import { create } from 'zustand'
import { IAssistantModel } from '../interfaces/iAssistantModel.ts'
import assistantService from '../services/assistantService.ts'

interface IAssistantModelState {
  isLoading: boolean
  assistantModels: IAssistantModel[]
  setIsLoading: (isLoading: boolean) => void
  setAssistantModels: (models: IAssistantModel[]) => void
  getAssistantModelsByContext: (
    context: 'all' | 'team',
    teamId?: string,
  ) => IAssistantModel[]
  loadAssistantModels: (
    scopes: string[],
    teamId?: string,
  ) => Promise<IAssistantModel[]>
}

const useAssistantModelStore = create<IAssistantModelState>((set, getState) => ({
  isLoading: false,
  assistantModels: [],
  getAssistantModelsByContext: (context: 'all' | 'team', teamId?: string) => {
    const models = getState().assistantModels
    if (context === 'team') {
      if (!teamId) throw new Error('teamId param is necessary for team context')
      return models.filter((m) =>
        m.teamReferences?.find((teamRefId) => teamRefId === teamId),
      )
    }
    return models
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setAssistantModels: (assistantModels: IAssistantModel[]) =>
    set(() => ({ assistantModels })),
  loadAssistantModels: async (scopes) => {
    const models = await assistantService.getAssistantModels(scopes)
    getState().setAssistantModels(models)
    return models
  },
}))

export default useAssistantModelStore
