import axiosClient from '../utils/axiosClient'
import {
  iAssistant as iSessionSettings,
  IAssistantDetails,
} from '../components/sessionControls/assistants/iAssistantTypes'
class SystemPromptService {
  private static instance: SystemPromptService

  private constructor() {}

  public static getInstance(): SystemPromptService {
    if (!SystemPromptService.instance) {
      SystemPromptService.instance = new SystemPromptService()
    }

    return SystemPromptService.instance
  }

  public async createSessionSettings(
    sessionSettings: iSessionSettings,
  ): Promise<iSessionSettings> {
    return (await axiosClient.post('/sessionsettings', sessionSettings))
      .data as iSessionSettings
  }

  public async deleteSessionSettings(sessionSettingsId: string): Promise<void> {
    const response = await axiosClient.delete(
      `/sessionsettings/${sessionSettingsId}`,
    )
    if (response.status !== 200) {
      throw new Error('Could not delete system prompt')
    }
  }

  public async getSessionSettings(
    sessionSettingsId: string,
  ): Promise<iSessionSettings> {
    return (await axiosClient.get(`/sessionsettings/${sessionSettingsId}`))
      .data as iSessionSettings
  }

  public async getSessionSettingDetails(
    sessionSettingsId: string,
  ): Promise<IAssistantDetails> {
    return (await axiosClient.get(`sessionsettings/${sessionSettingsId}/details`))
      .data as IAssistantDetails
  }

  public async getAllSessionSettings(): Promise<iSessionSettings[]> {
    return (await axiosClient.get('/sessionsettings')).data as iSessionSettings[]
  }

  public async getBlueprintAssistants(): Promise<iSessionSettings[]> {
    return (await axiosClient.get('/sessionsettings/blueprint'))
      .data as iSessionSettings[]
  }

  public async updateSessionSettings(
    sessionSettings: iSessionSettings,
  ): Promise<void> {
    const response = await axiosClient.put('/sessionsettings', sessionSettings)
    if (response.status !== 200) {
      throw new Error('Could not update system prompt')
    }
  }
}

export default SystemPromptService.getInstance()
