import { iPat } from '../components/utils/iPat.ts'
import axiosClient from '../utils/axiosClient.ts'

class PatService {
  private static instance: PatService

  private constructor() {}

  public static getInstance(): PatService {
    if (!PatService.instance) {
      PatService.instance = new PatService()
    }
    return PatService.instance
  }

  public async getPats(): Promise<iPat[]> {
    return (await axiosClient.get(`/personalaccesstokens`)).data as iPat[]
  }

  public async createPat(groupId: string, expiryDate: Date): Promise<iPat> {
    const formattedDate = expiryDate.toISOString()
    return (
      await axiosClient.post(
        `/personalaccesstoken/${groupId}?expiryDate=${formattedDate}`,
      )
    ).data as iPat
  }

  public async deletePat(tokenId: string) {
    return await axiosClient.delete(`/personalaccesstoken/${tokenId}`)
  }
}

export default PatService.getInstance()
