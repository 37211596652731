import React from 'react'
import iSettingsProps from '../settings/iSettingsProps.ts'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'

function OktaChangePopup({ closeFunction }: iSettingsProps) {
  const updateAndClose = async () => {
    closeFunction()
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={closeFunction}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <h2 className={'text-red-600 font-bold text-xl mb-4'}>
            Warning, all data will be wiped
          </h2>
        </ModalHeader>
        <ModalBody>
          <p className={'text-gray-600 mb-4'}>
            Please be aware that as part of the transition to Okta authentication,
            all existing data will be permanently deleted on Friday, March 1st.
            Ensure that you back up any critical data before this date. For any
            concerns or further assistance, please contact our support team.
          </p>
        </ModalBody>
        <ModalFooter>
          <button onClick={updateAndClose} className={'btn btn-primary'}>
            Acknowledge
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default OktaChangePopup
