import AssistantView from '../sessionControls/assistants/assistantView.tsx'
import { AssistantViewVariant } from '../sessionControls/assistants/iAssistantTypes.tsx'
import React, { useState } from 'react'
import KnowledgeConView from '../sessionControls/assistants/knowledgeConView.tsx'
import PluginView from '../sessionControls/assistants/pluginView.tsx'
import {
  CloudArrowDownIcon,
  PuzzlePieceIcon,
  SwatchIcon,
} from '@heroicons/react/24/outline'
import useTeamStore from '../../stateManagement/teamState.ts'
import { Role } from '../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'

const AssistantStartup = () => {
  const [availableSteps, setAvailableSteps] = useState<number[]>([1])
  const [activeStep, setActiveStep] = useState<number>(1)

  const { hasRightTo } = useTeamStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )
  const finishStep = (step: number) => {
    const steps = Array.from({ length: step + 1 }, (_, i) => i + 1)
    setAvailableSteps(steps)
    setActiveStep(step + 1)
    // click on the next step
    const nextStep = step + 1
    const nextStepElement = document.getElementById(`item${nextStep}`)
    if (nextStepElement) {
      nextStepElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const clickLink = (step: number) => {
    const nextStepElement = document.getElementById(`item${step}`)
    if (nextStepElement) {
      nextStepElement.scrollIntoView({ behavior: 'smooth' })
    }
    setActiveStep(step)
  }

  return (
    <>
      <ul className="timeline justify-center">
        <li className={'myTimeline'}>
          <div
            className={`timeline-start timeline-box cursor-pointer noBorderShadow ${activeStep === 1 ? `underline` : ``}`}
            onClick={() => clickLink(1)}
          >
            Assistant
          </div>

          <div
            className="timeline-middle cursor-pointer "
            onClick={() => clickLink(1)}
          >
            <SwatchIcon
              className="h-7 w-7"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            />
          </div>
          {hasRightTo(Role.Member) && (
            <hr className={availableSteps.includes(2) ? `bg-primary` : ''} />
          )}
        </li>
        {hasRightTo(Role.Member) && (
          <>
            <li className={'myTimeline'}>
              <hr className={availableSteps.includes(2) ? `bg-primary` : ''} />
              <div
                className={`timeline-start timeline-box cursor-pointer noBorderShadow ${activeStep === 2 ? `underline` : ``}`}
                onClick={() => clickLink(2)}
              >
                Knowledge Container
              </div>
              <div
                className="timeline-middle cursor-pointer "
                onClick={() => clickLink(2)}
              >
                <CloudArrowDownIcon
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-7 h-7 "
                />
              </div>
              <hr className={availableSteps.includes(3) ? `bg-primary` : ''} />
            </li>
            <li className={'myTimeline'}>
              <hr className={availableSteps.includes(3) ? `bg-primary` : ''} />
              <div
                className={`timeline-start timeline-box cursor-pointer noBorderShadow ${activeStep === 3 ? `underline` : ``}`}
                onClick={() => clickLink(3)}
              >
                Plugins
              </div>
              <div
                className="timeline-middle cursor-pointer "
                onClick={() => clickLink(3)}
              >
                <PuzzlePieceIcon
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-7 h-7 "
                />
              </div>
            </li>
          </>
        )}
      </ul>

      <div className="carousel w-full">
        <div id="item1" className="carousel-item w-full justify-center">
          <div className={'flex w-full'}>
            <AssistantView
              closeFunction={() => finishStep(1)}
              variant={AssistantViewVariant.ChatSelection}
            />
          </div>
        </div>
        {hasRightTo(Role.GaiaUser) && (
          <>
            <div id="item2" className="carousel-item w-full justify-center ">
              <div className={'flex w-full'}>
                <KnowledgeConView
                  goNextFunction={() => finishStep(2)}
                  goBeforeFunction={() => clickLink(1)}
                />
              </div>
            </div>
            <div id="item3" className="carousel-item w-full justify-center min-h-0 ">
              <div className={'flex w-full'}>
                <PluginView
                  goNextFunction={() => clickLink(3)}
                  goBeforeFunction={() => clickLink(2)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default AssistantStartup
