import axiosClient from '../utils/axiosClient.ts'
import { iTeam } from '../components/sessionControls/userManagement/iTeam.tsx'

type TeamSetter = (teams: iTeam[]) => void

class TeamService {
  private static instance: TeamService

  private constructor() {}

  public static getInstance(): TeamService {
    if (!TeamService.instance) {
      TeamService.instance = new TeamService()
    }
    return TeamService.instance
  }

  public async getTeams(all: boolean, includeSpent = false): Promise<iTeam[]> {
    // await new Promise((resolve) => setTimeout(resolve, 2000, false)) // just for testing
    return (await axiosClient.get(`/teams?all=${all}&includeSpent=${includeSpent}`))
      .data as iTeam[]
  }

  public async getTeam(teamId: string): Promise<iTeam> {
    return (await axiosClient.get(`/team/${teamId}`)).data as iTeam
  }

  public async createTeam(team: iTeam): Promise<iTeam> {
    const response = await axiosClient.post(`/team`, team)
    return response.data
  }

  public async updateTeam(team: iTeam): Promise<iTeam> {
    const updatedTeam = (await axiosClient.put(`/team`, team)).data as iTeam
    return updatedTeam
  }

  public async deleteTeam(teamId: string): Promise<void> {
    await axiosClient.delete(`/team/${teamId}`)
  }
}

export default TeamService.getInstance()
