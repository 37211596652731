import React, { useEffect, useState } from 'react'
import SearchInput from '../../utils/searchInput.tsx'
import { iPlugin } from '../plugins/iPlugin.ts'
import pluginService from '../../../services/pluginService.ts'
import PluginCard from './pluginCard.tsx'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import { shallow } from 'zustand/shallow'
export interface iPluginViewProps {
  goNextFunction: () => void
  goBeforeFunction: () => void
}

function PluginView({ goNextFunction, goBeforeFunction }: iPluginViewProps) {
  const [plugins, setPlugins] = useState<iPlugin[]>([])
  // const [selectedPlugins, setSelectedPlugins] = useState<iPlugin[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { activeSession, updateSession } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      activeSession: state.activeSession,
    }),
    shallow,
  )

  /**
   * Reload plugins when the selected assistant changes
   */
  useEffect(() => {
    loadPlugins()
  }, [activeSession, activeSession?.sessionSettings?.baseSessionSettingsId])

  const loadPlugins = () => {
    setLoading(true)
    pluginService
      .getPlugins(activeSession?.sessionSettings?.baseSessionSettingsId ?? '')
      .then((response) => {
        if (!activeSession) {
          return
        }
        let pluginsResponse = response.map((blob) => {
          const selected =
            activeSession.sessionSettings!.pluginIds?.includes(blob.id!) ?? false
          return { ...blob, selected: selected }
        })
        // filter out duplicates, keep the one with fromBaseAssistantInherited = true
        pluginsResponse = Object.values(
          pluginsResponse.reduce(
            (acc, plugin) => {
              if (!acc[plugin.id!] || plugin.fromBaseAssistantInherited) {
                acc[plugin.id!] = plugin
              }
              return acc
            },
            {} as { [id: string]: iPlugin },
          ),
        )

        setLoading(false)
        setPlugins(pluginsResponse)
      })
  }

  const filteredPlugins = () => {
    if (searchTerm === '' || searchTerm.length < 3) {
      return plugins
    } else {
      return plugins.filter((blobcontainer) => {
        return (
          blobcontainer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          blobcontainer.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
      })
    }
  }

  const handleSelect = (plugin: iPlugin) => {
    // prevent the uncheck if it is from base assistant inherited
    if (plugin.fromBaseAssistantInherited) return

    const updatedPlugins = plugins.map((blob) => {
      if (blob.id === plugin.id) {
        return { ...blob, selected: !blob.selected }
      }
      return blob
    })

    setPlugins(updatedPlugins)

    const selectedPluginsList = updatedPlugins.filter((plugin) => plugin.selected)
    if (activeSession) {
      activeSession.sessionSettings!.pluginIds = selectedPluginsList.map(
        (plugin) => plugin.id!,
      )
      updateSession(activeSession)
    }
  }

  return (
    <>
      <div className="space-y-4 mx-auto  p-4 rounded-xl  sticky top-0 z-10 w-full max-h-fit  flex justify-center">
        <div className={'h-full justify-center flex flex-col-reverse'}>
          <button
            onClick={() => goBeforeFunction()}
            className={
              'btn-glass px-2 max-h-12 rounded-lg hover:-translate-y-1 hover:scale-110 duration-300 indicator'
            }
          >
            <ArrowLeftIcon
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            />
          </button>
        </div>
        <div className={'w-full'}>
          <div className={'justify-center'}>
            <h1 className={'text-xl font-semibold mb-2'}>Plugins</h1>
            <p className={''}>Select the plugin you want to add to the session. </p>
          </div>
          {(loading && (
            <div className={'grid grid-cols-1 h-20'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )) ||
            (plugins.length > 0 && (
              <>
                {plugins.length > 3 && (
                  <SearchInput
                    searchTerm={searchTerm}
                    placeholder={'Search for plugin or description'}
                    setSearchTerm={setSearchTerm}
                  />
                )}

                <div className="flex flex-wrap justify-center overflow-y-auto">
                  {filteredPlugins().map((plugin, key) => {
                    return (
                      <PluginCard
                        key={key}
                        plugin={plugin}
                        handleSelect={handleSelect}
                      />
                    )
                  })}
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  )
}

export default PluginView
