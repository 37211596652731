import React, { useMemo, useState } from 'react'
import OptionsMenu from '../utils/optionsMenu.tsx'
import './sessionGroups.css'
import { iSession } from '../chatWindow/iSession.ts'
import { Button, Input, Spinner } from '@nextui-org/react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { useSearchParams } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

type SessionItemProps = React.HTMLAttributes<HTMLButtonElement> & {
  session: iSession
}
const SessionItem = ({ session }: SessionItemProps) => {
  const { deleteSession, updateSession, activateSession, activeSession } =
    useSessionStore(
      (state) => ({
        updateSession: state.updateSession,
        deleteSession: state.deleteSession,
        activateSession: state.activateSession,
        activeSession: state.activeSession,
      }),
      shallow,
    )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [editingSession, setEditingSession] = useState<boolean>()
  const [searchParams, setSearchParams] = useSearchParams()

  const isSessionActive = useMemo(() => {
    const isActive = activeSession?.id === session.id
    if (isActive) setIsLoading(false)
    return isActive
  }, [activeSession, session])

  const handleSessionDelete = async (session: iSession) => {
    if (!confirm('Are you sure you want to delete this session?')) {
      return
    }
    setIsLoading(true)
    await deleteSession(session)
    setIsLoading(false)
  }

  const handleSessionEdit = async (sessionId: string, newName: string) => {
    if (!session) return
    setIsLoading(true)
    setEditingSession(false)

    session.title = newName
    setTimeout(
      () =>
        updateSession(session)
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false)),
      100,
    )
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      console.log('Enter spotted: prevent!')
      const temp = document.activeElement as HTMLElement
      temp && temp.blur()
    }
  }

  const selectSession = () => {
    if (!isSessionActive && !isLoading) {
      setIsLoading(true)
      activateSession(session).then(() => {
        searchParams.set('sessionId', session.id!)
        setSearchParams(searchParams)
        setIsLoading(false)
      })
    }
  }

  return (
    <div
      className={`transition px-2 py-4 relative gaia-session-item overflow-visible h-fit w-full bg-ghost ${isSessionActive ? 'active' : ''}`}
    >
      {editingSession ? (
        <Input
          type="text"
          color={'default'}
          className={'px-4 w-full'}
          defaultValue={session.title}
          disabled={isLoading}
          onKeyDown={handleKeyDown}
          onBlur={(e) =>
            handleSessionEdit(
              session.id!,
              (e as React.FocusEvent<HTMLInputElement>).target.value,
            )
          }
          autoFocus={true}
        />
      ) : (
        <Button
          variant={'flat'}
          onClick={selectSession}
          disableRipple
          className={'pr-0 h-fit w-full bg-transparent -hover'}
        >
          <div className={`flex justify-between w-full`}>
            <>
              <div className={'w-4/5 text-start'}>
                <div>
                  {/* {session.title.substring(0, 20) + (session.title.length > 20 ? "..." : "")} */}
                  <div className="truncate chat-title text-primary">
                    {session.title}
                  </div>
                  <div className="truncate chat-footer opacity-50 text-primary">
                    {session?.modified?.toLocaleString()}
                  </div>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <OptionsMenu
                  items={[
                    {
                      label: 'Rename',
                      method: async () => setEditingSession(true),
                      icon: <PencilSquareIcon className={'h-5 w-5'} />,
                      showDivider: true,
                    },
                    {
                      label: 'Delete',
                      method: async () => await handleSessionDelete(session),
                      icon: <TrashIcon className={'h-5 w-5'} />,
                      className: 'text-danger',
                    },
                  ]}
                />
              )}
            </>
          </div>
        </Button>
      )}
    </div>
  )
}

export default SessionItem
