import React, { useEffect, useRef, useState } from 'react'
import './snackbar.css'

export interface SnackbarProps {
  isOpen: boolean
  relative?: boolean
  action?: React.ReactNode
  className?: string | undefined
  children?: React.ReactNode
}

function Snackbar({ isOpen, children, action, className, relative }: SnackbarProps) {
  const snackbarRef = useRef<HTMLDivElement | null>(null)
  const [isSnackbarOpen, setSnackbarOpen] = useState(isOpen)

  useEffect(() => {
    // prevent calling onClose if nothing has been changed
    if (isOpen === isSnackbarOpen) return
    if (isOpen) {
      setSnackbarOpen(isOpen)
    } else {
      handleCloseAnimation()
    }
  }, [isOpen])
  const handleCloseAnimation = () => {
    snackbarRef.current?.classList.add('closing')
    setTimeout(() => setSnackbarOpen(false), 150)
  }

  return (
    isSnackbarOpen && (
      <div
        ref={snackbarRef}
        role="alert"
        className={`${relative ? 'relative' : 'fixed bottom-4'} ${className || ''} alert w-fit snackbar`}
      >
        {children}
        {action && <div data-testid="test-action-wrapper">{action}</div>}
      </div>
    )
  )
}

export default Snackbar
