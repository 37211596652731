import React, { useState } from 'react'
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/outline'

interface iThumbRatingProps {
  currentValue: number
  onRated: (value: number) => void
}

function ThumbRating({ currentValue, onRated }: iThumbRatingProps) {
  const [highlightedValue, setHighlightedValue] = useState(currentValue)

  return (
    <>
      <button
        className={highlightedValue === 1 ? 'text-yellow-500' : 'text-gray-500'}
        onMouseEnter={() => setHighlightedValue(1)}
        onMouseLeave={() => setHighlightedValue(currentValue)}
        onClick={() => onRated(1)}
      >
        <HandThumbUpIcon className="w-[1.5em] inline-block cursor-pointer" />
      </button>
      <button
        className={highlightedValue === -1 ? 'text-yellow-500' : 'text-gray-500'}
        onMouseEnter={() => setHighlightedValue(-1)}
        onMouseLeave={() => setHighlightedValue(currentValue)}
        onClick={() => onRated(-1)}
      >
        <HandThumbDownIcon className="w-[1.5em] inline-block ml-[0.6em] cursor-pointer" />
      </button>
    </>
  )
}

export default ThumbRating
