import React, { useState, useEffect, Key } from 'react'

interface Node {
  name: string
  path: string
  children: Node[]
  isFolder: boolean
}

interface iFileNodeTreeProps {
  originalFiles: File[]
  selectedFiles: File[]
  onSelectionChange: (path: string, name: string, isSelected: boolean) => void
}

function FileNodeTree({
  originalFiles,
  selectedFiles,
  onSelectionChange,
}: iFileNodeTreeProps) {
  const [expandedFolders, setExpandedFolders] = useState<Set<string>>(new Set())
  const [fileNodes, setFileNodes] = useState<Node[]>([])
  const [selectedData, setSelectedData] = useState<Set<string>>(new Set())

  useEffect(() => {
    const fN = createNodeStructure(originalFiles)
    setFileNodes(fN)
  }, [originalFiles])

  useEffect(() => {
    if (selectedData.size === 0 && selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        setSelectedData((prevSelectedData) => {
          const newSelectedData = new Set(prevSelectedData)
          newSelectedData.add(file.webkitRelativePath + file.name)
          return newSelectedData
        })
      })
    }
  }, [selectedFiles])

  const handleCheckboxChange = (path: string, name: string, isChecked: boolean) => {
    onSelectionChange(path, name, isChecked)
    setSelectedData((prevSelectedData) => {
      const newSelectedData = new Set(prevSelectedData)
      if (isChecked) {
        newSelectedData.add(path + name)
      } else {
        newSelectedData.delete(path + name)
      }
      return newSelectedData
    })
  }

  const toggleFolder = (path: string) => {
    const newExpandedFolders = new Set(expandedFolders)
    if (newExpandedFolders.has(path)) {
      newExpandedFolders.delete(path)
    } else {
      newExpandedFolders.add(path)
    }
    setExpandedFolders(newExpandedFolders)
  }

  const checkAllFilesInFolder = (node: Node, isChecked: boolean) => {
    if (node.children.length > 0) {
      node.children.forEach((child) => checkAllFilesInFolder(child, isChecked))
    } else {
      handleCheckboxChange(node.path, node.name, isChecked)
    }
  }

  const areAllFilesSelected = (node: Node): boolean => {
    if (node.children.length > 0) {
      return node.children.every((child) => areAllFilesSelected(child))
    } else {
      return selectedData.has(node.path + node.name)
    }
  }

  const createNodeStructure = (files: File[]) => {
    const nodes: Node[] = []

    files.forEach((file) => {
      const path = file.webkitRelativePath || ''
      const pathParts = path.split('/')
      pathParts.pop()
      const fileName = file.name

      // Add all path parts as folders
      let currentFolder = nodes
      pathParts.forEach((part) => {
        let existingNode = currentFolder.find((node) => node.name === part)

        if (!existingNode) {
          existingNode = {
            name: part,
            path:
              currentFolder.length > 0 ? `${currentFolder[0].path}/${part}` : part,
            children: [],
            isFolder: true,
          }
          currentFolder.push(existingNode)
        }

        currentFolder = existingNode.children
      })

      // Add file to the deepest folder
      currentFolder.push({
        name: fileName!,
        path: path,
        children: [],
        isFolder: false,
      })
    })

    return nodes
  }

  const renderFileNode = (node: Node) => {
    const isSelected = selectedData.has(node.path + node.name)
    const isFolder = node.children.length > 0
    const key: Key = node.path + node.name

    function handleCheckboxTick(
      node: Node,
    ): React.ChangeEventHandler<HTMLInputElement> {
      return (event) => {
        event.stopPropagation()
        handleCheckboxChange(node.path, node.name, event.target.checked)
        if (isFolder) {
          node.children.forEach((child) => handleCheckboxTick(child)(event))
        }
      }
    }

    return (
      <React.Fragment key={key}>
        {isFolder ? (
          <li>
            <details open>
              <summary>
                <div
                  onClick={() =>
                    checkAllFilesInFolder(node, !areAllFilesSelected(node))
                  }
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <input
                    type="checkbox"
                    className="checkbox checkbox-xs checkbox-primary mr-2"
                    checked={isSelected}
                    onChange={handleCheckboxTick(node)}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4"
                    onClick={() => toggleFolder(node.path)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                    />
                  </svg>
                  <span className="ml-2">{node.name}</span>
                </div>
              </summary>
              <ul>{node.children.map((child) => renderFileNode(child))}</ul>
            </details>
          </li>
        ) : (
          <div>
            <li
              onClick={() => handleCheckboxChange(node.path, node.name, !isSelected)}
            >
              <a style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  className="checkbox checkbox-xs checkbox-primary"
                  checked={isSelected}
                  onChange={handleCheckboxTick(node)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
                <span>{node.name}</span>
              </a>
            </li>
          </div>
        )}
      </React.Fragment>
    )
  }

  return (
    <div className="overflow-auto no-scrollbar">
      <ul className="menu menu-xs bg-base-200 rounded-lg w-full max-h-80 overflow-x-hidden overflow-y-auto flow-root">
        {fileNodes.map((node) => renderFileNode(node))}
      </ul>
    </div>
  )
}

export default FileNodeTree
