import React, { useMemo } from 'react'
import { Spinner } from '@nextui-org/react'
import useTeamStore from '../../stateManagement/teamState.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import useGroupStore from '../../stateManagement/groupState.ts'
import NoRelatedTeam from '../emptyStates/noRelatedTeam.tsx'
import EmptySessionFolder from '../emptyStates/emptyTeam.tsx'
import EmptyTeam from '../emptyStates/emptyTeam.tsx'
import SessionChatContent from './sessionChatContent.tsx'
import { shallow } from 'zustand/shallow'
import LegalDialog from '../legal/legalDialog.tsx'
import useAssistantStore from '../../stateManagement/assistantState.ts'

interface iSessionContentProps {
  theme: string
}

function SessionContent({ theme }: iSessionContentProps) {
  const { sessions, isLoading, activeSession } = useSessionStore(
    (state) => ({
      isLoading: state.isLoading,
      sessions: state.sessions,
      activeSession: state.activeSession,
    }),
    shallow,
  )

  const { assistants } = useAssistantStore(
    (state) => ({
      assistants: state.assistants,
    }),
    shallow,
  )

  const { groups } = useGroupStore((state) => ({ groups: state.groups }), shallow)
  const { teams, selectedTeam } = useTeamStore(
    (state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  const termsOfUseAssistant = useMemo(() => {
    const assistantId = activeSession?.sessionSettings?.baseSessionSettingsId
    const assistant = assistants.find((assistant) => assistant.id === assistantId)
    return assistant?.termsOfUse
  }, [activeSession?.sessionSettings?.baseSessionSettingsId])

  const displayLoading = useMemo(
    () => !teams || !sessions || isLoading,
    [teams, sessions, isLoading],
  )
  return (
    <>
      {displayLoading ? (
        <div className={'flex flex-1 justify-center align-center'}>
          <Spinner size={'lg'} />
        </div>
      ) : selectedTeam && sessions && activeSession ? (
        <SessionChatContent theme={theme} />
      ) : teams?.length === 0 ? (
        <NoRelatedTeam />
      ) : teams?.length && groups.length === 0 ? (
        <EmptyTeam />
      ) : groups.length > 0 && sessions.length === 0 ? (
        <EmptySessionFolder />
      ) : (
        <></>
      )}
      {termsOfUseAssistant && (
        <LegalDialog
          termsOfUse={termsOfUseAssistant}
          resourceId={activeSession!.sessionSettings!.baseSessionSettingsId!}
        ></LegalDialog>
      )}
    </>
  )
}

export default SessionContent
