import React, { useEffect, useRef, useState } from 'react'

interface iDescriptionProps {
  description: string
}

function Description({ description }: iDescriptionProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const descriptionRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(
        descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight,
      )
    }
  }, [description])

  const handleShowMore = () => {
    setIsExpanded(true)
  }

  const handleShowLess = () => {
    setIsExpanded(false)
  }

  return (
    <div className={'flex-grow'}>
      <p ref={descriptionRef} className={`${isExpanded ? '' : 'line-clamp-3'}`}>
        {description}
      </p>
      {!isExpanded && isOverflowing && (
        <button className="text-blue-500 hover:underline" onClick={handleShowMore}>
          Show more
        </button>
      )}
      {isExpanded && (
        <button className="text-blue-500 hover:underline" onClick={handleShowLess}>
          Show less
        </button>
      )}
    </div>
  )
}

export default Description
