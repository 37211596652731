import { createContext, useContext } from 'react'
import { ContentItem, iMessage } from '../../components/chatWindow/iMessage'
import { iSession } from '../../components/chatWindow/iSession'

type MessageContextType = {
  handleSendMessage: (
    content: string | undefined,
    contentItem: ContentItem[] | undefined,
    session: iSession,
    file: File | Blob | null,
  ) => Promise<void>
  createMessage: (
    session: iSession,
    message: iMessage,
  ) => Promise<iMessage | undefined>
  updateMessage: (session: iSession, message: iMessage) => Promise<void>
  deleteMessage: (session: iSession, message: iMessage) => Promise<void>
  getMessages: (session: iSession) => Promise<void>
  setMessages: (session: iSession, messages: iMessage[]) => void
  measureAndSendGenerationTime: (session: iSession) => void
}

export const MessageContext = createContext<MessageContextType | undefined>(
  undefined,
)

export const useMessages = () => {
  const context = useContext(MessageContext)
  if (!context) {
    throw new Error('useMessages must be used within a SessionProvider')
  }

  return context
}
