import React, { useState } from 'react'
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline'
import './copyToClipboardStyle.css'
import { Button, Tooltip } from '@nextui-org/react'

interface iClipboardCopyButtonProps {
  textToCopy: string
  tooltip?: string
}

function ClipboardCopyButton(props: iClipboardCopyButtonProps) {
  const [copyState, setCopyState] = useState(0)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(props.textToCopy)
      setCopyState(1)
      setTimeout(() => setCopyState(2), 3000) // Reset the copied state after 3 seconds
      setTimeout(() => setCopyState(0), 4000) // Reset the copied state after 4 seconds
    } catch (err) {
      console.error('Failed to copy text to clipboard', err)
    }
  }

  return (
    <Tooltip
      content={props.tooltip}
      isDisabled={!props.tooltip}
      color={'primary'}
      className={'max-w-96'}
    >
      <Button isIconOnly variant={'light'} onClick={copyToClipboard}>
        {copyState === 1 ? (
          <CheckIcon className="w-5 h-5 icon-rotate" color="green" />
        ) : copyState === 2 ? (
          <ClipboardIcon className="w-5 h-5 icon-rotate" />
        ) : (
          <ClipboardIcon className="w-5 h-5" />
        )}
      </Button>
    </Tooltip>
  )
}

export default ClipboardCopyButton
