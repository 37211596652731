import React, { useEffect, useState } from 'react'
import './sessions.css'
import { Popup } from '../sessionControls/contextPopup.tsx'
import { BellIcon } from '@heroicons/react/24/outline'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import { Button, Tooltip } from '@nextui-org/react'
import { getLastChangelogDate } from './dateUtils.ts'
import changelogMarkdown from '../../../cms/changelog/CHANGELOG.md'
import useUserProfileStore from '../../stateManagement/userProfileState.ts'
import { shallow } from 'zustand/shallow'

const RoadmapNotification = () => {
  const { setPopup } = useMain()
  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const lastChangeLogDate = getLastChangelogDate(changelogMarkdown)
  const [showChangeLogIndicator, setShowChangeLogIndicator] = useState(false)

  useEffect(() => {
    if (!userProfile) return
    if (userProfile.changelogdate !== lastChangeLogDate) {
      setShowChangeLogIndicator(true)
      setPopup(Popup.Changelog)
    } else {
      setShowChangeLogIndicator(false)
    }
  }, [userProfile])

  return (
    <Tooltip
      content="Upcoming roadmap and new features for GAIA"
      color={'primary'}
      className={'max-w-96'}
    >
      <Button variant={'light'} isIconOnly onClick={() => setPopup(Popup.Changelog)}>
        <div className="indicator">
          <span
            className="indicator-item indicator-end z-20"
            hidden={!showChangeLogIndicator}
          >
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
          </span>
          <BellIcon
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          />
        </div>
      </Button>
    </Tooltip>
  )
}

export default RoadmapNotification
