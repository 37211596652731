import { OnboardingState, iUser } from '../components/login/iUser'
import axiosClient from '../utils/axiosClient'
import axios, { AxiosResponse } from 'axios'
import { iShared } from '../interfaces/iShared.ts'
import { deleteTokens, getUserKey } from '../components/login/authentication.tsx'

class UserService {
  private static instance: UserService
  // private baseURL: string = import.meta.env.VITE_API_BASE_URL;

  private constructor() {}

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService()
    }

    return UserService.instance
  }

  public async createUser(email: string): Promise<void> {
    await axiosClient.post(`/user/${email}`, {})
  }

  public async deleteUser(userId: string): Promise<void> {
    await axiosClient.delete(`/user/${userId}`)
  }

  public async getUser(): Promise<iUser> {
    return (await axiosClient.get(`/user`)).data as iUser
  }

  public async acceptedToUVersion(
    isoDate: string,
    resourceId?: string,
  ): Promise<iUser> {
    return (
      await axiosClient.patch(`/user/acceptedToUVersion`, {
        version: isoDate,
        resourceId,
      })
    ).data as iUser
  }

  public async getUsers(): Promise<iUser[]> {
    return (await axiosClient.get(`/users`)).data as iUser[]
  }

  public async setLastTeamId(teamId: string): Promise<iUser[]> {
    return (
      await axiosClient.patch(`/user/lastteamid`, {
        lastTeamId: teamId,
      })
    ).data as iUser[]
  }

  public async addUserToCostCenter(
    userId: string,
    costcenterId: string,
  ): Promise<void> {
    await axiosClient.patch(`/user/${userId}/costcenter/${costcenterId}`, {})
  }

  public async removeUserFromCostCenter(
    userId: string,
    costcenterId: string,
  ): Promise<void> {
    await axiosClient.delete(`/user/${userId}/costcenter/${costcenterId}`)
  }

  public async identifyUser(): Promise<AxiosResponse> {
    const response = await axiosClient.get(`/user/auth/identify`).catch((error) => {
      console.error('UserService.ts error: ', error)
      return error.response
    })
    console.log('UserService.ts: ', response)
    return response
  }

  public async userPicture(): Promise<string> {
    const response = await axiosClient.get(`/user/profile/picture`)
    return 'data:image/jpeg;base64,' + response.data
  }

  public async userPictureByMail(email: string): Promise<string> {
    const response = await axiosClient.get(`/user/profile/picture/${email}`)
    return 'data:image/jpeg;base64,' + response.data
  }

  public async searchUsers(): Promise<iUser[]> {
    return (await axiosClient.get(`/users`)).data as iUser[]
  }

  public async updateUserProfileChangelog(changelog: string): Promise<void> {
    await axiosClient.patch(`/user/profile/changelog`, { changelog })
  }

  public async saveOnboardingState(state: OnboardingState): Promise<void> {
    return await axiosClient.put(`/user/onboardingstate/${state.name}`, state)
  }

  public async loadOnboardingState(name: string): Promise<OnboardingState> {
    const onboardingStates =
      (await this.getUser().then((user) => user.onboardingStates)) || []
    const existingState = onboardingStates.find((state) => state.name === name)
    if (existingState) {
      return existingState
    }
    const state = { name: name, status: 'NotStarted', stepIndex: 0 }
    await this.saveOnboardingState(state)
    return state
  }
}

export default UserService.getInstance()
