import { iModelInfo } from '../components/sessionControls/userManagement/iModelInfo'
import axiosClient from '../utils/axiosClient'

class TokenService {
  private static instance: TokenService

  private constructor() {}

  public static getInstance(): TokenService {
    if (!TokenService.instance) {
      TokenService.instance = new TokenService()
    }
    return TokenService.instance
  }

  public async getTokens(
    teamId: string,
    from: Date,
    to: Date,
  ): Promise<iModelInfo[]> {
    return (
      await axiosClient.get(`team/${teamId}/chargeitems`, {
        params: {
          from: from,
          to: to,
        },
      })
    ).data as iModelInfo[]
  }

  public async getAllTokensAsAdmin(from: Date, to: Date): Promise<iModelInfo[]> {
    return (
      await axiosClient.get(`teams/chargeitems`, {
        params: {
          from: from,
          to: to,
        },
      })
    ).data as iModelInfo[]
  }
}

export default TokenService.getInstance()
