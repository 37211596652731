import { CancelTokenSource } from 'axios'
import { iMessage } from './iMessage'
import { iItem } from '../../interfaces/iItem'
import { iFileData } from '../sessionControls/files/fileSetting'
import { CustomFile } from '../sessionControls/files/processDirectory.tsx'
import { iAssistant } from '../sessionControls/assistants/iAssistantTypes.tsx'

export interface iSession extends iItem {
  title: string
  messages?: iMessage[]
  sessionSettings?: iAssistant
  sessionSettingsId?: string
  assistantStatus?: AssistantStatus
  groupId?: string
  teamId: string
  isActive: boolean
  originalFiles?: CustomFile[]
  selectedFiles?: CustomFile[]
  cancelSource?: CancelTokenSource | null
  isGenerating?: boolean
  fileData?: iFileData[]
}

export interface CustomFileInfo {
  name: string
  webkitRelativePath: string
}

export enum AssistantStatus {
  Idle,
  Generating,
  Cancelled,
}
