import React, { useCallback, useEffect, useState } from 'react'
import pluginService from '../../../services/pluginService'
import { iPlugin } from './iPlugin'
import { PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import ProfileImage from '../../utils/profileImage/profileImage'
import { getUserEmail } from '../../login/authentication.tsx'
import {
  Avatar,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import { CameraIcon } from '@heroicons/react/16/solid'
import usePluginStore from '../../../stateManagement/pluginState.ts'
import { Role } from '../../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'

interface iPluginView {
  closeFunction: () => void
  editPluginFunction: (plugin: iPlugin | undefined) => void
  selector: 'Selection' | 'Action'
}

function PluginView({ closeFunction, editPluginFunction, selector }: iPluginView) {
  const { activeSession, updateSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
      updateSession: state.updateSession,
    }),
    //TODO: quick fix till we found a better update solution for activeSession
    //shallow,
  )

  const { hasRightTo, loadPlugins } = usePluginStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      loadPlugins: state.loadPlugins,
    }),
    shallow,
  )

  const [allPlugins, setAllPlugins] = useState<iPlugin[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  // const [pluginSettings, setPluginSettings] = useState<iPluginSettings | null>(null);

  useEffect(() => {
    getPlugins()
  }, [])

  useEffect(() => {
    console.log('get plugins again')
    getPlugins()
  }, [activeSession?.sessionSettings?.baseSessionSettingsId])

  const getPlugins = () => {
    setLoading(true)
    loadPlugins(activeSession?.sessionSettings?.baseSessionSettingsId ?? '').then(
      (plugins) => {
        // filter out duplicates, keep the one with fromBaseAssistantInherited = true
        plugins = Object.values(
          plugins.reduce(
            (acc, plugin) => {
              if (!acc[plugin.id!] || plugin.fromBaseAssistantInherited) {
                acc[plugin.id!] = plugin
              }
              return acc
            },
            {} as { [id: string]: iPlugin },
          ),
        )

        setAllPlugins(plugins)
        setLoading(false)
      },
    )
  }

  const filteredPlugins = allPlugins
    .filter((plugin) => {
      if (!searchQuery || searchQuery.length <= 3) return true
      plugin.name.toLowerCase().includes(searchQuery.toLowerCase())
    })
    .filter((plugin) => {
      if (selector === 'Selection') {
        return true
      }
      return (
        plugin.ownerEmail === getUserEmail() || hasRightTo(Role.Manager, plugin.id!)
      )
    })

  const togglePlugin = async (pluginId: string) => {
    if (!activeSession) return

    // create list of pluginIds if it doesn't exist
    if (!activeSession.sessionSettings?.pluginIds) {
      activeSession.sessionSettings!.pluginIds = []
    }

    // Remove pluginId if it exists, otherwise add it
    if (activeSession.sessionSettings?.pluginIds.some((p) => p === pluginId)) {
      activeSession.sessionSettings.pluginIds =
        activeSession.sessionSettings.pluginIds.filter((p) => p !== pluginId)
    } else {
      activeSession.sessionSettings!.pluginIds.push(pluginId)
    }

    await updateSession(activeSession!)
  }

  const isPluginEnabled = useCallback(
    (pluginId: string) => {
      return activeSession?.sessionSettings?.pluginIds?.some((p) => p === pluginId)
    },
    [activeSession?.sessionSettings?.pluginIds?.length],
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const deletePlugin = (pluginId: string) => {
    if (!confirm('Are you sure you want to delete this predefined setting?')) {
      return
    }
    setLoading(true)
    pluginService.deletePlugin(pluginId).then(() => {
      getPlugins()
    })
  }

  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-2'}>Plugin settings</h1>
      </ModalHeader>
      <ModalBody className={'min-w-96'}>
        <>
          {selector === 'Selection' && (
            <p className={''}>Select plugins you want to use for this session.</p>
          )}
          {selector === 'Action' && <p className={''}>Manage your plugins.</p>}
          {allPlugins.length > 5 && (
            <>
              <input
                id="search"
                type="text"
                placeholder="Search..."
                className="input input-bordered w-full"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <br />
            </>
          )}
          {loading && (
            <div className={'w-full grid'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )}

          {!loading && (
            <div className="overflow-x-hidden ">
              <table className="table">
                <thead>
                  <tr>
                    {selector === 'Selection' && <th></th>}
                    <th>Name</th>
                    <th>Author</th>
                    {selector === 'Action' && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {filteredPlugins.map((plugin) => (
                    <tr key={plugin.id}>
                      {selector === 'Selection' && (
                        <th>
                          <label>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={isPluginEnabled(plugin.id!)}
                              onChange={() => togglePlugin(plugin.id!)}
                              disabled={plugin.fromBaseAssistantInherited}
                            />
                          </label>
                        </th>
                      )}
                      <td>
                        <div className="flex items-center space-x-3">
                          <Avatar
                            className="flex-shrink-0"
                            showFallback
                            fallback={
                              <CameraIcon className="w-6 h-6 text-default-500" />
                            }
                            size={'md'}
                            src={plugin.image}
                          ></Avatar>
                          <div>
                            <div className="font-bold">{plugin.name}</div>
                            <div className="text-sm opacity-50">
                              {plugin.description}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <ProfileImage contact={plugin.ownerEmail!} />
                      </td>
                      {selector === 'Action' && (
                        <td>
                          <div className="flex items-center">
                            <button
                              className="btn btn-ghost btn-circle"
                              onClick={() => editPluginFunction(plugin)}
                            >
                              <PencilIcon className="w-5 h-5" />
                            </button>
                            <button
                              className="btn btn-ghost btn-circle"
                              onClick={() => deletePlugin(plugin.id!)}
                            >
                              <TrashIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {selector === 'Action' && (
            <div className="w-full  grid place-items-center">
              <Button
                className={'m-1'}
                fullWidth
                isIconOnly
                variant={'light'}
                color={'primary'}
                onClick={() => editPluginFunction(undefined)}
              >
                <PlusCircleIcon className="w-8 h-8" />
              </Button>
            </div>
          )}
        </>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeFunction}>Close</Button>
      </ModalFooter>
    </>
  )
}

export default PluginView
