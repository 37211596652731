import React, { useState } from 'react'
import MarkdownPreview from '@uiw/react-markdown-preview'
import changelogMarkdown from '../../../../cms/changelog/CHANGELOG.md'
import { getLastChangelogDate } from '../../utils/dateUtils'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useUserProfileStore from '../../../stateManagement/userProfileState.ts'
import { shallow } from 'zustand/shallow'

interface ChangelogProps {
  onClose: () => void
}

function Changelog({ onClose }: ChangelogProps) {
  const [changelogContent] = useState(changelogMarkdown)
  const updateUserProfileChangelog = useUserProfileStore(
    (state) => state.updateUserProfileChangelog,
    shallow,
  )

  const updateAndClose = async () => {
    const lastChangelog = getLastChangelogDate(changelogMarkdown)
    if (lastChangelog) await updateUserProfileChangelog(lastChangelog)
    onClose()
  }

  return (
    <Modal
      backdrop={'blur'}
      isOpen={true}
      onClose={updateAndClose}
      scrollBehavior={'inside'}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent>
        <ModalHeader>Changelog</ModalHeader>
        <ModalBody>
          <MarkdownPreview
            source={changelogContent}
            className={'bg-white text-black'}
            style={{
              color: 'black',
              backgroundColor: 'white',
            }}
            warpperElement={{ 'data-color-mode': 'light' }}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={async () => await updateAndClose()}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default Changelog
