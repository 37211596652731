import React, { useEffect } from 'react'
import './feedbackAlert.css'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'

function FeedbackAlert() {
  const { error, setError, feedback, setFeedback, feedbackType, setFeedbackType } =
    useMain()

  useEffect(() => {
    if (feedback) {
      const timeout = setTimeout(() => {
        setFeedback('')
      }, 5000) // Clear the feedback message after 5 seconds

      return () => clearTimeout(timeout) // Clear the timeout if the component is unmounted
    }
  }, [feedback])

  useEffect(() => {
    if (error) {
      setFeedback(error.message)
      setFeedbackType('error')
    }
  }, [error])

  const closePopup = () => {
    setError(null)
  }

  return (
    feedback && (
      <div
        className={` w-full alert ${feedbackType === 'success' ? 'alert-success' : 'alert-error'} floating-feedback`}
      >
        {feedback}
      </div>
    )
  )
}

export default FeedbackAlert
