import React, { useEffect, useState } from 'react'
import {
  deleteTokens,
  evaluateTokens,
  openLoginPage,
  validateToken,
} from './components/login/authentication.tsx'
import ContextPopup from './components/sessionControls/contextPopup.tsx'
import FeedbackAlert from './components/utils/feedbackAlert.tsx'
import UserService from './services/userService'
import LoadingScreen from './components/loadingScreen/loadingScreen.tsx'
import AppDrawer from './components/app/appDrawer.tsx'
import BuildProviderTree from './utils/buildProviderTree.tsx'
import { NextUIProvider } from '@nextui-org/react'
import MainProvider from './stateManagement/providers/mainProvider.tsx'
import MessageProvider from './stateManagement/providers/messageProvider.tsx'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import InvitationDialog from './components/invitation/invitationDialog.tsx'

function App() {
  const [theme] = useState<string>('draeger')

  const [loginState, setLoginState] = useState(-1)

  useEffect(() => {
    let loginProcess = false
    console.log('evaluate tokens')
    const params = window.location.href.split('&')
    if (params.length > 1 && params[1].indexOf('state=STATE') === 0) {
      loginProcess = true
      console.log('Current URL: ', window.location.href)
      evaluateTokens()
        .then(async (e) => {
          console.log('e, ', e)
          loginProcess = false
          userLogin()
        })
        .catch((e) => {
          console.error('Error: ', e)
          debugger
        })
    }

    //handle user identification and login redirection
    const validateTokenState = validateToken()

    if (!loginProcess) {
      console.debug(
        'Login auth: ',
        validateTokenState.authToken && validateTokenState.refreshToken,
      )

      if (!validateTokenState.refreshToken) {
        console.log('No refresh token found')
        openLoginPage()
        return
      } else {
        userLogin()
      }
    }
  }, [])

  const userLogin = () => {
    console.log('Login State: ', loginState)
    try {
      UserService.identifyUser().then((e) => {
        if (e) {
          if (e.status === 401) {
            // reset cookies
            deleteTokens()
            // redirect to login page
            openLoginPage()
            return
          }
          setLoginState(1)
          if (window.location.hash.indexOf('state=STATE') === 1) {
            window.location.href = window.location.href.split('#')[0]
          }
          return
        }
        console.log('Identify failed')
        setLoginState(0)
        return
      })
    } catch (e) {
      console.error('Error: ', e)
      setLoginState(0)
      deleteTokens()
    }
    let params = new URLSearchParams(document.location.search)
    let stateParam = params.get('state')
    if (stateParam) {
      stateParam = stateParam.replace('STATE-', '')
      const stateParamAsString = atob(stateParam)
      const stateParamAsJSON = JSON.parse(stateParamAsString)
      window.location.href = stateParamAsJSON.redirectUri
    }
    //window.history.replaceState({}, document.title, window.location.pathname)
  }

  const Providers = BuildProviderTree([
    <NextUIProvider children={''} />,
    <MainProvider />,
    <MessageProvider />,
  ])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            loginState === 1 ? (
              <Providers>
                <ContextPopup />
                <FeedbackAlert />
                {/*<LegalDialog*/}
                {/*  termsOfUse={GAIA_LEGAL_JSON}*/}
                {/*  resourceId={'gaia'}*/}
                {/*  language="de"*/}
                {/*></LegalDialog>*/}
                {/* don't set termsOfUse to open GAIA ToU-dialog */}
                {/*<LegalDialog></LegalDialog>*/}
                <InvitationDialog></InvitationDialog>
                <div className={'flex flex-col h-screen'}>
                  {/* Drawer left side */}
                  <AppDrawer></AppDrawer>
                </div>
              </Providers>
            ) : (
              <LoadingScreen isLoading={loginState !== 1}></LoadingScreen>
            )
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
