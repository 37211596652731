import React, { useCallback } from 'react'
import './sessionGroups.css'
import { iSession } from '../chatWindow/iSession.ts'
import SessionItem from './sessionItem.tsx'
import { iSessionGroup } from './interfaces/iSessionGroup.ts'
import { iTeam } from '../sessionControls/userManagement/iTeam.tsx'

type SessionGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  group?: iSessionGroup
  searchValue: string
  selectedTeam: iTeam | null
  sessions: iSession[]
}
function SessionGroup({
  group,
  searchValue,
  selectedTeam,
  sessions,
}: SessionGroupProps) {
  const filteredSessions = useCallback(() => {
    return Array.from(sessions)
      .sort((a, b) => (a.modified! > b.modified! ? -1 : 1))
      .filter((session) =>
        group?.id ? session.groupId === group?.id : session.groupId === null,
      )
      .filter((session) => session.title.toLowerCase().indexOf(searchValue) >= 0)
  }, [selectedTeam, searchValue, sessions])

  return (
    <>
      {filteredSessions().map((session, key) => (
        <SessionItem session={session} key={key}></SessionItem>
      ))}
    </>
  )
}

export default SessionGroup
