import React, { useState } from 'react'

import OptionsMenu from '../utils/optionsMenu.tsx'
import './sessionGroups.css'
import { iSessionGroup } from './interfaces/iSessionGroup.ts'
import {
  Cog6ToothIcon,
  CommandLineIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import DialogSessionGroupModal from './dialogSessionGroupModal.tsx'
import DialogFolderOptions from './dialogFolderOptions.tsx'
import useGroupStore from '../../stateManagement/groupState.ts'
import { shallow } from 'zustand/shallow'
type SessionGroupTitelProps = React.HTMLAttributes<HTMLButtonElement> & {
  group: iSessionGroup
}
const SessionGroupTitel = ({ group }: SessionGroupTitelProps) => {
  const deleteGroup = useGroupStore((state) => state.deleteGroup, shallow)

  const [dialogSessionGroupModalDialog, setDialogSessionGroupModalDialog] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [dialogSendMessageToGroup, setDialogSendMessageToGroup] =
    useState<boolean>(false)

  const handleGroupDelete = async (group: iSessionGroup) => {
    if (!confirm('Are you sure you want to delete this group?')) {
      return
    }
    setIsLoading(true)
    await deleteGroup(group)
    setIsLoading(false)
  }

  /**
   * Event when creating a new group/folder
   * @param newGroup
   */
  const handleCreateGroup = async () => {}

  /**
   * Reset the variables for the group modal on modal close
   */
  const onGroupModalClose = () => {
    setDialogSessionGroupModalDialog(false)
  }

  return (
    <>
      {/* Send to group dialog */}
      <DialogFolderOptions
        group={group}
        open={dialogSendMessageToGroup}
        onClose={() => setDialogSendMessageToGroup(false)}
      />

      {/* Select User group for Chat group */}
      <DialogSessionGroupModal
        open={dialogSessionGroupModalDialog}
        group={group}
        onCreated={handleCreateGroup}
        onClose={onGroupModalClose}
      />

      <div className={'flex justify-between items-center'}>
        <div>
          <p className={''}>{group.title}</p>
        </div>
        {/* options menu must be on the right */}
        <div className={''}>
          <OptionsMenu
            isLoading={isLoading}
            items={[
              {
                label: 'Edit Folder',
                method: async () => setDialogSessionGroupModalDialog(true),
                icon: <PencilSquareIcon className={'h-5 w-5'} />,
              },
              {
                label: 'Options',
                method: () => {
                  setDialogSendMessageToGroup(true)
                },
                disabled: true,
                icon: <Cog6ToothIcon className={'h-5 w-5'} />,
                showDivider: true,
              },
              {
                label: 'Delete',
                method: async () => await handleGroupDelete(group),
                className: 'text-danger',
                icon: <TrashIcon className={'h-5 w-5'} />,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default SessionGroupTitel
