import React from 'react'
import './sessions.css'
import { Popup } from '../sessionControls/contextPopup.tsx'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import { Button, Tooltip } from '@nextui-org/react'
import { getUserRole } from '../login/authentication.tsx'
import { twMerge } from 'tailwind-merge'
import useTeamStore from '../../stateManagement/teamState.ts'
import { shallow } from 'zustand/shallow'

type ManageTeamsButtonProps = React.HTMLAttributes<HTMLDivElement>
const ManageTeamsButton = ({ className }: ManageTeamsButtonProps) => {
  const { setPopup } = useMain()
  const { isLoading } = useTeamStore(
    (state) => ({ isLoading: state.isLoading }),
    shallow,
  )
  const classes = twMerge(className)
  return (
    <Tooltip
      content="Manage and show your teams"
      color={'primary'}
      className={'max-w-96'}
    >
      <Button
        variant={'light'}
        isIconOnly
        isLoading={isLoading}
        className={classes}
        onClick={() => setPopup(Popup.UserManagement)}
      >
        <UserPlusIcon
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        />
      </Button>
    </Tooltip>
  )
}

export default ManageTeamsButton
