interface CitationProps {
  cid: string
  backHRefs: string[]
  text: string
  onClick: () => void
}

const Citation = (props: CitationProps) => {
  return (
    <div data-id={`cid-${props.cid}`}>
      <sup className="mr-2">{props.cid}</sup>
      <span
        className={'text-sm font-semibold hover:underline cursor-pointer'}
        onClick={props.onClick}
      >
        {props.text}
      </span>
      {props.backHRefs.map((href: string) => (
        <a href={href} data-footnote-backref="" className="data-footnote-backref">
          ↩
        </a>
      ))}
    </div>
  )
}

export default Citation
