import React from 'react'
import FeedbackWidget from './feedbackWidget'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'

interface iSessionFeedbackProps {
  sessionId: string
  closeFunction?: () => void
}

function SessionFeedback({ sessionId, closeFunction }: iSessionFeedbackProps) {
  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={closeFunction}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent>
        <ModalHeader>Rate this session</ModalHeader>
        <ModalBody>
          <p>Did you like this session?</p>
          <p className="py-8 text-center text-3xl">
            <FeedbackWidget
              ratingType="thumbs"
              commentingEnabled={false}
              entityType="session"
              entityId={sessionId}
              onFeedbackSaved={closeFunction}
            ></FeedbackWidget>
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="btn float-right" onClick={closeFunction}>
            Skip
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SessionFeedback
