import React, { useEffect, useState } from 'react'
import { HttpConstantSet, iHttpConstant, iHttpConstantItem } from './model'
import HttpConstantEditor from './httpConstantEditor'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@nextui-org/react'

const tooltipConstants =
  'Constants are constant values that are used in the request. They can be used in the URL, headers, cookies, or the request body. If you will have JSON data in your request body you need to set the Content-Type header to application/json using a constant or a parameter. Constants can be marked as templates, which means that parts of form {parameterName} will be replaced by parameter values at runtime.'

interface iHttpConstantSetEditorProps {
  constantSet: HttpConstantSet
  onChange: (constantSet: HttpConstantSet) => void
}

function HttpConstantSetEditor({
  constantSet,
  onChange,
}: iHttpConstantSetEditorProps) {
  const [constants, setConstants] = useState<iHttpConstantItem[]>(
    toConstantsList(constantSet),
  )
  const [isExpanded, setIsExpanded] = useState(false)

  function toConstantsList(constants: HttpConstantSet): iHttpConstantItem[] {
    return Object.keys(constants).map((name) => ({
      name,
      constant: constants[name],
    }))
  }

  function toConstantsSet(constants: iHttpConstantItem[]): HttpConstantSet {
    const set: HttpConstantSet = {}
    constants.forEach((item) => {
      set[item.name] = item.constant
    })
    return set
  }

  const handleNameChange = (index: number, newName: string) => {
    setConstants(
      constants.map((item, i) => {
        if (i === index) {
          return { name: newName, constant: item.constant }
        } else {
          return item
        }
      }),
    )
  }

  const handleValueChange = (index: number, constant: iHttpConstant) => {
    setConstants(
      constants.map((item, i) => {
        if (i === index) {
          return { name: item.name, constant }
        } else {
          return item
        }
      }),
    )
  }

  useEffect(() => {
    onChange(toConstantsSet(constants))
  }, [constants])

  const addNewConstant = () => {
    setConstants([
      ...constants,
      {
        name: '',
        constant: { value: '', in: 'query', isTemplate: false, isSecret: false },
      },
    ])
    setIsExpanded(true)
  }

  const removeConstant = (index: number) =>
    setConstants(constants.filter((_, i) => i !== index))

  return (
    <div className="mb-4">
      <label className="label ">
        <span className="label-text flex  text-base">
          Constants ({constants.length})
          <Tooltip
            content={tooltipConstants}
            color={'primary'}
            className={'max-w-96'}
          >
            <InformationCircleIcon
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.0}
              stroke="currentColor"
              className="w-4 h-4 ml-1"
            />
          </Tooltip>
        </span>
      </label>
      <div className="ml-0">
        {constants &&
          constants.map((item, i) => (
            <HttpConstantEditor
              index={i}
              item={item}
              onNameChange={handleNameChange}
              onValueChange={handleValueChange}
              onRemove={removeConstant}
            />
          ))}
      </div>
      <div className="text-center">
        <PlusCircleIcon
          onClick={addNewConstant}
          className="w-8 h-8 inline cursor-pointer"
        />
      </div>
    </div>
  )
}
export default HttpConstantSetEditor
