import axiosClient from '../utils/axiosClient'
import { iFeedback } from '../components/chatWindow/feedback/iFeedback'

export class FeedbackService {
  private static instance: FeedbackService

  private constructor() {}

  public static getInstance(): FeedbackService {
    if (!FeedbackService.instance) {
      FeedbackService.instance = new FeedbackService()
    }

    return FeedbackService.instance
  }

  public async upsertFeedback(feedback: iFeedback): Promise<iFeedback> {
    if (!feedback.id) {
      const res = (await axiosClient.post(`/feedback`, feedback)).data
      return res as iFeedback
    }
    return axiosClient.put(`/feedback`, feedback).then(() => feedback)
  }
}

export default FeedbackService.getInstance() as FeedbackService
