import React, { useState } from 'react'
import iSettingsProps from '../../settings/iSettingsProps'
import { iPlugin } from './iPlugin'
import PluginCreateEdit from './pluginCreateEdit'
import { PluginViewTab } from './iPluginSettings.ts'
import PluginView from './pluginView.tsx'
import { Modal, ModalBody, ModalContent } from '@nextui-org/react'

interface iPluginProps extends iSettingsProps {
  selector: 'Selection' | 'Action'
}

function PluginSettings({ closeFunction, selector }: iPluginProps) {
  const [pluginViewTab, setPluginViewTab] = useState<PluginViewTab>(
    selector === 'Selection' ? PluginViewTab.Selection : PluginViewTab.Action,
  )
  const [currentPlugin, setCurrentPlugin] = useState<iPlugin | undefined>(undefined)

  const editPluginFunction = (plugin: iPlugin | undefined) => {
    setCurrentPlugin(plugin)
    setPluginViewTab(PluginViewTab.Edit)
  }

  const backToPluginView = () => {
    if (selector === 'Selection') {
      setPluginViewTab(PluginViewTab.Selection)
    } else {
      setPluginViewTab(PluginViewTab.Action)
    }
  }

  let currentComponent = <></>
  switch (pluginViewTab) {
    case PluginViewTab.Selection:
      currentComponent = (
        <PluginView
          closeFunction={closeFunction}
          editPluginFunction={editPluginFunction}
          selector={selector}
        />
      )
      break
    case PluginViewTab.Action:
      currentComponent = (
        <PluginView
          closeFunction={closeFunction}
          editPluginFunction={editPluginFunction}
          selector={selector}
        />
      )
      break
    case PluginViewTab.Edit:
      currentComponent = (
        <PluginCreateEdit
          closeFunction={backToPluginView}
          currentPlugin={currentPlugin}
        />
      )
      break
  }

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={true}
      onClose={closeFunction}
      classNames={{
        base: '!max-w-[100vw] w-fit',
      }}
    >
      <ModalContent className={''}>{currentComponent}</ModalContent>
    </Modal>
  )
}

export default PluginSettings
