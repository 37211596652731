import { iSettings } from '../../settings/iSettings.ts'
import { iPlugin } from '../plugins/iPlugin.ts'
import { iKnowledgeContainer } from '../knowledgeContainer/iKnowledgeContainer.ts'
import { iItem } from '../../../interfaces/iItem.ts'
import { iPluginSettings } from '../plugins/iPluginSettings.ts'
import { iRole, iShareLink } from '../../../interfaces/iShared.ts'
import { IAssistantModel } from '../../../interfaces/iAssistantModel.ts'
import { iTermsOfUse } from '../../legal/iTermsOfUse.ts'

export interface iAssistant extends iItem {
  // backend
  baseSessionSettingsId?: string | null
  baseSessionSettingsCopyDate?: Date
  title: string
  description?: string
  instruction?: string
  pluginIds?: string[]
  pluginsSettings?: iPluginSettings[]
  knowledgeContainerIds?: string[]
  teamReferences?: string[] | null | undefined
  settings?: iSettings
  isPublic?: boolean
  blueprint?: boolean
  editable?: boolean
  ownerName?: string
  ownerId?: string
  ownerEmail?: string
  functionRetryCount?: number
  roles?: iRole[]
  archiveChatHistory?: boolean

  // frontend
  files?: File[]
  plugins?: iPlugin[]
  knowledgeContainer?: iKnowledgeContainer[]
  selected?: boolean
  termsOfUse?: iTermsOfUse
}

export interface IAssistantDetails {
  models: IAssistantModel[]
  knowledgeContainers: iKnowledgeContainer[]
  plugins: iPlugin[]
}
export interface iAssistantPopupView {
  closeFunction: () => void
  variant: AssistantViewVariant
}

export enum AssistantViewVariant {
  Manage,
  // Selection,
  ChatSelection,
}

export interface iAssistantEdit {
  currentPreSetting: iAssistant
  closeFunction: () => void
}

/**
 * Used for the Assistant popups
 */
export enum AssistantTab {
  // Manage Assistants
  Containers,
  // Edit Assistant
  Edit,
  // show details on Assistant popup
  Inspect,
  // show details on Assistant selection view
  OnlyInspect,
  // Create new assistant
  New,
}

export interface iBlobManageFiles {
  containerid: string
  closeFunction: () => void
  submitFunction: () => void
}

export interface iBlobList {
  node: string
  // name: string;
  type: ContainerType
  lastModified: string | null
  size: number
  children: iBlobList[]

  showChildren: boolean
  // selected: boolean;
  file: File | null
  newStatus: newStatus
}

export enum ContainerType {
  Folder,
  File,
}

export interface iBlobDictionary {
  [key: string]: iBlobList
}

export enum newStatus {
  None,
  Existing,
  New,
  Deleted,
  Updated,
}
