import { iShared, iShareLink } from './iShared'

export interface iItem {
  id?: string
  created?: Date
  modified?: Date
  shared?: iShared[]
  sharedType?: ShareType
  shareLinks?: iShareLink[]
}

export enum ShareType {
  None,
  Public,
  Limited,
}
