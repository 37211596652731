import React, { useEffect, useState } from 'react'
import { iMessage } from './iMessage'

import { useMessages } from '../../stateManagement/contexts/messageContext'
import { useMain } from '../../stateManagement/contexts/mainContext'
import { AssistantStatus, iSession } from './iSession.ts'
import { SignalRStatus } from '../../enums/signalRStatus.ts'

import useSignalRStore, {
  iAssistantPayload,
} from '../../stateManagement/signalRState.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import useUserProfileStore from '../../stateManagement/userProfileState.ts'
import './chatWindow.css'
import ChatWindowMessageBar from './chatWindowMessageBar.tsx'
import ChatWindowContent from './chatWindowContent.tsx'
import { shallow } from 'zustand/shallow'

interface iChatWindowProps {
  theme: string
  session: iSession
}

function ChatWindow({ session }: iChatWindowProps) {
  const { updateSession } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
    }),
    shallow,
  )

  const { measureAndSendGenerationTime } = useMessages()
  const { signalRstatus, connection, joinSession } = useSignalRStore(
    (state) => ({
      signalRstatus: state.signalRstatus,
      connection: state.connection,
      joinSession: state.joinSession,
    }),
    shallow,
  )
  const { setError } = useMain()
  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const messageAnchor = React.useRef<HTMLDivElement>(null)

  const [displayType, setDisplayType] = useState<
    'intro' | 'assistant' | 'chat' | null
  >(null)
  const [_, setAppearingMessage] = useState<iMessage | null>(null)
  const [assistantPayload, setAssistantPayload] = useState<iAssistantPayload | null>(
    null,
  )

  // const [content, setContent] = React.useState('');

  const upsertSessionMessage = (
    session: iSession,
    message: iMessage,
  ): iMessage[] => {
    let messages: iMessage[] = []
    if (session.messages) {
      messages = [...session.messages]
    }

    const messageToUpdate = messages.find(
      (m) => m.id === message.id && m.role === 'assistant',
    )
    if (!messageToUpdate) {
      //check if empty assistant message exists
      const emptyAssistantMessageIndex = messages.findIndex(
        (m) => !m.id && m.role === 'assistant',
      )
      if (emptyAssistantMessageIndex > -1) {
        messages[emptyAssistantMessageIndex] = message
        return messages
      }
      // append message
      messages.push(message)
      console.log('Message pushed', message)
    } else {
      // update message
      messages = messages!.map((m) => (m.id === message.id ? message : m))
    }

    return messages
  }

  const onSessionMessageReceived = (message: iMessage) => {
    if (!session) return
    if (message.sessionId !== session.id) return

    session.messages = upsertSessionMessage(session, message)
    setAppearingMessage(message) // triggers re-render
  }

  const onCompletionFinished = (message: iMessage) => {
    if (!session) return
    if (message.sessionId !== session.id) return

    session.messages = upsertSessionMessage(session, message)
    session!.isGenerating = false
    measureAndSendGenerationTime(session)
    setAppearingMessage(message) // triggers re-render
  }

  // Subscribe to the group (session) when the component mounts and unsubscribe when it unmounts
  useEffect(() => {
    if (!session) return
    if (!connection) return

    if (signalRstatus !== SignalRStatus.Connected) return

    joinSession(session.id!, userProfile!.id!)
    // Replace 'ReceiveMessage' with your actual event name
    connection.on('completionUpdate', (message: iMessage) =>
      onSessionMessageReceived(message),
    )
    connection.on('completionFinish', (message: iMessage) =>
      onCompletionFinished(message),
    )
    connection.on('completionError', (e: string) => {
      console.error('CompletionError', e), setError(e)
    })
    connection.on('AssistantError', (e: string) => {
      console.error('AssistantError', e), setError(e)
      session!.isGenerating = false
      session!.assistantStatus = AssistantStatus.Idle
      measureAndSendGenerationTime(session)
    })
    connection.on('AssistantCancelled', (e: string) => {
      console.error('AssistantCancelled', e), setError(e)
      session!.isGenerating = false
      session!.assistantStatus = AssistantStatus.Idle
      measureAndSendGenerationTime(session)
    })
    connection.on('StartAssistant', (assistantPayload: iAssistantPayload) => {
      setAssistantPayload(assistantPayload)
    })
    connection.on('title', ({ title, sessionId }) => {
      if (session && session.id === sessionId) {
        session.title = title
        updateSession(session)
      }
    })
  }, [session, signalRstatus])

  useEffect(() => {
    const messages = session?.messages?.filter(
      (m: iMessage) => m.contentType !== 'systemMessage',
    )
    if (!messages?.length) setDisplayType('assistant')
    else if (messages && messages.length > 0) setDisplayType('chat')
    else setDisplayType('intro')
  }, [session, session.messages?.length])

  const createDummyAssistantMessage = () => {
    const dummyMessage: iMessage = { role: 'assistant', sessionId: session.id! }
    session.messages = upsertSessionMessage(session, dummyMessage)
    session.isGenerating = true
  }
  return (
    session && (
      <div className="chat-window-wrapper">
        <div
          className={`chat-window flex pt-2 flex-col max-w-full w-full mx-auto px-2 sm:px-4 absolute h-full overflow-auto`}
          id={'chat-window_' + session.id}
        >
          <div
            className={` w-full flex-1 chat-content`}
            ref={messageAnchor}
            id={'chatMessages'}
          >
            <ChatWindowContent
              displayType={displayType}
              session={session}
            ></ChatWindowContent>
          </div>
          <ChatWindowMessageBar
            assistantPayload={assistantPayload}
            displayType={displayType}
            changeDisplayType={(type) => setDisplayType(type)}
            session={session}
            afterSendUserMessage={createDummyAssistantMessage}
          ></ChatWindowMessageBar>
        </div>
      </div>
    )
  )
}

export default ChatWindow
