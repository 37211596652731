import { create } from 'zustand'
import { iAssistant } from '../components/sessionControls/assistants/iAssistantTypes.tsx'
import sessionSettingsService from '../services/sessionSettingsService.ts'
import { Role } from '../interfaces/iShared.ts'
import { getUserKey } from '../components/login/authentication.tsx'
import SystemPromptService from '../services/sessionSettingsService.ts'

interface IAssistantState {
  isLoading: boolean
  assistants: iAssistant[]
  getAssistantRole: (assistantId: string) => Role | undefined
  hasRightTo: (minRole: Role, assistantId: string) => boolean
  updateLocalAssistant: (assistant: iAssistant) => void
  deleteAssistant: (id: string) => Promise<void>
  createAssistant: (assistant: iAssistant) => Promise<iAssistant>
  setIsLoading: (isLoading: boolean) => void
  getAssistantsByContext: (context: 'all' | 'team', teamId?: string) => iAssistant[]
  setAssistants: (assistants: iAssistant[]) => void
  loadAssistants: () => Promise<iAssistant[]>
}

const useAssistantStore = create<IAssistantState>((set, getState) => ({
  isLoading: false,
  assistants: [],
  setAssistants: (assistants: iAssistant[]) => set(() => ({ assistants })),
  deleteAssistant: async (id) => {
    await SystemPromptService.deleteSessionSettings(id)
    const newAssistants = getState().assistants.filter(
      (assistant) => assistant.id !== id,
    )
    console.log(newAssistants)
    getState().setAssistants(newAssistants)
  },
  createAssistant: async (assistant: iAssistant) => {
    const newAssistant = await SystemPromptService.createSessionSettings(assistant)
    const tmpAssistants = [...getState().assistants, newAssistant]
    getState().setAssistants(tmpAssistants)
    console.log(tmpAssistants)
    return newAssistant
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  getAssistantRole: (assistantId) => {
    const assistant = getState().assistants.find(
      (a: iAssistant) => a.id === assistantId,
    )
    const shared = assistant?.shared?.find((s) => s.id === getUserKey())

    return shared?.role
  },
  getAssistantsByContext: (context: 'all' | 'team', teamId?: string) => {
    const assistants = getState().assistants
    if (context === 'team') {
      if (!teamId) throw new Error('teamId param is necessary for team context')
      return assistants.filter((a) => {
        return a.teamReferences?.find((teamRefId) => teamRefId === teamId)
      })
    }
    return assistants
  },
  hasRightTo: (minRole: Role, assistantId) => {
    const assistant = getState().assistants.find(
      (a: iAssistant) => a.id === assistantId,
    )
    const isOwner = assistant?.ownerId === getUserKey()
    if (isOwner) return true
    const role = getState().getAssistantRole(assistantId)
    if (role === undefined) return false
    return role <= minRole
  },
  loadAssistants: async () => {
    const blueprints = await sessionSettingsService.getBlueprintAssistants()
    getState().setAssistants(blueprints)
    return blueprints
  },
  updateLocalAssistant: (assistant) => {
    const allAssistants = getState().assistants
    const assistantIndex = allAssistants.findIndex((a) => a.id === assistant.id)
    if (assistantIndex > -1) {
      allAssistants[assistantIndex] = assistant
      getState().setAssistants([...allAssistants])
    }
  },
}))

export default useAssistantStore
