export class InputField {
  key = ''
  name = ''
  info = ''

  protected constructor(key: string, name: string, info?: string) {
    this.key = key
    this.name = name
    this.info = info || ''
  }
}

export class TextField extends InputField {
  value = ''
  lines? = 1
  placeholder? = ''
  isSecret? = false

  constructor({
    key,
    name,
    info,
    value,
    lines,
    placeholder,
    isSecret,
  }: {
    key: string
    name: string
    info?: string
    value: string
    lines?: number
    placeholder?: string
    isSecret?: boolean
  }) {
    super(key, name, info)
    this.value = value
    if (lines && lines < 1) {
      throw new Error(
        `Invalid number of lines for text field: ${lines}, expected >= 1`,
      )
    }
    this.lines = lines || 1
    this.placeholder = placeholder || ''
    this.isSecret = isSecret ?? false
  }
}

export class FlagField extends InputField {
  value = false

  constructor({
    key,
    name,
    info,
    value,
  }: {
    key: string
    name: string
    info?: string
    value: boolean
  }) {
    super(key, name, info)
    this.value = value
  }
}

export interface SelectOption {
  key: string
  display?: string
}

export class SelectField extends InputField {
  value: SelectOption
  options: SelectOption[] = []

  constructor({
    key,
    name,
    info,
    value,
    options,
  }: {
    key: string
    name: string
    info?: string
    value: SelectOption
    options: SelectOption[]
  }) {
    super(key, name, info)
    const optionKeys = options.map((opt) => opt.key)
    if (!optionKeys.includes(value.key)) {
      throw new Error(
        `Invalid key for select field: ${value.key}, expected one of ${optionKeys.join(', ')}`,
      )
    }
    this.value = value
    this.options = options
  }
}

export class EnumField extends InputField {
  values: string[] = []

  constructor({
    key,
    name,
    info,
    values,
  }: {
    key: string
    name: string
    info?: string
    values?: string[]
  }) {
    super(key, name, info)
    this.values = values || []
  }
}
