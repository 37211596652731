import React from 'react'
import './sessions.css'
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

export type OptionsMenuProps = {
  items: Array<{
    method: () => void
    label: string
    icon?: React.ReactNode
    disabled?: boolean
    showDivider?: boolean
    className?: string | undefined
  }>
  isLoading?: boolean
}

function OptionsMenu({ items, isLoading }: OptionsMenuProps) {
  const executeFunction = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    func: () => void,
  ) => {
    event.stopPropagation()
    func()
  }

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          isIconOnly
          variant={'light'}
          isLoading={isLoading}
          disabled={isLoading}
        >
          <EllipsisVerticalIcon className="w-5 h-5" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions">
        {items.map((value, key) => {
          return (
            <DropdownItem
              textValue={value.label}
              key={key}
              showDivider={value.showDivider}
              startContent={value.icon}
              isDisabled={value.disabled}
              className={value.className}
              onClick={(event) => executeFunction(event, value.method)}
            >
              <a>{value.label}</a>
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default OptionsMenu
