import axiosClient from '../utils/axiosClient.ts'
import { iFileAttachment } from '../components/fileAttachments/iFileAttachment.ts'

class FileAttachmentsService {
  private static instance: FileAttachmentsService

  private constructor() {}

  public static getInstance(): FileAttachmentsService {
    if (!FileAttachmentsService.instance) {
      FileAttachmentsService.instance = new FileAttachmentsService()
    }

    return FileAttachmentsService.instance
  }

  public getFileAttachmentLink(attachment: iFileAttachment): string {
    return `${axiosClient.getUri()}/attachment/${attachment.id}?shared&name=${attachment.fileName}`
  }
}

export default FileAttachmentsService.getInstance()
