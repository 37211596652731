import { create } from 'zustand'
import { iSessionGroup } from '../components/sessionsGroups/interfaces/iSessionGroup.ts'
import GroupService from '../services/groupService.ts'
import SessionState, { getLastModifiedSession } from './sessionState.ts'
import { iSession } from '../components/chatWindow/iSession.ts'

interface ITeamState {
  isLoading: boolean
  groups: iSessionGroup[]
  addGroup: (
    group: iSessionGroup,
    withoutNewSession?: boolean,
  ) => Promise<iSessionGroup | undefined>
  updateGroup: (group: iSessionGroup) => Promise<void>
  deleteGroup: (group: iSessionGroup) => Promise<void>
  getGroups: (options: { teamId?: string }) => Promise<void>
  setIsLoading: (isLoading: boolean) => void
  setGroups: (groups: iSessionGroup[]) => void
}

const useGroupStore = create<ITeamState>((set, getState) => ({
  isLoading: false,
  groups: [],
  addGroup: async (group, withoutNewSession) => {
    try {
      const newGroup = await GroupService.createGroup(group)
      set(() => ({ groups: [...(getState().groups || []), newGroup] }))
      if (!withoutNewSession) {
        const session: iSession = {
          groupId: newGroup.id!,
          teamId: newGroup.billingGroupId,
          title: 'New Session',
          isActive: false,
        }
        await SessionState.getState().addSession(session)
      }
      return newGroup
    } catch (error) {
      console.error(error)
    } finally {
      console.log('Added Group: ', group)
    }
  },
  updateGroup: async (group: iSessionGroup) => {
    try {
      await GroupService.updateGroup(group)
      const groups = getState().groups
      const updatedGroups = (groups || []).map((g) =>
        g.id === group.id ? group : g,
      )
      set(() => ({ groups: updatedGroups }))
    } catch (error) {
      console.error(error)
    } finally {
      console.log('Updated Group: ', group)
    }
  },
  deleteGroup: async (group: iSessionGroup) => {
    try {
      await GroupService.deleteGroup(group.id!)
    } catch (error) {
      console.error(error)
    } finally {
      const groupsLeft = getState().groups.filter((g) => g.id !== group.id)
      getState().setGroups(groupsLeft)
      // check if activeSession is based on deleted group
      const newSessions = SessionState.getState().removeSessionsByGroup(group)
      const lastModifiedSession = getLastModifiedSession(newSessions)
      if (lastModifiedSession) {
        await SessionState.getState().activateSession(lastModifiedSession, true)
      } else {
        SessionState.getState().setActiveSession(null)
      }
      console.log('Deleted Group: ', group)
    }
  },
  getGroups: async ({ teamId }) => {
    let loadedGroups: iSessionGroup[] = []
    getState().setIsLoading(true)
    try {
      loadedGroups = await GroupService.getGroups(teamId)
      set(() => ({ groups: loadedGroups }))
    } catch (error) {
      console.error('Error loadingScreen groups: ', error)
      getState().setIsLoading(false)
    } finally {
      console.log('Loaded Groups: ', loadedGroups)
      getState().setIsLoading(false)
    }
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setGroups: (groups: iSessionGroup[]) => set(() => ({ groups })),
}))

export default useGroupStore
