import { create } from 'zustand'
import { iSession } from '../components/chatWindow/iSession.ts'
import { iPlugin } from '../components/sessionControls/plugins/iPlugin.ts'
import SessionService from '../services/sessionService.ts'
import { iMessage } from '../components/chatWindow/iMessage.ts'
import SystemPromptService from '../services/sessionSettingsService.ts'
import GroupState from './groupState.ts'
import { debounce } from '../utils/debouncer.ts'
import pluginService from '../services/pluginService.ts'
import { iSessionGroup } from '../components/sessionsGroups/interfaces/iSessionGroup.ts'
import { iKnowledgeContainer } from '../components/sessionControls/knowledgeContainer/iKnowledgeContainer.ts'
import knowledgeContainerService from '../services/knowledgeContainerService.ts'

interface ISessionState {
  sessions: iSession[]
  activeSession: iSession | null
  isLoading: boolean
  allPlugins: iPlugin[]
  allKnowledgeContainers: iKnowledgeContainer[]
  setInstructions: (session: iSession, instructions: string) => Promise<iSession>
  updateSessionInternal: (session: iSession) => Promise<void>
  addSession: (session: iSession) => Promise<iSession | undefined>
  getSessionsByGroup: (group: iSessionGroup) => iSession[]
  removeSessionsByGroup: (group: iSessionGroup) => iSession[]
  updateSession: (session: iSession) => Promise<void>
  deleteSession: (session: iSession) => Promise<void>
  clearSession: (session: iSession) => Promise<void>
  getSession: (sessionId: string) => Promise<iSession | undefined>
  getSessions: (options: {
    teamId?: string
    sessionId?: string | null
  }) => Promise<iSession[] | undefined>
  activateSession: (session: iSession, ignoreLoading?: boolean) => Promise<void>
  getPlugins: (assistantId?: string) => Promise<iPlugin[]>
  getKnowledgeContainers: (assistantId?: string) => Promise<iKnowledgeContainer[]>
  setIsLoading: (isLoading: boolean) => void
  setAllPlugins: (plugins: iPlugin[]) => void
  setAllKnowledgeContainers: (plugins: iKnowledgeContainer[]) => void
  setSessions: (sessions: iSession[]) => void
  setActiveSession: (activeSession: iSession | null) => void
}

const useSessionStore = create<ISessionState>((set, getState) => ({
  isLoading: true,
  sessions: [],
  activeSession: null,
  allPlugins: [],
  allKnowledgeContainers: [],
  setAllPlugins: (allPlugins: iPlugin[]) => set(() => ({ allPlugins })),
  setAllKnowledgeContainers: (allKnowledgeContainers: iKnowledgeContainer[]) =>
    set(() => ({ allKnowledgeContainers })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setSessions: (sessions: iSession[]) => set(() => ({ sessions })),
  setActiveSession: (activeSession: iSession | null) =>
    set(() => ({ activeSession })),
  setInstructions: async (session: iSession, prompt: string) => {
    session.sessionSettings!.instruction = prompt
    if (
      session.messages &&
      session.messages.length > 0 &&
      session.messages![0].role === 'system' &&
      session.messages![0].contentType === 'systemMessage'
    ) {
      session.messages![0].content = prompt
      await SessionService.updateMessage(session.id!, session.messages![0])
      return session
    }
    // systemMessage has always a creation date of 1.1.1970

    const systemMsg: iMessage = {
      role: 'system',
      content: prompt,
      sessionId: session.id!,
      contentType: 'systemMessage',
      created: new Date(0),
    }

    const sysMsg = await SessionService.createMessage(session.id!, systemMsg)
    session.messages!.unshift(sysMsg)
    await SessionService.updateSession(session)
    return session
  },
  getSessionsByGroup: (group: iSessionGroup) => {
    return getState().sessions.filter((session) => session.groupId === group.id)
  },
  updateSessionInternal: async (session: iSession) => {
    const prevSessions = (getState().sessions || []).map((s) =>
      s.id === session.id ? session : s,
    )
    const activeSession = getState().sessions.find(
      (s) => s.id === getState().activeSession?.id,
    )
    if (activeSession) getState().setActiveSession(activeSession)
    getState().setSessions(prevSessions)
    //TODO: figure out why timeout is used
    await new Promise((r) => setTimeout(r, 1))
  },
  addSession: async (session: iSession) => {
    getState().setIsLoading(true)
    try {
      const s = await SessionService.createSession(session)
      getState().setSessions([...(getState().sessions || []), s])
      await getState().activateSession(s, true)
      const group = (GroupState.getState().groups || []).find(
        (group) => group.id === session.groupId,
      )
      if (group) {
        const ssId = group.settingsId
        if (ssId) {
          const sessionSettings = await SystemPromptService.getSessionSettings(ssId!)
          sessionSettings.id = s?.sessionSettingsId
          sessionSettings.blueprint = false
          s.sessionSettings = sessionSettings

          const updatedSession = await getState().setInstructions(
            s,
            sessionSettings!.instruction!,
          )
          updatedSession!.sessionSettings = sessionSettings
          await getState().updateSession(updatedSession!)
        }
      }
      getState().setIsLoading(false)
      return s
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      getState().setIsLoading(false)
    }
  },
  updateSession: async (session: iSession) => {
    autoHideMessages(session)
    getState().updateSessionInternal(session)
    debouncedUpdateSession(session)
  },
  removeSessionsByGroup: (group: iSessionGroup) => {
    const newSessions = getState().sessions.filter(
      (session) => session.groupId !== group.id,
    )
    getState().setSessions(newSessions)
    return newSessions
  },
  deleteSession: async (session: iSession) => {
    if (getState().activeSession?.id === session.id) getState().setIsLoading(true)
    try {
      await SessionService.deleteSession(session.id!)
      const sessions = getState().sessions
      const newSessions = sessions.filter(
        (prevSession) => prevSession.id !== session.id,
      )
      getState().setSessions(newSessions)
      const activeSession = getState().activeSession
      if (!sessions) return console.error('no sessions found')
      if (session.id === activeSession?.id) {
        // best fit (last modified) in group or first session
        const lastModifiedSession = getLastModifiedSession(newSessions)
        if (lastModifiedSession) {
          await getState().activateSession(lastModifiedSession, true)
        } else {
          getState().setActiveSession(null)
        }
      }
    } catch (error) {
      console.error('Error: ', error)
      getState().setIsLoading(false)
    } finally {
      console.log('Deleted Session: ', session)
      getState().setIsLoading(false)
    }
  },
  clearSession: async (session: iSession) => {
    getState().setIsLoading(true)
    try {
      // remove everything except the first system msg
      session.messages = []
      await SessionService.deleteMessages(session.id!)
      await getState().updateSession(session)
    } catch (error) {
      console.error(error)
    } finally {
      console.log('Cleared Session: ', session)
      getState().setIsLoading(false)
    }
  },
  getSession: async (sessionId) => {
    return await SessionService.getSession(sessionId)
  },
  getSessions: async ({ teamId, sessionId }) => {
    getState().setIsLoading(true)
    try {
      const loadedSessions = await SessionService.getSessions(teamId)
      getState().setSessions(loadedSessions)
      // handle initial session loading to activate last modified session
      const session =
        loadedSessions.find((session) => session.id === sessionId) ??
        getLastModifiedSession(loadedSessions)
      if (loadedSessions.length) await getState().activateSession(session, true)
      else getState().setActiveSession(null)
      return loadedSessions
    } catch (error) {
      console.error(error)
    } finally {
      getState().setIsLoading(false)
    }
  },
  activateSession: async (session: iSession, ignoreCatches: boolean = false) => {
    if (!ignoreCatches && getState().isLoading) {
      console.log('New session is already loading, be patient')
      return
    } else if (!ignoreCatches && session.id === getState().activeSession?.id) {
      console.log('Session is already active: ', session)
    } else {
      console.log('Activating Session: ', session)
      getState().setIsLoading(true)
      try {
        const loadedSession = await loadSession(session)
        const assistantId = session?.sessionSettings?.baseSessionSettingsId
        if (assistantId) {
          await getState().getPlugins(assistantId)
          await getState().getKnowledgeContainers(assistantId)
        }
        await getState().updateSessionInternal(loadedSession)
        getState().setActiveSession(loadedSession!)
        autoHideMessages(session)

        const promises = [] as Promise<void>[]
        promises.push(getState().updateSession(session))

        await Promise.all(promises)
        getState().setIsLoading(false)
      } catch (error) {
        console.error(error)
      } finally {
        console.log('Activated Session: ', session)
      }
    }

    getState().setIsLoading(false)
  },
  getPlugins: async (assistantId?: string) => {
    return pluginService.getPlugins(assistantId).then((plugins) => {
      getState().setAllPlugins(plugins)
      return plugins
    })
  },
  getKnowledgeContainers: async (assistantId?: string) => {
    return knowledgeContainerService
      .getKnowledgeContainerContainerList(assistantId)
      .then((plugins) => {
        getState().setAllKnowledgeContainers(plugins)
        return plugins
      })
  },
}))

export default useSessionStore

const debouncedUpdateSession = debounce(async (session: iSession) => {
  try {
    // remove unnecessary fields which are not used in the backend
    const sessionCopy = { ...session }
    delete sessionCopy.messages

    await SessionService.updateSession(sessionCopy)
    console.log('save session', session)
    if (session.sessionSettings) {
      await SystemPromptService.updateSessionSettings(session.sessionSettings)
    }
  } catch (error) {
    console.error('Error: ', error)
  }
}, 500)

/**
 * Auto hide messages that are not in the last x messages
 * Marks them with a yellow border
 * @param session
 */
function autoHideMessages(session: iSession): void {
  const messagesToConsider = session.messages || []
  if (!session.sessionSettings) return

  const messagesToConsiderCount =
    session.sessionSettings!.settings!.transferLength === 21
      ? messagesToConsider.length
      : session.sessionSettings!.settings!.transferLength
  const latestMessages = messagesToConsider.slice(-messagesToConsiderCount!)
  const latestMessageIds = latestMessages.map((message) => message.id)

  // loop through all messages and set hidden property
  messagesToConsider.forEach((message) => {
    message.autoHide = !latestMessageIds.includes(message.id)
  })
}

async function loadSession(session: iSession): Promise<iSession> {
  console.log('Loading session: ', session)
  const tasks: Promise<unknown>[] = []
  tasks.push(
    SessionService.getMessages(session.id!).then((messages) => {
      session.messages = messages
    }),
  )
  tasks.push(
    SystemPromptService.getSessionSettings(session.sessionSettingsId!).then(
      (settings) => {
        session.sessionSettings = settings
      },
    ),
  )

  await Promise.all(tasks)
  return session
}

export const getLastModifiedSession = (sessions: iSession[]) => {
  // if no session is active, activate the last modified session

  const lastModified = sessions.sort((a, b) => {
    const dateA = a.modified instanceof Date ? a.modified : new Date(a.modified!)
    const dateB = b.modified instanceof Date ? b.modified : new Date(b.modified!)
    return dateB.getTime() - dateA.getTime()
  })[0]
  return lastModified
}
