import React, { useState } from 'react'
import iSettingsProps from '../../settings/iSettingsProps.ts'
import { BlobViewTab, EBlobContainerViewVariant } from './iBlobUpload.tsx'
import BlobContainerView from './knowledgeContainerView.tsx'
import { iKnowledgeContainer } from './iKnowledgeContainer.ts'
import BlobAddEditContainer from './knowledgeContainerAddEditContainer.tsx'
import BlobManageFiles from './knowledgeContainerManageFiles.tsx'
import { Modal, ModalBody, ModalContent } from '@nextui-org/react'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import { getUserKey } from '../../login/authentication.tsx'
import { shallow } from 'zustand/shallow'

interface iBlobSettingsProps extends iSettingsProps {
  selector: boolean
}

function BlobSettings({ closeFunction, selector }: iBlobSettingsProps) {
  const { activeSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
    }),
    shallow,
  )
  const [currentContainer, setCurrentContainer] =
    useState<iKnowledgeContainer | null>(null)
  const [blobViewTab, setBlobViewTab] = useState<BlobViewTab>(
    !selector ? BlobViewTab.Containers : BlobViewTab.Selection,
  )

  const editContainerFunction = (container: iKnowledgeContainer) => {
    setCurrentContainer(container)
    setBlobViewTab(BlobViewTab.Edit)
  }

  const manageContainerFunction = (container: iKnowledgeContainer) => {
    setCurrentContainer(container)
    setBlobViewTab(BlobViewTab.ManageFiles)
  }

  const addContainerFunction = () => {
    const container: iKnowledgeContainer = {
      name: '',
      description: '',
      created: new Date(),
      modified: new Date(),
      ownerName: '',
      teamId: '',
      ownerEmail: '',
      ownerId: getUserKey(),
      lastModifiedBy: '',
      shared: [],
      editable: true,
      fromBaseAssistantInherited: false,
    }

    setCurrentContainer(container)
    setBlobViewTab(BlobViewTab.Edit)
  }

  let currentComponent = <></>
  switch (blobViewTab) {
    case BlobViewTab.Containers:
      currentComponent = (
        <BlobContainerView
          closeFunction={closeFunction}
          editContainerFunction={editContainerFunction}
          addContainerFunction={addContainerFunction}
          manageContainerFunction={manageContainerFunction}
          variant={EBlobContainerViewVariant.Action}
        />
      )
      break
    case BlobViewTab.Selection:
      currentComponent = (
        <BlobContainerView
          closeFunction={closeFunction}
          editContainerFunction={editContainerFunction}
          addContainerFunction={addContainerFunction}
          manageContainerFunction={manageContainerFunction}
          variant={EBlobContainerViewVariant.Selection}
        />
      )
      break
    case BlobViewTab.Edit:
      currentComponent = (
        <BlobAddEditContainer
          currentContainer={currentContainer!}
          closeFunction={() => setBlobViewTab(BlobViewTab.Containers)}
        />
      )
      break
    case BlobViewTab.ManageFiles:
      currentComponent = (
        <BlobManageFiles
          containerid={currentContainer!.id!}
          closeFunction={() => setBlobViewTab(BlobViewTab.Containers)}
        />
      )
      break
    default:
      currentComponent = <></>
  }

  return (
    (activeSession && (
      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        isOpen={true}
        onClose={closeFunction}
        classNames={{
          base: '!max-w-[100vw] w-fit',
        }}
      >
        <ModalContent>
          <ModalBody>{currentComponent}</ModalBody>
        </ModalContent>
      </Modal>
    )) || <></>
  )
}

export default BlobSettings
