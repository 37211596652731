const synth = window.speechSynthesis

export class TextToSpeech {
  private voices: SpeechSynthesisVoice[] = []
  private defaultVoice: SpeechSynthesisVoice
  private browsersLanguage = navigator.language || (navigator as any).userLanguage

  constructor() {
    this.voices = synth.getVoices()
    this.defaultVoice = this.getFallBackVoice()
  }

  getFallBackVoice() {
    return (
      this.voices.find(
        (voice) =>
          voice.voiceURI ===
          'Microsoft Sonia Online (Natural) - English (United Kingdom)',
      ) ?? this.voices.find((voice) => voice.voiceURI.includes('(Natural)'))!
    )
  }
  getVoices() {
    return this.voices
  }

  getVoiceCollection(): SpeechSynthesisVoice[] {
    const germanVoices = this.getVoicesByUsersLanguage('de-DE', 'Katja')
    const britishVoices = this.getVoicesByUsersLanguage('en-GB', 'Sonia')
    const voices = []
    if (britishVoices[0]) voices.push(britishVoices[0])
    if (germanVoices[0]) voices.push(germanVoices[0])
    return voices
  }

  getVoicesByUsersLanguage(countryCode?: string, include?: string) {
    return this.voices.filter(
      (voice) =>
        voice.lang === (countryCode ?? this.browsersLanguage) &&
        voice.voiceURI.includes(include ?? '(Natural)'),
    )
  }

  setDefaultVoice(voice: SpeechSynthesisVoice) {
    console.log(voice)
    this.defaultVoice = voice
  }

  talk(text: string, callback: () => void) {
    const synth = new SpeechSynthesisUtterance()
    if (!this.defaultVoice) {
      this.setDefaultVoice(this.getFallBackVoice())
    }
    console.log('default:, ', this.defaultVoice)
    synth.voice = this.defaultVoice
    synth.text = text
    speechSynthesis.speak(synth)
    synth.onend = callback
  }
  cancel() {
    speechSynthesis.cancel()
  }
}
