import { iTeam } from './iTeam.tsx'

export enum UserManagementState {
  List,
  Edit,
  Usage,
  Create,
}

export interface iButtonHandles {
  closeFunction: () => void
  submitFunction: () => void
  setLoading: () => void
  mode?: UserManagementState
  team?: iTeam
}
