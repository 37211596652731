import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { iAssistant, iAssistantPopupView } from './iAssistantTypes.tsx'
import { Role } from '../../../interfaces/iShared.ts'
import SearchInput from '../../utils/searchInput.tsx'
import AssistantCard from './assistantCard.tsx'
import { useTranslation } from 'react-i18next'
import AssistantCardAddNew from './assistantCardAddNew.tsx'
import useSessionStore from '../../../stateManagement/sessionState.ts'
import useAssistantStore from '../../../stateManagement/assistantState.ts'
import useAssistantModelStore from '../../../stateManagement/assistantModelState.ts'
import { Button, Switch } from '@nextui-org/react'
import useTeamStore from '../../../stateManagement/teamState.ts'
import { IAssistantModel } from '../../../interfaces/iAssistantModel.ts'
import { shallow } from 'zustand/shallow'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

function AssistantView({ closeFunction }: iAssistantPopupView) {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [displayAvailable, setDisplayAvailable] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)

  const {
    assistants: allAssistants,
    getAssistantsByContext,
    loadAssistants,
  } = useAssistantStore(
    (state) => ({
      getAssistantsByContext: state.getAssistantsByContext,
      assistants: state.assistants,
      loadAssistants: state.loadAssistants,
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )
  const { activeSession, updateSession, setInstructions } = useSessionStore(
    (state) => ({
      updateSession: state.updateSession,
      setInstructions: state.setInstructions,
      activeSession: state.activeSession,
      getPlugins: state.getPlugins,
      getKnowledgeContainers: state.getKnowledgeContainers,
    }),
    shallow,
  )

  const { assistantModels, getAssistantModelsByContext } = useAssistantModelStore(
    (state) => ({
      assistantModels: state.assistantModels,
      getAssistantModelsByContext: state.getAssistantModelsByContext,
    }),
    shallow,
  )

  const {
    hasRightTo: hasTeamRightTo,
    selectedTeam,
    role,
    isOwner,
  } = useTeamStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      selectedTeam: state.selectedTeam,
      role: state.role,
      isOwner: state.isOwner,
    }),
    shallow,
  )

  const refresh = () => {
    if (loading) return
    setLoading(true)
    loadAssistants()
      .then(() => {
        return autoSelectSingleAssistant()
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    refresh()
  }, [])

  const modelAllowed = (assistant: iAssistant): boolean => {
    return !!getAssistantModelsByContext('team', selectedTeam!.id).find(
      (model: IAssistantModel) => model.id === assistant.settings?.modelId,
    )
  }

  const assistantAllowed = (assistant: iAssistant) => {
    return getAssistantsByContext('team', selectedTeam?.id).find(
      (a: iAssistant) => a.id === assistant.id,
    )
  }

  const allowedModels = useMemo(() => {
    return getAssistantModelsByContext('team', selectedTeam?.id)
  }, [assistantModels, selectedTeam])

  const assistants: iAssistant[] = useMemo(() => {
    if (!displayAvailable) return allAssistants
    let preSettings: iAssistant[]
    preSettings = getAssistantsByContext('team', selectedTeam?.id)
    preSettings = preSettings.filter((s) => modelAllowed(s))
    return preSettings.map((preSetting) => {
      return {
        ...preSetting,
        selected: false,
      }
    })
  }, [allAssistants, selectedTeam, displayAvailable])

  const { t } = useTranslation()
  /**
   * Filter the assistants based on the search term
   * Add an empty assistant to the list as first item
   */
  const filterAssistants = useMemo(() => {
    const emptyAssistant: iAssistant = {
      id: '',
      title: 'Start Empty',
      description: 'Start an empty session with default settings',
      ownerEmail: '',
      knowledgeContainer: [],
      knowledgeContainerIds: [],
      files: [],
      pluginIds: [],
      plugins: [],
      instruction: '',
      settings: {
        maxTokens: 4096,
        temperature: 0.6,
        topP: 0.95,
        modelId: allowedModels[0]?.id,
        transferLength: 21,
      },
      shared: [],
      selected: false,
    }

    if (searchTerm === '' || searchTerm.length < 3) {
      return [emptyAssistant, ...assistants]
    } else {
      // split the search term into words and each word must be found in the title or description, unless the word is enclosed in quotes
      const searchWords = searchTerm.split(' ').filter((word) => word !== '')
      const assistantsReturn = assistants.filter((blobcontainer) => {
        return searchWords.every((word) => {
          return (
            blobcontainer.title.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.description?.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.instruction?.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.ownerEmail?.toLowerCase().includes(word.toLowerCase()) ||
            blobcontainer.id?.toLowerCase().includes(word.toLowerCase())
          )
        })
      })
      return assistantsReturn
    }
  }, [assistants, searchTerm])

  const disabledReason = useCallback(
    (assistant: iAssistant) => {
      // check if assistant model is allowed in current team session
      if (!modelAllowed(assistant)) {
        return 'restricted-model'
      }

      if (!assistantAllowed(assistant) && assistant.id) {
        return 'restricted-assistant'
      }
      return undefined
    },
    [filterAssistants],
  )

  const autoSelectSingleAssistant = () => {
    if (
      role === Role.LimitedMember &&
      !isOwner &&
      assistants.length === 1 &&
      !activeSession!.sessionSettings?.baseSessionSettingsId
    ) {
      handleApply(assistants[0])
    }
  }

  const handleApply = async (
    selectedPreSettingParam: iAssistant | undefined = undefined,
  ) => {
    const selectedPreSetting =
      selectedPreSettingParam || assistants.filter((blob) => blob.selected)[0]

    activeSession!.sessionSettings!.knowledgeContainer =
      selectedPreSetting.knowledgeContainer
    activeSession!.sessionSettings!.description = selectedPreSetting.description
    activeSession!.sessionSettings!.files = selectedPreSetting.files
    activeSession!.sessionSettings!.pluginIds = selectedPreSetting.pluginIds
    activeSession!.sessionSettings!.plugins = selectedPreSetting.plugins
    activeSession!.sessionSettings!.instruction = selectedPreSetting.instruction
    activeSession!.sessionSettings!.settings!.maxTokens =
      selectedPreSetting.settings?.maxTokens
    activeSession!.sessionSettings!.settings!.temperature =
      selectedPreSetting.settings?.temperature
    activeSession!.sessionSettings!.settings!.topP =
      selectedPreSetting.settings?.topP
    activeSession!.sessionSettings!.settings!.modelId =
      selectedPreSetting.settings?.modelId
    activeSession!.sessionSettings!.settings!.transferLength =
      selectedPreSetting.settings?.transferLength
    activeSession!.sessionSettings!.blueprint = false
    activeSession!.sessionSettings!.title = selectedPreSetting.title
    activeSession!.sessionSettings!.baseSessionSettingsId =
      selectedPreSetting.id || null
    activeSession!.sessionSettings!.baseSessionSettingsCopyDate = new Date()
    activeSession!.sessionSettings!.knowledgeContainerIds =
      selectedPreSetting.knowledgeContainerIds
    activeSession!.sessionSettings!.pluginIds = selectedPreSetting.pluginIds
    activeSession!.sessionSettings!.archiveChatHistory =
      selectedPreSetting.archiveChatHistory
    // session!.sessionSettings!.blobs = selectedPreSetting.blobs; // TODO ???
    await updateSession(activeSession!)
    setInstructions(activeSession!, selectedPreSetting.instruction!)
    closeFunction()
  }

  return (
    <div className="space-y-4 mx-auto overflow-y-auto p-4 rounded-xl overflow-hidden max-w-[1500px] sticky top-0 w-full">
      <div className="justify-center flex w-full">
        <div>
          <div className={'justify-center'}>
            <h1 className={'text-xl font-semibold mb-2'}>Assistants</h1>
            <p className={'w-4/5'}>{t('assistant.description.chat')}</p>
          </div>
          {(loading && (
            <div className={'grid grid-cols-1 h-20 w-full'}>
              <span className="loading loading-spinner loading-lg place-self-center"></span>
            </div>
          )) || (
            <>
              <SearchInput
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                placeholder="Search for Assistant, Owner, Description or Instruction"
              >
                <div className="flex flex-row justify-center">
                  <Button
                    isIconOnly
                    variant={'light'}
                    onClick={refresh}
                    className=" mr-2"
                  >
                    <ArrowPathIcon className={'h-6 w-6'}></ArrowPathIcon>
                  </Button>
                  <Switch
                    isSelected={displayAvailable}
                    onValueChange={setDisplayAvailable}
                    defaultSelected
                    size="sm"
                  >
                    Available
                  </Switch>
                </div>
              </SearchInput>
              <div className="flex flex-wrap justify-center">
                <>
                  {hasTeamRightTo(Role.Member) && <AssistantCardAddNew />}
                  {filterAssistants.map((assistant, key) => {
                    return (
                      <AssistantCard
                        preSetting={assistant}
                        handleApply={handleApply}
                        disabledReason={disabledReason(assistant)}
                        key={key}
                      />
                    )
                  })}
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AssistantView
