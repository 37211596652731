export function debounce<F extends (...args: any[]) => any>(
  func: F,
  wait: number,
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  let timeout: ReturnType<typeof setTimeout> | null = null

  return function (this: ThisParameterType<F>, ...args: Parameters<F>): void {
    const later = () => {
      timeout = null
      func.apply(this, args)
    }
    if (timeout !== null) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(later, wait)
  }
}
