import {
  CloudArrowUpIcon,
  SwatchIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline'
import { Popup } from './contextPopup.tsx'
import React, { useEffect, useMemo, useState } from 'react'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import promiseQueue from '../utils/promiseQueue.tsx'
import { Badge, Button, Spinner, Tooltip } from '@nextui-org/react'
import useTeamStore from '../../stateManagement/teamState.ts'
import { isDefaultTeam } from './userManagement/iTeam.tsx'
import { Role } from '../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'

const NavBarButtons = () => {
  const { setPopup } = useMain()
  // info: dont remove "selectedTeam" because its rerenders navBar after value has been changed
  const { teams, hasRightTo } = useTeamStore(
    (state) => ({
      teams: state.teams,
      hasRightTo: state.hasRightTo,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  const [fulfilledCount, setFulfilledCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  /**
   * Update the fulfilled and total count of the promise queue for blobs
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setFulfilledCount(promiseQueue.getInstance().getFulfilledCount())
      setTotalCount(promiseQueue.getInstance().getTotalCount())
    }, 1000)

    // destructor
    return () => clearInterval(interval)
  }, [])

  const userHasATeam = useMemo(
    () => teams.some((team) => !isDefaultTeam(team)),
    [teams],
  )

  return (
    <div className="flex items-center space-x-2 sm:space-x-1">
      {hasRightTo(Role.Member) && userHasATeam && (
        <>
          <Badge
            isOneChar
            isInvisible={!totalCount}
            content={
              <Spinner
                color={'primary'}
                size={'sm'}
                className={'rounded-full bg-white'}
              />
            }
            placement="top-right"
          >
            <Tooltip
              content={`${totalCount > 0 ? `${fulfilledCount} / ${totalCount} files processed` : 'Manage your knowledge container'}`}
              color={'primary'}
              className={'max-w-96'}
            >
              <Button
                onClick={() => setPopup(Popup.Container)}
                isIconOnly
                size={'lg'}
                variant={'light'}
              >
                <CloudArrowUpIcon
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                />
              </Button>
            </Tooltip>
          </Badge>

          <Tooltip
            content="Create new plugins for GAIA"
            color={'primary'}
            className={'max-w-96'}
          >
            <Button
              onClick={() => setPopup(Popup.Plugins)}
              isIconOnly
              size={'lg'}
              variant={'light'}
            >
              <PuzzlePieceIcon
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              />
            </Button>
          </Tooltip>
        </>
      )}
      {hasRightTo(Role.GaiaUser) && (
        <Tooltip
          content="Manage your predefined settings"
          color={'primary'}
          className={'max-w-96'}
        >
          <Button
            onClick={() => setPopup(Popup.AssistantContainer)}
            isIconOnly
            variant={'light'}
            size={'lg'}
          >
            <SwatchIcon
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            />
          </Button>
        </Tooltip>
      )}
    </div>
  )
}

export default NavBarButtons
