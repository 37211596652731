export function getLastChangelogDate(changelog: string): string | null {
  const dateRegex = /## (\d{2}\.\d{2}\.\d{4})/g

  // Find all dates in the changelog
  const dates = [...changelog.matchAll(dateRegex)]

  // Get the last date, if any
  const lastDate = dates.shift()

  // Return the last date found or null if none
  return lastDate ? lastDate[1] : null
}
