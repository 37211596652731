import React, { useEffect, useState, useCallback } from 'react'
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Button, Tooltip } from '@nextui-org/react'
import { iKnowledgeContainer } from '../sessionControls/knowledgeContainer/iKnowledgeContainer.ts'
import knowledgeContainerService from '../../services/knowledgeContainerService.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { shallow } from 'zustand/shallow'

interface iItemSelectionProps {
  selectedItemId: string[]
  setSelectedItemId: (id: string[]) => void
}

const KnowledgeContainerSelection: React.FC<iItemSelectionProps> = ({
  selectedItemId,
  setSelectedItemId,
}) => {
  const [search, setSearch] = useState('')
  const [selectedItems, setSelectedItems] = useState<iKnowledgeContainer[]>([])
  const [items, setItems] = useState<iKnowledgeContainer[]>([])
  const [allItems, setAllItems] = useState<iKnowledgeContainer[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { activeSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
    }),
    shallow,
  )

  useEffect(() => {
    knowledgeContainerService
      .getKnowledgeContainerContainerList(
        activeSession?.sessionSettings?.baseSessionSettingsId,
      )
      .then((response) => {
        setAllItems(response)
        if (selectedItemId) {
          const items = selectedItemId.map((sItem) => {
            const container = response.find((item) => sItem === item.id)
            if (container?.fromBaseAssistantInherited) {
              container.description =
                'Since this container is inherited from the base assistant, it cannot be used for a new assistant. Please contact the owner of this resource to get access.'
            }
            return container ?? { name: 'Container was deleted', description: sItem }
          })
          setSelectedItems(items as any)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [selectedItemId])

  const setItem = useCallback(
    (item: iKnowledgeContainer) => {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item])
      setSelectedItemId([...selectedItemId, item.id!])
    },
    [selectedItemId, setSelectedItemId],
  )

  const deleteItemFromList = useCallback(
    (item: iKnowledgeContainer) => {
      setSelectedItems(selectedItems.filter((i) => i.id !== item.id))
      setSelectedItemId(selectedItemId.filter((i) => i !== item.id))
    },
    [setSelectedItemId],
  )

  const searchItems = useCallback(
    (query: string) => {
      const filterItems = (i: iKnowledgeContainer[]) => {
        return i.filter(
          (user) =>
            user.name?.toLowerCase().includes(query.toLowerCase()) ||
            user.description?.toLowerCase().includes(query.toLowerCase()),
        )
      }
      setItems(filterItems(allItems))
    },
    [allItems],
  )

  return (
    <div>
      {loading ? (
        <div className="grid grid-cols-1 h-20">
          <span className="loading loading-spinner loading-lg place-self-center"></span>
        </div>
      ) : (
        <div className="flex flex-col border-2 border-gray-300 rounded-md p-4 w-full min-w-fit">
          <div className="max-h-40 mb-2">
            {selectedItems.map((item) => (
              <div key={item.id} className="flex items-center mb-3">
                <div className="join flex w-full justify-between">
                  <div className="w-full flex items-center">
                    <div
                      className={`w-fit pr-0 ${item.fromBaseAssistantInherited && 'text-red-500 line-through'}`}
                    >
                      {item.name}
                    </div>
                    <Tooltip
                      content={item.description}
                      color="primary"
                      className="max-w-96"
                    >
                      <InformationCircleIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.0}
                        stroke="currentColor"
                        className="w-4 h-4 ml-1"
                      />
                    </Tooltip>
                  </div>
                  <div className="shrink">
                    <Button
                      variant="light"
                      isIconOnly
                      className={`hover:text-error ${item.fromBaseAssistantInherited && 'hidden'}`}
                      onClick={() => deleteItemFromList(item)}
                    >
                      <TrashIcon className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <input
            type="text"
            placeholder="Add Knowledgecontainer"
            className="input input-bordered input-sm w-full mb-4"
            value={search}
            onClick={() => searchItems(search)}
            onChange={(e) => {
              setSearch(e.target.value)
              searchItems(e.target.value)
            }}
          />
          {items.length > 0 && (
            <div className="max-h-40 overflow-y-auto rounded-md p-4 bg-gray-100 min-w-fit">
              {items
                .filter((item) => !selectedItems.some((i) => i.id === item.id))
                .map((item) => (
                  <div
                    key={item.id}
                    className="flex justify-between items-center bg-gray-100 rounded-md"
                  >
                    <button
                      className="btn glass btn-sm"
                      onMouseDown={() => setItem(item)}
                    >
                      {item.name}
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default KnowledgeContainerSelection
