import * as React from 'react'
import './profileImage.css'
import ProfileImageLoader from '../../../hooks/useProfileImage.ts'
import { useEffect, useState } from 'react'
import { iContact } from './iContact'
import { Avatar, Spinner } from '@nextui-org/react'
import { UserIcon } from '@heroicons/react/24/outline'

function ProfileImage({ contact, width = 6 }: iContact) {
  const [image, setImage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const loadImage = async () => {
      setLoading(true)
      try {
        if (contact === 'gaia') {
          getGaiaAvatar()
          return
        }
        const loadedImage = await ProfileImageLoader.loadProfileImage(contact)
        setImage(loadedImage)
      } catch (e) {
        /* empty */
      } finally {
        setLoading(false)
      }
    }
    loadImage()
  }, [contact])

  const getGaiaAvatar = () => {
    // load the 96.png from the public folder
    // convert it to base64 to use it as src in Avatar
    const img = new Image()
    img.src = '/96.png'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0)
      const dataUrl = canvas.toDataURL('image/png')
      setImage(dataUrl)
    }
  }

  return loading ? (
    <Spinner className={`w-${width}`} />
  ) : (
    <Avatar
      size={'md'}
      src={image!}
      showFallback
      fallback={<UserIcon className={`w-${width} h-${width} text-default-500`} />}
      className={`${contact === 'gaia' && 'bg-transparent'}`}
    />
  )
}

export default ProfileImage
