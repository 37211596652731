import React, { useState } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'

interface iStarRatingProps {
  currentValue: number
  onRated: (value: number) => void
}

function StarRating({ currentValue, onRated }: iStarRatingProps) {
  const [hoveredValue, setHoveredValue] = useState(0)

  return (
    <>
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1

        return (
          <button
            key={'overall' + ratingValue}
            className={`star ${
              ratingValue <= (hoveredValue || currentValue)
                ? 'text-yellow-500'
                : 'text-gray-500'
            }`}
            onMouseEnter={() => setHoveredValue(ratingValue)}
            onMouseLeave={() => setHoveredValue(0)}
            onClick={() => onRated(ratingValue)}
          >
            <StarIcon
              color={'primary'}
              className="w-[1.5em] inline-block ml-2 cursor-pointer"
            />
          </button>
        )
      })}
    </>
  )
}

export default StarRating
