import * as Sentry from '@sentry/react'
import { feedbackIntegration } from '@sentry/react'

Sentry.init({
  dsn: 'https://2e81f41cba440cfcee9e1d7d9b0c147f@o1382842.ingest.sentry.io/4506581111799808',
  integrations: [
    Sentry.browserTracingIntegration(),
    feedbackIntegration({
      isEmailRequired: false,
      showEmail: false,
      autoInject: false,

      colorScheme: 'light',
    }),
  ],
  environment: process.env.SENTRY_ENVIRONMENT,
  // beforeSend(event, hint) {
  //     // Check if it is an exception, and if so, show the report dialog
  //     if (event.exception) {
  //         Sentry.showReportDialog({ eventId: event.event_id });
  //     }
  //     return event;
  // },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
})

export { Sentry }
