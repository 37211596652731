import React from 'react'
import Description from '../../utils/description'
import { useMain } from '../../../stateManagement/contexts/mainContext.tsx'
import { Popup } from '../contextPopup.tsx'
import { Button } from '@nextui-org/react'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

function AssistantCardAddNew() {
  const { setPopup } = useMain()
  return (
    <div key={'AddNewAssistantSetting'} className="p-1 tracking-wide mt-2">
      <div className="card m-1 w-64 min-h-96 glass hover:shadow-lg hover:scale-105 transition-transform duration-500 ease-in-out max-w-64">
        <div className="card-body">
          <h2 className="card-title mt-11">New Setting</h2>

          <Description description={'Create your own assistant'} />

          <Button
            isIconOnly
            variant={'light'}
            id="createNewAssistant"
            className="self-center mt-3"
            data-guide-id="createNewAssistant"
            onClick={() => {
              setPopup(Popup.AssistantNew)
            }}
          >
            <PlusCircleIcon className="w-8 h-8" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AssistantCardAddNew
