import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './sessionGroups.css'
import { iSession } from '../chatWindow/iSession.ts'
import { Accordion, AccordionItem } from '@nextui-org/react'
import SessionGroupsSearch from './sessionGroupsSearch.tsx'
import SessionGroupTitel from './sessionsGroupTitle.tsx'
import { iSessionGroup } from './interfaces/iSessionGroup.ts'
import { Selection } from '@react-types/shared/src/selection'
import useTeamStore from '../../stateManagement/teamState.ts'
import useGroupStore from '../../stateManagement/groupState.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import SessionGroup from './sessionsGroup.tsx'
import CreateSessionButton from './createSessionButton.tsx'
import { twMerge } from 'tailwind-merge'
import { shallow } from 'zustand/shallow'

type SessionGroupsProps = React.HTMLAttributes<HTMLDivElement> & {}
function SessionGroups({ className }: SessionGroupsProps) {
  const classes = twMerge('flex-1', className)

  const {
    sessions,
    activeSession,
    isLoading: isSessionsLoding,
  } = useSessionStore(
    (state) => ({
      sessions: state.sessions,
      isLoading: state.isLoading,
      activeSession: state.activeSession,
    }),
    shallow,
  )
  const { groups, isLoading: isGroupsLoading } = useGroupStore(
    (state) => ({
      groups: state.groups,
      isLoading: state.isLoading,
    }),
    shallow,
  )
  const { teams, selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
      teams: state.teams,
    }),
    shallow,
  )

  const [searchValue, setSearchValue] = useState('')
  const [selectedGroups, setSelectedGroups] = useState<Selection>()

  useEffect(() => {
    if (activeSession === null) return
    const groupdId = sessions.find(
      (session) => session.id === activeSession.id,
    )?.groupId
    if (groupdId) setSelectedGroups(new Set([groupdId]))
  }, [activeSession])

  const filteredSessions = useCallback(
    (group: iSessionGroup) => {
      return Array.from(sessions)
        .sort((a, b) => (a.modified! > b.modified! ? -1 : 1))
        .filter((session) => session.groupId === group.id)
        .filter((session) => session.title.toLowerCase().indexOf(searchValue) >= 0)
    },
    [selectedTeam, searchValue, sessions],
  )
  const filteredGroups = useMemo(() => {
    return (
      Array.from(groups!)
        //.filter((group) => group.billingGroupId === selectedTeam?.id)
        .filter((group) => !searchValue.length || filteredSessions(group).length)
    )
  }, [filteredSessions, groups])
  return (
    <>
      <div className={classes}>
        {!!selectedTeam && (
          <SessionGroupsSearch
            isLoading={isSessionsLoding}
            onValueChange={(value: string) => setSearchValue(value)}
          />
        )}
        <div className="overflow-auto mt-8">
          {!isGroupsLoading && (
            <Accordion
              selectionMode="multiple"
              variant="splitted"
              selectedKeys={selectedGroups}
              onSelectionChange={(keys) => setSelectedGroups(keys)}
            >
              {filteredGroups.map((group) => (
                <AccordionItem
                  textValue={group.id}
                  key={group.id}
                  className={'px-0 overflow-hidden !shadow-none !bg-[#f6f6f6]'}
                  classNames={{
                    heading: 'px-4',
                  }}
                  title={<SessionGroupTitel group={group} />}
                >
                  <div className={'px-4'}>
                    <CreateSessionButton
                      group={group}
                      selectedTeam={selectedTeam!}
                    />
                  </div>
                  <div className={'mt-6'}></div>
                  <SessionGroup
                    group={group}
                    searchValue={searchValue}
                    selectedTeam={selectedTeam}
                    sessions={sessions}
                  ></SessionGroup>
                </AccordionItem>
              ))}
            </Accordion>
          )}
          {/*          <SessionGroup
              searchValue={searchValue}
              selectedTeam={selectedTeam}
              sessions={sessions}
          ></SessionGroup>
          <div className={'flex justify-center my-8'}>
            <CreateSessionButton selectedTeam={selectedTeam!} />
          </div>*/}
        </div>
      </div>
    </>
  )
}

export default SessionGroups
