import { CancelTokenSource } from 'axios'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { iMessage, ContentItem } from '../../components/chatWindow/iMessage'
import { iSession } from '../../components/chatWindow/iSession'
import { AudioPlayer, TextToSpeechService } from '../../utils/audioPlayer'
import { useMain } from '../contexts/mainContext'
import { MessageContext } from '../contexts/messageContext'
import SessionService from '../../services/sessionService'
import useSignalRStore from '../signalRState.ts'
import useSessionStore from '../sessionState.ts'
import useUserProfileStore from '../userProfileState.ts'
import { shallow } from 'zustand/shallow'

type MessageProviderProps = React.HTMLAttributes<HTMLDivElement>

function MessageProvider({ children }: MessageProviderProps) {
  const [cancelSource, setCancelSource] = useState<CancelTokenSource | null>(null)
  const [isGenerating, setIsGenerating] = useState(false)
  const startTime = useRef<Date | null>(null)

  const { sessions, updateSession, updateSessionInternal, activeSession } =
    useSessionStore(
      (state) => ({
        sessions: state.sessions,
        updateSession: state.updateSession,
        updateSessionInternal: state.updateSessionInternal,
        activeSession: state.activeSession,
      }),
      shallow,
    )
  const { setError } = useMain()
  const userProfile = useUserProfileStore((state) => state.userProfile, shallow)
  const startAssistant = useSignalRStore((state) => state.startAssistant, shallow)

  const measureAndSendGenerationTime = async (session: iSession) => {
    if (!session.id || !session.messages || session.messages.length === 0) {
      return
    }

    const generatedMessageId = session.messages[session.messages.length - 1].id
    if (!generatedMessageId) {
      return
    }
    if (!startTime.current) {
      return
    }

    let generationTime = new Date().getTime() - startTime.current.getTime()
    generationTime = Number((generationTime / 1000).toFixed(3))
    SessionService.getMessage(session.id, generatedMessageId).then((msg) => {
      if (msg.generationTime) {
        msg.generationTime.end2end = generationTime
        updateMessage(session, msg)
      }
    })
  }

  const handleSendMessage = async (
    content: string | undefined,
    contentItem: ContentItem[] | undefined,
    session: iSession,
    file: File | Blob | null,
  ) => {
    startTime.current = new Date()

    let player: AudioPlayer | undefined = undefined

    if (session.sessionSettings!.settings!.synthesizeAudio) {
      const service = new TextToSpeechService()
      player = new AudioPlayer(service)
    }
    try {
      const uM: iMessage = {
        role: 'user',
        content: content,
        contentItems: contentItem,
        contentType: 'userMessage',
        sessionId: session.id!,
        ownerId: userProfile!.id!,
        isAudio: !!file,
      }

      await createMessage(session, uM)
      startAssistant(session.id!, userProfile!.id!)
    } catch (error) {
      console.error('Error: ', error)
      setError(error as Error)
    } finally {
      session.isGenerating = false
      setIsGenerating(false)
      updateSessionInternal(session)
    }
  }

  useEffect(() => {
    if (activeSession) {
      activeSession.cancelSource = cancelSource
      updateSessionInternal(activeSession)
    }
  }, [cancelSource])

  useEffect(() => {
    if (activeSession) {
      activeSession.isGenerating = isGenerating
      setIsGenerating(isGenerating)
      updateSessionInternal(activeSession)
    }
  }, [isGenerating])

  async function createMessage(session: iSession, message: iMessage) {
    try {
      session.messages = session.messages || []
      const tmpId = 'tmp_' + Math.random().toString(36)
      message.tmpId = tmpId
      message.isLoading = true
      session.messages.push(message)
      updateSessionInternal(session)

      const msg = await SessionService.createMessage(session.id!, message)
      const messageIndex = session.messages.findIndex(
        (message) => message.tmpId! === tmpId,
      )
      session.messages[messageIndex] = msg
      const prom1 = updateSessionInternal(session)
      const prom2 = updateSession(session)
      await Promise.all([prom1, prom2])
      console.log('Created Message: ', msg)
      return msg
    } catch (error) {
      setError(error)
    }
    return undefined
  }

  const updateMessage = async (session: iSession, message: iMessage) => {
    try {
      session.messages = session.messages || []
      session.messages = session.messages.map((m) =>
        m.id === message.id ? message : m,
      )
      const prom1 = SessionService.updateMessage(session.id!, message)
      const prom2 = updateSession(session)
      await Promise.all([prom1, prom2])
    } catch (error) {
      setError(error)
    } finally {
      console.log('Updated Message: ', message)
    }
  }

  const deleteMessage = async (session: iSession, message: iMessage) => {
    try {
      session.messages = session.messages || []
      session.messages = session.messages.filter((m) => m.id !== message.id)
      const prom1 = SessionService.deleteMessage(session.id!, message.id!)
      const prom2 = updateSession(session)
      await Promise.all([prom1, prom2])
    } catch (error) {
      setError(error)
    } finally {
      console.log('Deleted Message: ', message)
    }
  }

  const getMessages = async (session: iSession) => {
    try {
      session.messages = await SessionService.getMessages(session.id!)
      updateSessionInternal(session)
    } catch (error) {
      setError(error)
    } finally {
      console.log(
        'Loaded Messages: ',
        sessions.find((s) => s.id === session.id)?.messages,
      )
    }
  }

  const setMessages = async (session: iSession, messages: iMessage[]) => {
    session.messages = messages
    await updateSession(session)
  }

  const value = useMemo(
    () => ({
      createMessage,
      deleteMessage,
      getMessages,
      handleSendMessage,
      setMessages,
      updateMessage,
      measureAndSendGenerationTime,
    }),
    [
      createMessage,
      deleteMessage,
      getMessages,
      handleSendMessage,
      setMessages,
      updateMessage,
      measureAndSendGenerationTime,
    ],
  )

  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
}

export default MessageProvider
