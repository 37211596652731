import { iItem } from '../../../interfaces/iItem'

export interface iPluginSettings extends iItem {
  pluginId: string
  sessionSettingsId: string
  settings: { [key: string]: any } | null | undefined
}

export enum PluginViewTab {
  Selection,
  Action,
  Edit,
}
