import { SharableEntityType } from '../../interfaces/iShared.ts'

export const invitationPathName = '/'
export const invitationSearchParam = 'invitation'
export const invitationItemIdSearchParam = 'sharing_item_id'
export const invitationLinkIdSearchParam = 'sharing_link_id'
export const invitationItemTypeSearchParam = 'sharing_item_type'
export const invitationUrl = `${window.location.origin}${invitationPathName}`

export const generateInvitationUrl = (
  resourceId: string,
  linkId: string,
  type: SharableEntityType,
) => {
  return `${invitationUrl}?${invitationSearchParam}=true&${invitationItemIdSearchParam}=${resourceId}&${invitationLinkIdSearchParam}=${linkId}&${invitationItemTypeSearchParam}=${type}`
}
