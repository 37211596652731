import axios, { AxiosInstance } from 'axios'
import {
  getRefreshToken,
  getToken,
  setRefreshToken,
  setToken,
} from '../components/login/authentication'
import { Sentry } from './sentry.ts'

class AxiosClient {
  static instanceClient: AxiosClient
  public instance: AxiosInstance
  private baseURL: string
  // timeout 20 minutes
  private timeout = 1200000

  constructor() {
    // this.baseURL = import.meta.env.VITE_API_BASE_URL || '';
    this.baseURL = import.meta.env.VITE_BACKEND_URL ?? 'http://localhost:7071/api'
    this.instance = axios.create({
      baseURL: this.baseURL,
      timeout: this.timeout,
      headers: {
        'Access-Control-Allow-Credentials': true,
      },
    })

    this.instance.interceptors.response.use(
      (config) => {
        const idToken = config.headers['id_token']
        const refreshToken = config.headers['refresh_token']

        if (idToken && refreshToken) {
          setToken(idToken)
          setRefreshToken(refreshToken)
        }

        return config
      },
      (error) => {
        Sentry.captureMessage('Error in request interceptor: ' + error)
        return Promise.reject(error)
      },
    )

    this.instance.interceptors.request.use(
      (config) => {
        const idToken = getToken()
        const refreshToken = getRefreshToken()

        if (idToken && refreshToken) {
          config.headers['id_token'] = idToken
          config.headers['refresh_token'] = refreshToken
        }
        config.headers['x-frontend-url'] = window.location.origin

        return config
      },
      (error) => {
        Sentry.captureMessage('Error in request interceptor: ' + error)
        return Promise.reject(error)
      },
    )
  }
  public static get getInstance() {
    return (
      this.instanceClient?.instance || (this.instanceClient = new this()).instance
    )
  }
}

export default AxiosClient.getInstance
