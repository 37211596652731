import React, { useState } from 'react'

const useMouseDragScroll = (containerRef: React.RefObject<HTMLDivElement>) => {
  const [isMoving, setIsMoving] = useState<boolean>(false)
  const [startX, setStartX] = useState<number>(0)
  const [startY, setStartY] = useState<number>(0)
  const [scrollLeft, setScrollLeft] = useState<number>(0)
  const [scrollTop, setScrollTop] = useState<number>(0)

  const onMouseDown = (e: React.MouseEvent<Element, MouseEvent>) => {
    setIsMoving(true)
    setStartX(e.pageX)
    setStartY(e.pageY)
    setScrollLeft(containerRef.current!.scrollLeft)
    setScrollTop(containerRef.current!.scrollTop)
  }

  const onMouseMove = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!isMoving) return
    e.preventDefault()
    const walkLeft = e.pageX - startX
    const walkTop = e.pageY - startY
    containerRef.current!.scrollLeft = scrollLeft - walkLeft
    containerRef.current!.scrollTop = scrollTop - walkTop
  }
  const onMouseUp = () => {
    setIsMoving(false)
  }

  return {
    isMoving,
    events: {
      onMouseDown,
      onMouseMove,
      onMouseUp,
    },
  }
}

export default useMouseDragScroll
