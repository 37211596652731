import React, { useMemo } from 'react'
import ChatWindow from '../chatWindow/chatWindow'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import Drawer from '../basic/drawer/drawer.tsx'
import SystemSettings from '../settings/system/systemSettings.tsx'
import useTeamStore from '../../stateManagement/teamState.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { Role } from '../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'
import { isDefaultTeam } from '../sessionControls/userManagement/iTeam.tsx'

type SessionChatContentProps = {
  theme: string
}

function SessionChatContent({ theme }: SessionChatContentProps) {
  const { showSettings, setShowSettings } = useMain()

  const { activeSession } = useSessionStore(
    (state) => ({
      activeSession: state.activeSession,
    }),
    shallow,
  )
  const { hasRightTo, role, selectedTeam } = useTeamStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      role: state.role,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  const isOpen = useMemo(
    () =>
      (hasRightTo(Role.GaiaUser) || isDefaultTeam(selectedTeam!)) && showSettings,
    [showSettings, role],
  )
  return (
    <Drawer
      className={`${isOpen ? 'lg:drawer-open' : ''} h-full`}
      sideClassName={'lg:h-[calc(100vh-56px)] h-full'}
      end
      contentClassName={'flex h-full'}
      open={isOpen}
      onClickOverlay={() => setShowSettings(false)}
      side={
        <div
          key={'settings_' + activeSession?.sessionSettings?.baseSessionSettingsId}
          className="menu lg:bg-transparent flex-nowrap p-4 w-96 h-full bg-neutral-content text-base-content overflow-auto "
        >
          <SystemSettings activeSession={activeSession!} />
        </div>
      }
    >
      <ChatWindow
        theme={theme}
        session={activeSession!}
        key={'chatWindow_' + activeSession!.id}
      />
    </Drawer>
  )
}

export default SessionChatContent
