import React, { useMemo, useState } from 'react'
import { iSessionGroup, SessionModalMode } from './interfaces/iSessionGroup.ts'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
import useTeamStore from '../../stateManagement/teamState.ts'
import useGroupStore from '../../stateManagement/groupState.ts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { shallow } from 'zustand/shallow'

interface IFolderFormInput {
  title: string
}

interface iDialogSessionGroupModalProps {
  open: boolean
  group?: iSessionGroup | undefined
  onCreated?: (newGroup: iSessionGroup) => Promise<void>
  onClose?: () => void
}

function DialogSessionGroupModal({
  open,
  group,
  onCreated,
  onClose,
}: iDialogSessionGroupModalProps) {
  const { updateGroup, addGroup } = useGroupStore(
    (state) => ({
      updateGroup: state.updateGroup,
      addGroup: state.addGroup,
    }),
    shallow,
  )
  const selectedTeam = useTeamStore((state) => state.selectedTeam, shallow)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const groupModalMode = useMemo(
    () => (group ? SessionModalMode.Edit : SessionModalMode.Create),
    [group],
  )

  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFolderFormInput>({
    defaultValues: {
      title: group?.title ?? '',
    },
  })

  // validate the new group name and the settings
  const plausabilityCheckTitle = (title: string) => {
    const validName = /^[A-Za-z0-9 -]*[A-Za-z0-9][A-Za-z0-9 -]*$/i.test(title)
    console.log(validName)
    return validName
      ? true
      : 'Folder name must be larger than 3 letters and must not contain special characters.'
  }

  /**
   * Check if the new group name is valid and create the group
   */
  const saveFolder: SubmitHandler<IFolderFormInput> = (data) => {
    const folder: iSessionGroup =
      group ??
      ({
        title: data.title,
        billingGroupId: selectedTeam?.id,
      } as iSessionGroup)
    folder.title = data.title.trim()
    setIsLoading(true)
    const method = folder.id ? updateGroup : addGroup
    method(folder).then(() => {
      onCreated && onCreated(folder)
      setIsLoading(false)
      prepareClose()
    })
  }

  /**
   * reset the form and close the modal
   */
  const prepareClose = () => {
    reset({})
    onClose && onClose()
  }

  const saveButtonTitle = useMemo(
    () =>
      groupModalMode === SessionModalMode.Edit ? 'Edit Folder' : 'Create Folder',
    [],
  )

  return (
    <Modal
      scrollBehavior={'inside'}
      backdrop={'blur'}
      isOpen={open}
      onClose={onClose}
      classNames={{
        base: '!max-w-[100vw] w-fit w-[350px]',
      }}
    >
      <ModalContent>
        <ModalHeader>
          <h3 className="font-bold text-lg">{saveButtonTitle}</h3>
        </ModalHeader>

        <ModalBody>
          <>
            <Input
              type="text"
              label="Folder Name"
              {...register('title', {
                required: 'Title is required',
                minLength: {
                  value: 3,
                  message: 'Title must have an minimum of four characters',
                },
                maxLength: {
                  value: 64,
                  message: 'Title can only have a maximum of 64 characters',
                },
                validate: plausabilityCheckTitle,
              })}
              isInvalid={!!errors.title}
              errorMessage={errors.title?.message}
            />
          </>
        </ModalBody>
        <ModalFooter>
          <>
            <Button
              variant={'light'}
              className="mr-4"
              onClick={() => prepareClose()}
            >
              Close
            </Button>

            <Button
              disabled={isLoading}
              isLoading={isLoading}
              color={'primary'}
              onClick={handleSubmit(saveFolder)}
            >
              {saveButtonTitle}
            </Button>
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DialogSessionGroupModal
