import React, { useEffect, useState } from 'react'
import ProfileImage from './profileImage/profileImage'
import { iUser } from '../login/iUser'
import userService from '../../services/userService'
import {
  AccessRight,
  EntityType,
  iRole,
  iShared,
  Role,
} from '../../interfaces/iShared'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import ImportMembers from './importMembers.tsx'
import { Avatar, Button, Tooltip } from '@nextui-org/react'
import { isDraegerEmail, isEmail } from './regexHelper.tsx'
import { TrashIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import MemberRoleSelection from './memberRoleSelection.tsx'
import { iTeam } from '../sessionControls/userManagement/iTeam.tsx'
import useTeamStore from '../../stateManagement/teamState.ts'
import { shallow } from 'zustand/shallow'
import { uuidv4 } from '../../utils/uuidv4.ts'

const tooltipImportMembers =
  'Import members by any text-based file. The members must be separated by a comma or semicolon. The list may contain email addresses or names. The members will be added to the list of members. You can add members by email address or name. You can add Dräger members by email address as placeholder.'

interface iMembersSelectionProps {
  shared: iShared[]
  setShared: React.Dispatch<React.SetStateAction<iShared[]>>
  writeOnly?: boolean
  ownerId?: string
  roles?: iRole[]
}

/**
 * Component to select members for a session or a team.
 * @param shared List of already shared members
 * @param setShared Function to set the list of shared members in the parent component
 @param writeOnly If true, the access right is always write. Used for ShareType.Public
 * @param ownerId detects and filters owner out of memberslist
 */
function MembersSelection({
  shared,
  setShared,
  ownerId,
  writeOnly = false,
  roles,
}: iMembersSelectionProps) {
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState<iUser[]>([])
  const [teams, setTeams] = useState<iTeam[]>([])
  const [allUsers, setAllUsers] = useState<iUser[]>([])
  const [showUsers, setShowUsers] = useState(false)
  const [addingNewDraegerEmail, setAddingNewDraegerEmail] = useState(false)
  const [addingNewEmail, setAddingNewEmail] = useState(false)

  const [nonExistingUsers, setNonExistingUsers] = useState<string[]>([])

  const [showInfoPlaceHolderMembers, setShowInfoPlaceHolderMembers] = useState(false)

  const filterMembers = (users: iUser[]) => {
    return users.filter((user: iUser) => user.id !== ownerId)
  }
  const filterTeams = (teams: iTeam[]) => {
    return teams.filter((team: iTeam) => team.id !== ownerId)
  }
  const { editableTeams } = useTeamStore(
    (state) => ({
      editableTeams: state.editableTeams,
    }),
    shallow,
  )

  useEffect(() => {
    if (allUsers.length === 0) {
      userService.searchUsers().then((response) => {
        setAllUsers(filterMembers(response))
      })
    }
  }, [])

  /**
   * Filter the users based on the search string and the already shared users.
   */
  useEffect(() => {
    if (!showUsers) {
      setUsers([])
      setTeams([])
      return
    }

    // exclude the current user and the already shared users
    let newUsers = allUsers.filter((user) => {
      return shared.find((m) => m.id === user.id) === undefined
    })
    // exclude the current user and the already shared users
    let newTeams = editableTeams.filter((user) => {
      return shared.find((m) => m.id === user.id) === undefined
    })
    if (search.length > 0) {
      // check if the search string is part of an email
      if (isDraegerEmail(search)) {
        setAddingNewDraegerEmail(true)
        return
      } else if (!isDraegerEmail(search) && isEmail(search)) {
        setAddingNewDraegerEmail(false)
        setAddingNewEmail(true)
        return
      }

      newUsers = newUsers.filter((user) => {
        return (
          user.name?.toLowerCase().includes(search.toLowerCase()) ||
          user.email?.toLowerCase().includes(search.toLowerCase())
        )
      })
      newTeams = newTeams.filter((team) => {
        return (
          team.name?.toLowerCase().includes(search.toLowerCase()) ||
          team.description?.toLowerCase().includes(search.toLowerCase())
        )
      })
    }
    newUsers = filterMembers(newUsers)
    newTeams = filterTeams(newTeams)
    setUsers(newUsers)
    setTeams(newTeams)
  }, [shared, search, showUsers])

  const addMember = (user: iUser) => {
    if (shared.find((m) => m.id === user.id)) {
      return
    }
    const newMember: iShared = {
      name: user.name!,
      id: user.id!,
      email: user.email!,
      accessRight: writeOnly ? AccessRight.Write : AccessRight.Read,
      entityType: EntityType.User,
      role: writeOnly ? Role.Manager : Role.Member,
    }
    console.log(newMember)
    setShared((prevState) => [...prevState, newMember])
  }

  const addTeam = (team: iTeam) => {
    if (shared.find((m) => m.id === team.id)) {
      return
    }
    const newTeam: iShared = {
      name: team.name!,
      id: team.id!,
      accessRight: writeOnly ? AccessRight.Write : AccessRight.Read,
      entityType: EntityType.Team,
      role: writeOnly ? Role.Manager : Role.Member,
    }
    console.log(newTeam)
    setShared((prevState) => [...prevState, newTeam])
  }

  const handleSelectionChange = (value: number, s: iShared) => {
    s.role = value
    setShared([...shared])
  }

  const getRowStyle = (index: number) => ({
    transition: 'transform 0.5s ease, opacity 0.5s ease',
    transitionDelay: `${index * 0.1}s`, // staggered delay
    opacity: 1,
    transform: 'translateY(0)',
  })

  /**
   * Import members from a list of strings. The list may contain email addresses or names.
   * @param members List of members
   */
  const massImportMembers = (members: string[]) => {
    const nonExistingUsers: string[] = []
    members.forEach((member) => {
      // check if the member is already in the list
      if (
        shared.find((m) => {
          return m.email === member || m.name === member
        })
      ) {
        return
      }

      // check if the member is in the list of all users
      let user = allUsers.find((u) => {
        return (
          u.email?.toLowerCase() === member.toLowerCase() ||
          u.name?.toLowerCase() === member.toLowerCase()
        )
      })

      // if the user is not in the list of all users, and it is not a valid @draeger.com email address,
      // add it to the list of non-existing users
      // if it is a valid @draeger.com email address, create a new user object with empty id and name
      if (!user) {
        if (!member.endsWith('@draeger.com')) {
          nonExistingUsers.push(member)
          return
        } else {
          const newUser: iUser = {
            name: '',
            email: member,
            id: '',
          }
          user = newUser
          setShowInfoPlaceHolderMembers(true)
        }
      }

      addMember(user)
    })
    setNonExistingUsers(nonExistingUsers)
  }

  return (
    <div>
      {allUsers.length === 0 ? (
        <div className={'grid grid-cols-1 h-20'}>
          <span className="loading loading-spinner loading-lg place-self-center"></span>
        </div>
      ) : (
        <div className="flex flex-col mb-4 border-2 border-gray-300 rounded-md p-4 w-full min-w-fit">
          {shared.length > 0 && (
            <div className="max-h-40 overflow-y-auto mb-2">
              <table className={'w-full'}>
                <tbody>
                  {shared
                    .sort((a, b) => {
                      if (a.accessRight === b.accessRight) {
                        return 0
                      }
                      return a.accessRight > b.accessRight ? -1 : 1
                    })
                    .map((member, index) => (
                      <tr
                        style={getRowStyle(index)}
                        className={'content-center'}
                        key={index}
                      >
                        {member.entityType === EntityType.User && (
                          <td>
                            <div key={member.id} className="flex items-center m-1">
                              <ProfileImage contact={member.email || ''} />
                              <div className="flex-wrap flex w-full justify-between">
                                <div className={'pl-2 w-full text-sm'}>
                                  {member.name?.length === 0
                                    ? member.email + '*'
                                    : member.name}
                                </div>
                              </div>
                            </div>
                          </td>
                        )}
                        {member.entityType === EntityType.Team && (
                          <td>
                            <div key={member.id} className="flex items-center m-1">
                              <Avatar
                                alt={member.name}
                                className="flex-shrink-0"
                                size="sm"
                                icon={<UserGroupIcon className={'h-5 w-5'} />}
                              />
                              <div className="flex-wrap flex w-full justify-between">
                                <div className={'pl-2 w-full text-sm'}>
                                  {member.name} (Team)
                                </div>
                              </div>
                            </div>
                          </td>
                        )}
                        <td className={'flex justify-end '}>
                          <div className={'flex items-center min-w-52 h-full mt-1'}>
                            <MemberRoleSelection
                              value={member.role}
                              roles={roles}
                              writeOnly={writeOnly}
                              onChange={(value) =>
                                handleSelectionChange(value, member)
                              }
                            ></MemberRoleSelection>
                            <Button
                              isIconOnly
                              variant={'light'}
                              className="hover:text-error ml-4"
                              onClick={() =>
                                setShared(shared.filter((m) => m.id !== member.id))
                              }
                            >
                              <TrashIcon className={'w-4 h-4'}></TrashIcon>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          <span className={'flex mt-0'}>
            <input
              type="text"
              placeholder="Add Member or Team"
              className="input input-bordered input-sm flex-1 mr-2"
              value={search}
              onClick={() => setShowUsers(true)}
              onBlur={() => {
                setTimeout(() => {
                  setShowUsers(false)
                }, 200)
              }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />

            {/* Import button */}
            <div className={'flex'}>
              <ImportMembers
                handleFile={(members) => {
                  massImportMembers(members)
                }}
              />
              <label className={'label'}>
                <span className="label-text flex">
                  <Tooltip
                    content={tooltipImportMembers}
                    color={'primary'}
                    className={'max-w-96'}
                  >
                    <InformationCircleIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.0}
                      stroke="currentColor"
                      className="w-4 h-4 ml-1"
                    />
                  </Tooltip>
                </span>
              </label>
            </div>
          </span>

          {
            // if a draeger email is being added, show a message with button to add the user
            (addingNewDraegerEmail && (
              <div className="flex justify-between items-start mb-1 bg-gray-100 rounded-md ">
                <button
                  type="button"
                  className="btn glass btn-md w-full text-left mt-2 mb-2 justify-start"
                  onClick={() => {
                    const newUser: iUser = {
                      name: '',
                      email: search,
                      id: uuidv4(),
                    }
                    addMember(newUser)
                    setAddingNewDraegerEmail(false)
                  }}
                >
                  <div className={'flex '}>
                    <div className={'mr-4 mt-1'}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                        />
                      </svg>
                    </div>
                    <div className={''}>
                      <div className="pt-2 font-light ">{search}</div>
                    </div>
                  </div>
                </button>
              </div>
            )) ||
              // list of search results, possible users to add
              ((!!users?.length || !!teams?.length) && (
                <div className="max-h-40 overflow-y-auto rounded-md p-2 bg-gray-100 min-w-fit">
                  {users.map((user) => (
                    <div
                      key={user.id}
                      className="flex justify-between items-start mb-1 bg-gray-100 rounded-md"
                    >
                      <button
                        type="button"
                        className="btn glass btn-sm w-full text-left"
                        onMouseDown={(event) => {
                          event.preventDefault()
                          addMember(user)
                        }}
                      >
                        {user.name?.length === 0 ? user.email : user.name}
                      </button>
                    </div>
                  ))}
                  {teams.map((team) => (
                    <div
                      key={team.id}
                      className="flex justify-between items-start mb-1 bg-gray-100 rounded-md"
                    >
                      <button
                        type="button"
                        className="btn glass btn-sm w-full text-left"
                        onMouseDown={(event) => {
                          event.preventDefault()
                          addTeam(team)
                        }}
                      >
                        {team.name} (Team)
                      </button>
                    </div>
                  ))}
                </div>
              ))
          }

          {showInfoPlaceHolderMembers && (
            <div className={'alert alert-warning mt-2'}>
              * Placeholder members have been added to the list. They will be added
              to the list of members once they log in to GAIA.
            </div>
          )}

          {nonExistingUsers.length > 0 && (
            <div className={'alert alert-warning mt-2 max-h-36 overflow-auto'}>
              The following members could not be found. They need to log in to GAIA
              first. <br /> {nonExistingUsers.join('; ')}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MembersSelection
