import React, { useEffect, useState } from 'react'
import './sessionGroups.css'
import { iSession } from '../chatWindow/iSession.ts'
import { Card, CardHeader, Spinner } from '@nextui-org/react'
import { iSessionGroup } from './interfaces/iSessionGroup.ts'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { iTeam } from '../sessionControls/userManagement/iTeam.tsx'
import { shallow } from 'zustand/shallow'
import useGroupStore from '../../stateManagement/groupState.ts'
import useTeamStore from '../../stateManagement/teamState.ts'

type CreateSessionButtonProps = React.HTMLAttributes<HTMLDivElement> & {
  selectedTeam: iTeam | string
  group?: iSessionGroup
  description?: string
  defaultGroupName?: string
  assistantId?: string
  loading?: boolean
  onCreated?: (session: iSession) => void
  onBeforeCreateSession?: () => Promise<void>
}

function CreateSessionButton({
  group,
  selectedTeam,
  loading,
  description = 'Create a new session',
  defaultGroupName,
  assistantId,
  onCreated,
  onBeforeCreateSession,
}: CreateSessionButtonProps) {
  const [isLoading, setIsLoading] = useState(loading ?? false)
  useEffect(() => {
    setIsLoading(loading ?? false)
  }, [loading])
  const { addSession, activeSession, updateSession } = useSessionStore(
    (state) => ({
      addSession: state.addSession,
      activeSession: state.activeSession,
      updateSession: state.updateSession,
    }),
    shallow,
  )

  const { addGroup, groups } = useGroupStore(
    (state) => ({
      addGroup: state.addGroup,
      groups: state.groups,
    }),
    shallow,
  )
  const { setSelectedTeamByString } = useTeamStore(
    (state) => ({
      setSelectedTeamByString: state.setSelectedTeamByString,
    }),
    shallow,
  )

  const createSession = async () => {
    if (isLoading) return
    setIsLoading(true)
    if (onBeforeCreateSession) await onBeforeCreateSession()
    const changeToTeam = typeof selectedTeam === 'string'
    const session: iSession = {
      groupId: group?.id ?? activeSession?.groupId,
      teamId: typeof selectedTeam === 'string' ? selectedTeam : selectedTeam!.id!,
      title: 'New Session',
      isActive: false,
    }
    if (changeToTeam) {
      await setSelectedTeamByString(selectedTeam)
    }
    if (defaultGroupName) {
      console.log(groups)
      const checkGroupNameAlreadyExists = groups.find(
        (g) => g.title === defaultGroupName,
      )
      if (checkGroupNameAlreadyExists) {
        session.groupId = checkGroupNameAlreadyExists!.id
      } else {
        const defaultGroup = {
          title: defaultGroupName,
          billingGroupId:
            typeof selectedTeam === 'string' ? selectedTeam : selectedTeam!.id!,
        }
        // creat new folder for session
        return addGroup(defaultGroup, true)
          .then((group) => {
            session.groupId = group!.id
            return addSession(session!)
          })
          .then((session) => {
            if (assistantId)
              session!.sessionSettings!.baseSessionSettingsId = assistantId
            onCreated && onCreated(session!)
            return updateSession(session!)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    addSession(session!)
      .then((s) => {
        s!.sessionSettings!.baseSessionSettingsId = assistantId
        onCreated && onCreated(s!)
        return updateSession(session!)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Card
      className="border-dashed w-full border-2 bg-background/40"
      shadow={'none'}
      isDisabled={isLoading}
      isPressable={!isLoading}
      isHoverable={!isLoading}
      onPress={async () => await createSession()}
    >
      <CardHeader className="flex gap-3">
        {isLoading ? <Spinner></Spinner> : <PlusCircleIcon className="w-8 h-8" />}

        <div className="flex flex-col">
          <p className="text-start text-md">New session</p>
          <p className="text-small text-default-500">{description}</p>
        </div>
      </CardHeader>
    </Card>
  )
}

export default CreateSessionButton
