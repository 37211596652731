import React, { useEffect, useState } from 'react'
import {
  HttpLocation,
  iHttpParameter,
  iHttpParameterItem,
  httpLocations,
} from './model'
import { TrashIcon } from '@heroicons/react/24/outline'
import { FieldsEditor } from '../../../utils/inputfields/fieldEditors'
import {
  InputField,
  TextField,
  SelectField,
  FlagField,
  EnumField,
} from '../../../utils/inputfields/model'

const tooltipIn =
  'The location of the parameter in the request. Path: part of the URL. Query: part of the query string. Header: part of the request headers. Cookie: part of the request cookies. Template: part of a constant that is marked as template'
const tooltipDescription =
  'A description of the parameter. Help GAIA understand what this parameter is for.'
const tooltipRequired = 'GAIA needs to choose a value for this parameter.'
const tooltipEnum =
  'A list of possible values for the parameter. If none are provided, any value is allowed.'

interface iHttpParameterEditorProps {
  item: iHttpParameterItem
  index: number
  onNameChange: (index: number, newName: string) => void
  onValueChange: (index: number, constant: iHttpParameter) => void
  onRemove(index: number): void
}

function HttpParameterEditor({
  item,
  index,
  onNameChange,
  onValueChange,
  onRemove,
}: iHttpParameterEditorProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [fields, setFields] = useState<InputField[]>([
    new SelectField({
      key: 'in',
      name: 'In',
      value: { key: item.parameter.in },
      options: (httpLocations as unknown as string[]).map((location) => ({
        key: location,
      })),
      info: tooltipIn,
    }),
    new TextField({
      key: 'description',
      name: 'Description',
      value: item.parameter.description,
      lines: 4,
      info: tooltipDescription,
    }),
    new FlagField({
      key: 'required',
      name: 'Required',
      value: item.parameter.required,
      info: tooltipRequired,
    }),
    new EnumField({
      key: 'enum',
      name: 'Enum',
      values: item.parameter.enum,
      info: tooltipEnum,
    }),
  ])

  useEffect(() => {
    if (!item.name) {
      setIsExpanded(true)
    }
  }, [])

  useEffect(() => {
    onValueChange(index, {
      in: (fields[0] as SelectField).value.key as HttpLocation,
      description: (fields[1] as TextField).value,
      required: (fields[2] as FlagField).value,
      enum: (fields[3] as EnumField).values,
    })
  }, [fields])

  return (
    <>
      <div className="p-2 bg-gray-200 rounded-lg mb-2">
        <details open={isExpanded}>
          <summary>
            <div className="inline ml-1">
              <input
                type="text"
                className="input input-bordered w-10/12"
                value={item.name}
                placeholder="Name"
                onChange={(e) => onNameChange(index, e.target.value)}
                required
              />
              <TrashIcon
                className="w-6 h-6 inline-block ml-4 cursor-pointer"
                onClick={() => onRemove(index)}
              />
            </div>
          </summary>
          <div className="w-10/12">
            <FieldsEditor fields={fields} onChange={setFields}></FieldsEditor>
          </div>
        </details>
      </div>
    </>
  )
}
export default HttpParameterEditor
