export interface iHttpTemplate {
  method: HttpMethod
  url: string
  constants: HttpConstantSet
  parameters: HttpParameterSet
}

export type HttpConstantSet = { [key: string]: iHttpConstant }

export interface iHttpParameter {
  description: string
  enum: string[]
  in: HttpLocation
  required: boolean
}

export interface iHttpConstantItem {
  name: string
  constant: iHttpConstant
}

export type HttpParameterSet = { [key: string]: iHttpParameter }

export interface iHttpConstant {
  value: string
  in: HttpLocation
  isSecret: boolean
  isTemplate: boolean
}

export interface iHttpParameterItem {
  name: string
  parameter: iHttpParameter
}

export const httpMethods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'auto'] as const
export type HttpMethod = (typeof httpMethods)[number]

export const httpLocations = ['header', 'query', 'path', 'body', 'template'] as const
export type HttpLocation = (typeof httpLocations)[number]
