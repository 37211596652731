import React, { useEffect, useState } from 'react'
import { HttpParameterSet, iHttpParameter, iHttpParameterItem } from './model'
import HttpParameterEditor from './httpParameterEditor'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@nextui-org/react'

const tooltipParameters =
  'Parameters for the request. These can be used to pass data to the server. For example, a GET request can have parameters in the URL, while a POST request can have parameters in the body. If you will have JSON data in your request body you need to set the Content-Type header to application/json using a constant or a parameter'

interface iHttpParameterSetEditorProps {
  parameterSet: HttpParameterSet
  onChange: (parameterSet: HttpParameterSet) => void
}

function HttpParameterSetEditor({
  parameterSet,
  onChange,
}: iHttpParameterSetEditorProps) {
  const [parameters, setParameters] = useState<iHttpParameterItem[]>(
    toParametersList(parameterSet),
  )
  const [isExpanded, setIsExpanded] = useState(false)

  function toParametersList(parameters: HttpParameterSet): iHttpParameterItem[] {
    return Object.keys(parameters).map((name) => ({
      name,
      parameter: parameters[name],
    }))
  }

  function toParametersSet(parameters: iHttpParameterItem[]): HttpParameterSet {
    const set: HttpParameterSet = {}
    parameters.forEach((item) => {
      set[item.name] = item.parameter
    })
    return set
  }

  const handleNameChange = (index: number, newName: string) => {
    setParameters(
      parameters.map((item, i) => {
        if (i === index) {
          return { name: newName, parameter: item.parameter }
        } else {
          return item
        }
      }),
    )
  }

  const handleValueChange = (index: number, parameter: iHttpParameter) => {
    setParameters(
      parameters.map((item, i) => {
        if (i === index) {
          return { name: item.name, parameter }
        } else {
          return item
        }
      }),
    )
  }

  useEffect(() => {
    onChange(toParametersSet(parameters))
  }, [parameters])

  const addNewParameter = () => {
    setParameters([
      ...parameters,
      {
        name: '',
        parameter: { description: '', in: 'query', required: false, enum: [] },
      },
    ])
    setIsExpanded(true)
  }

  const removeParameter = (index: number) =>
    setParameters(parameters.filter((_, i) => i !== index))

  return (
    <div className="mb-4">
      <label className="label">
        <span className="label-text flex text-base">
          Parameters ({parameters.length})
          <Tooltip
            content={tooltipParameters}
            color={'primary'}
            className={'max-w-96'}
          >
            <InformationCircleIcon
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.0}
              stroke="currentColor"
              className="w-4 h-4 ml-1"
            />
          </Tooltip>
        </span>
      </label>
      <div className="ml-0">
        {parameters &&
          parameters.map((item, i) => (
            <HttpParameterEditor
              index={i}
              item={item}
              onNameChange={handleNameChange}
              onValueChange={handleValueChange}
              onRemove={removeParameter}
            />
          ))}
      </div>
      <div className="text-center">
        <PlusCircleIcon
          onClick={addNewParameter}
          className="w-8 h-8 inline cursor-pointer"
        />
      </div>
    </div>
  )
}
export default HttpParameterSetEditor
