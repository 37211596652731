import React, { useEffect, useMemo, useState } from 'react'
import {
  AssistantModelAttribute,
  IAssistantModel,
} from '../../../interfaces/iAssistantModel.ts'
import {
  DocumentTextIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from '@nextui-org/react'
import useAssistantModelStore from '../../../stateManagement/assistantModelState.ts'
import useTeamStore from '../../../stateManagement/teamState.ts'
import { shallow } from 'zustand/shallow'

interface IAIModelSelectionProps {
  modelId: string
  onChange?: (value: {
    modelName: string
    modelRegion: string
    modelId: string
  }) => void
  disableHint?: boolean
  isDisabled?: boolean
  context?: 'all' | 'team'
}

function AssistantModelSelection({
  modelId,
  onChange,
  disableHint,
  isDisabled,
  context = 'all',
}: IAIModelSelectionProps) {
  const { getAssistantModelsByContext, assistantModels: allAssistantModels } =
    useAssistantModelStore(
      (state) => ({
        assistantModels: state.assistantModels,
        getAssistantModelsByContext: state.getAssistantModelsByContext,
      }),
      shallow,
    )

  const { selectedTeam } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )
  const [selectedModel, setSelectedModel] = useState<IAssistantModel | undefined>()
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>()

  const assistantModels = useMemo(() => {
    return getAssistantModelsByContext(context, selectedTeam?.id)
  }, [allAssistantModels, selectedTeam])

  useEffect(() => {
    const assistantModel = assistantModels.find((model) => model.id === modelId)

    setSelectedModel(assistantModel ?? undefined)
    setSelectedRegion(assistantModel?.region ?? undefined)
  }, [modelId, assistantModels])

  useEffect(() => {
    if (selectedModel && selectedRegion && onChange)
      onChange({
        modelName: selectedModel!.deploymentName,
        modelRegion: selectedRegion!,
        modelId: selectedModel!.id,
      })
  }, [selectedModel, selectedRegion])

  const regions = useMemo(() => {
    const tmpRegions: string[] = []
    assistantModels.map((model) => {
      tmpRegions.push(model.region)
    })
    return [...new Set(tmpRegions)]
  }, [assistantModels])

  const filterModelByRegion = (region: string) =>
    assistantModels.filter((model) => model.region === region)

  const models = useMemo(() => {
    return filterModelByRegion(selectedRegion!)
  }, [selectedRegion])

  const attributeDisplay = (attr: string) => {
    let icon = <QuestionMarkCircleIcon className={'w-5 h-5'} />
    let tooltip = attr
    switch (attr) {
      case AssistantModelAttribute.toolCalling:
        icon = <WrenchIcon className={'w-5 h-5'} />
        tooltip = 'Tool Calling'
        break
      case AssistantModelAttribute.parallelToolCalling:
        icon = <WrenchScrewdriverIcon className={'w-5 h-5'} />
        tooltip = 'Parallel Tool Calling'
        break
      case AssistantModelAttribute.textProcessing:
        icon = <DocumentTextIcon className={'w-5 h-5'} />
        tooltip = 'Text Processing'
        break
      case AssistantModelAttribute.imageProcessing:
        icon = <PhotoIcon className={'w-5 h-5'} />
        tooltip = 'Image Processing'
        break
    }
    return {
      tooltip,
      icon,
    }
  }

  return (
    !!assistantModels.length && (
      <div>
        <div className="join w-full">
          {regions.length > 0 && (
            <select
              value={selectedRegion!}
              disabled={isDisabled}
              className="select select-bordered join-item max-w-28"
              onChange={(e) => {
                const region = e.target.value
                const isCurrentModelInRegionAvailable =
                  selectedModel?.region === region
                if (!isCurrentModelInRegionAvailable)
                  setSelectedModel(filterModelByRegion(region)[0])
                setSelectedRegion(region)
              }}
            >
              {regions.map((region, key) => (
                <option key={key + '-region'} value={region}>
                  {region}
                </option>
              ))}
            </select>
          )}
          <select
            className="select select-bordered w-full join-item"
            value={selectedModel?.deploymentName}
            disabled={isDisabled}
            onChange={(e) => {
              setSelectedModel(
                models.find((model) => model.deploymentName === e.target.value),
              )
            }}
          >
            {models.map((assistantModel, key) => (
              <option key={key + '-model'} value={assistantModel.deploymentName}>
                {assistantModel.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          {selectedModel?.attributes.map((attr, key) => {
            const attribute = attributeDisplay(attr)
            return (
              <Tooltip
                content={attribute.tooltip}
                color={'primary'}
                key={key + '-attr'}
                className={'max-w-96'}
              >
                <div
                  key={key + '-attr1'}
                  className="badge badge-lg h-8 mr-1 badge-primary my-2 "
                >
                  <div className="avatar -ml-1"></div>
                  <span className="ml-2">{attribute.icon}</span>
                </div>
              </Tooltip>
            )
          })}
        </div>
        {!disableHint && (
          <div className={'text-blue-400 my-3'}>
            Clear the Chat session if you change between the vision and the standard
            models.
          </div>
        )}
      </div>
    )
  )
}

export default AssistantModelSelection
