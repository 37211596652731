import { createContext, useContext } from 'react'
import { Popup } from '../../components/sessionControls/contextPopup'

type MainContextType = {
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
  error: Error | any | null
  setError: (error: Error | any | null) => void
  feedback: string
  setFeedback: (feedback: string) => void
  feedbackType: string
  setFeedbackType: (feedbackType: string) => void
  popup: Popup
  popupArgs?: any
  setPopup: (popup: Popup, args?: any) => void
  showSettings: boolean
  setShowSettings: (showSettings: boolean) => void
  rerunOnboarding: boolean
  setRerunOnboarding: (rerunOnboarding: boolean) => void
}

export const MainContext = createContext<MainContextType | undefined>(undefined)

export const useMain = () => {
  const context = useContext(MainContext)
  if (!context) {
    throw new Error('useMain must be used within a MainProvider')
  }
  return context
}
