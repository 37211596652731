import React, { useState } from 'react'
import { FolderPlusIcon } from '@heroicons/react/24/outline'

import { Button } from '@nextui-org/react'
import DialogSessionGroupModal from '../sessionsGroups/dialogSessionGroupModal.tsx'

const EmptySessionFolder = () => {
  const [dialogSessionGroupModalDialog, setDialogSessionGroupModalDialog] =
    useState<boolean>(false)

  return (
    <div className={'flex flex-col max-w-full  w-full mx-auto px-2 sm:px-4'}>
      <div className={'grid place-content-center h-full place-items-stretch'}>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Welcome to GAIA!</h2>
            <p>GAIA is here to assist you with any queries you may have.</p>
            <p>You have no folders yet.</p>
            <p className="flex items-center">
              Click on
              <Button
                className={'m-1'}
                fullWidth
                isIconOnly
                variant={'light'}
                color={'primary'}
                onClick={() => setDialogSessionGroupModalDialog(true)}
              >
                <FolderPlusIcon className={'w-5 h-5'} />
              </Button>
              on the left to create your first folder.
            </p>
            <DialogSessionGroupModal
              open={dialogSessionGroupModalDialog}
              onClose={() => setDialogSessionGroupModalDialog(false)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptySessionFolder
