import ProfileImage from '../../utils/profileImage/profileImage.tsx'
import Description from '../../utils/description.tsx'
import React, { useEffect } from 'react'
import { iPlugin } from '../plugins/iPlugin.ts'

interface iKnowledgeCardProps {
  plugin: iPlugin
  handleSelect: (plugin: iPlugin) => void
}

function PluginCard(props: iKnowledgeCardProps) {
  const [selected, setSelected] = React.useState(false)

  useEffect(() => {
    if (props.plugin.selected) {
      setSelected(true)
    }
  }, [])

  const select = () => {
    props.handleSelect(props.plugin)
    setSelected(!selected)
  }

  return (
    <div key={props.plugin.id + '_kc'} className="p-1 tracking-wide mt-2 min-h-0">
      <div className="card m-1 w-64 glass hover:shadow-lg hover:scale-105 transition-transform duration-500 ease-in-out max-w-64 min-h-96">
        <div className="card-body">
          <div className=" justify-between">
            <ProfileImage contact={props.plugin.ownerEmail!} width={8} />
          </div>
          <h2 className="card-title">{props.plugin.name}</h2>

          <Description description={props.plugin.description!} />

          <div className={'text-sm text-blue-500 text-right'}>
            {props.plugin.fromBaseAssistantInherited && 'Pre-set by assistant'}
          </div>

          <div className="card-actions justify-end">
            <button
              className={`btn btn-sm btn-primary ${selected ? '' : 'btn-outline'}`}
              onClick={() => {
                select()
              }}
              disabled={props.plugin.fromBaseAssistantInherited}
            >
              {selected ? 'Deselect' : 'Select'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PluginCard
