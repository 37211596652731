import { create } from 'zustand'
import { iPlugin } from '../components/sessionControls/plugins/iPlugin.ts'
import pluginService from '../services/pluginService.ts'
import { Role } from '../interfaces/iShared.ts'
import { iAssistant } from '../components/sessionControls/assistants/iAssistantTypes.tsx'
import { getUserKey } from '../components/login/authentication.tsx'

interface IPluginState {
  isLoading: boolean
  plugins: iPlugin[]
  getPluginRole: (pluginId: string) => Role | undefined
  setIsLoading: (isLoading: boolean) => void
  hasRightTo: (minRole: Role, assistantId: string) => boolean
  setPlugins: (teams: iPlugin[]) => void
  loadPlugins: (baseSettingsId?: string) => Promise<iPlugin[]>
}

const usePluginStore = create<IPluginState>((set, getState) => ({
  isLoading: false,
  plugins: [],
  setPlugins: (plugins: iPlugin[]) => set(() => ({ plugins })),
  getPluginRole: (pluginId) => {
    const plugin = getState().plugins.find((p: iPlugin) => p.id === pluginId)
    const shared = plugin?.shared?.find((s) => s.id === getUserKey())

    return shared?.role
  },
  hasRightTo: (minRole: Role, pluginId) => {
    const plugin = getState().plugins.find((p: iPlugin) => p.id === pluginId)
    const isOwner = plugin?.ownerId === getUserKey()
    if (isOwner) return true
    const role = getState().getPluginRole(pluginId)
    if (role === undefined) return false
    return role <= minRole
  },
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  loadPlugins: async (baseSettingsId?: string) => {
    const plugins = await pluginService.getPlugins(baseSettingsId)
    getState().setPlugins(plugins)
    return plugins
  },
}))

export default usePluginStore
