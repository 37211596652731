import tailwindTypography from '@tailwindcss/typography'
import daisyui from 'daisyui'
import { nextui } from '@nextui-org/react'
export default {
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
    './node_modules/tailwind-datepicker-react/dist/**/*.js',
    './node_modules/@nextui-org/theme/dist/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      // that is animation class
      animation: {
        create: 'createAnimation 250ms ease-in-out',
      },

      // that is actual animation
      keyframes: () => ({
        createAnimation: {
          '0%': { transform: 'translateY(10px)', opacity: 0 },
          '100%': { transform: 'translateY(0px)', opacity: 1 },
        },
      }),
    },
    screens: {
      'max-2xl': { max: '1535px' },
      // => @media (max-width: 1535px) { ... }

      'max-xl': { max: '1279px' },
      // => @media (max-width: 1279px) { ... }

      'max-lg': { max: '1023px' },
      // => @media (max-width: 1023px) { ... }

      'max-md': { max: '767px' },
      // => @media (max-width: 767px) { ... }

      'max-sm': { max: '639px' },
      // => @media (max-width: 639px) { ... }

      sm: '639px',
      // => @media (max-width: 639px) { ... }

      md: '767px',
      // => @media (max-width: 767px) { ... }

      lg: '1023px',
      // => @media (max-width: 1023px) { ... }

      xl: '1279px',
      // => @media (max-width: 1279px) { ... }

      '2xl': '1535px',
      // => @media (max-width: 1535px) { ... }
    },
  },
  daisyui: {
    themes: [
      {
        draeger: {
          primary: '#374151',
          secondary: '#79a5ec',
          'secondary-focus': '#d5e2f6',
          accent: '#ff9898',
          neutral: '#374151', //"#d5e2f6", 3e5160
          'base-100': '#f5f5f5',
          info: '#d5e2f6',
          success: '#00dd31',
          warning: '#ffe300',
          error: '#f30303',
          link: '#47b3ff',
          'neutral-content': '#eeeeee',

          '--rounded-box': '0.4rem',
          '--rounded-btn': '.25rem',
          '--rounded-badge': '.4rem',
          '--animation-btn': '0.25s', // duration of animation when you click on button
          '--animation-input': '0.2s', // duration of animation for inputs like checkbox, toggle, radio, etc
          '--btn-focus-scale': '0.95', // scale transform of button when you focus on it
          '--border-btn': '1px', // border width of buttons
          '--tab-border': '1px', // border width of tabs
          '--tab-radius': '0.5rem', // border radius of tabs
        },
      },
      {
        draegerDark: {
          primary: '#cbe9ff',
          secondary: '#495e6e',
          'secondary-focus': '#607a8a',
          accent: '#ff9898',
          neutral: '#495e6e', // #d5e2f6
          'base-100': '#344351',
          info: '#d5e2f6',
          success: '#00dd31',
          warning: '#ffe300',
          error: '#f30303',
          link: '#47b3ff',
          'neutral-content': '#f5f5f5',
        },
      },
    ],
    base: true,
    styled: true,
    darkTheme: 'draegerDark',
  },
  darkMode: 'class',
  plugins: [tailwindTypography, daisyui, nextui()],
}
