import React, { useMemo, useState } from 'react'
import { Avatar, Select, SelectItem } from '@nextui-org/react'
import { useSearchParams } from 'react-router-dom'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import { Key } from '@react-types/shared'
import useTeamStore from '../../stateManagement/teamState.ts'
import useGroupStore from '../../stateManagement/groupState.ts'
import { iTeam, isDefaultTeam } from '../sessionControls/userManagement/iTeam.tsx'
import { twMerge } from 'tailwind-merge'
import { SelectSlots, SlotsToClasses } from '@nextui-org/theme'
import { UseFormRegisterReturn } from 'react-hook-form'
import userService from '../../services/userService.ts'
import { shallow } from 'zustand/shallow'
import GaiaLogo from '../basic/logo/gaiaLogo.tsx'

type TeamSelectProps = React.HTMLAttributes<HTMLDivElement> & {
  onSelectionChange?: (team: iTeam) => void
  selectedKeys?: 'all' | Iterable<Key> | undefined
  withoutDefaultTeam?: boolean
  withoutTeamIcon?: boolean
  withoutCostcenter?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  errorMessage?: string
  formRegister?: UseFormRegisterReturn<string>
  classNames?: SlotsToClasses<SelectSlots>
}

function TeamSelect({
  onSelectionChange,
  withoutDefaultTeam,
  withoutTeamIcon,
  withoutCostcenter,
  errorMessage,
  isInvalid,
  isDisabled,
  selectedKeys,
  formRegister,
  className,
  classNames,
}: TeamSelectProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const { getGroups } = useGroupStore(
    (state) => ({
      getGroups: state.getGroups,
    }),
    shallow,
  )

  const {
    teams,
    selectedTeam,
    setSelectedTeam,
    isLoading: isTeamLoading,
  } = useTeamStore(
    (state) => ({
      selectedTeam: state.selectedTeam,
      setSelectedTeam: state.setSelectedTeam,
      teams: state.teams,
      isLoading: state.isLoading,
    }),
    shallow,
  )

  const classes = twMerge(className)

  const handleSelectionChange = async (id: string) => {
    const team = teams?.find((team) => team.id === id)
    if (onSelectionChange && team) {
      onSelectionChange(team)
    } else if (team) {
      setIsLoading(true)
      setSelectedTeam(team)
      searchParams.set('teamId', team.id!)
      setSearchParams(searchParams)
      userService.setLastTeamId(team.id!)
      getGroups({ teamId: team.id }).then(() => setIsLoading(false))
    }
  }

  const selectedTeamValues: 'all' | Iterable<Key> | undefined = useMemo(
    () =>
      selectedKeys
        ? selectedKeys
        : selectedTeam
          ? ([selectedTeam.id?.toString()] as Iterable<Key>)
          : undefined,
    [selectedTeam, selectedKeys],
  )

  const selectLabel = useMemo(() => {
    if (isTeamLoading) {
      return 'Loading teams...'
    } else if (teams?.length) {
      return 'Select a team'
    } else if (!teams?.length) {
      return 'No teams available'
    }
    return ''
  }, [teams])

  const teamItems = useMemo(() => {
    const tmpTeams = teams ?? []
    tmpTeams.sort((x, y) => (isDefaultTeam(x) ? -1 : isDefaultTeam(y) ? 1 : 0))
    return tmpTeams.filter((team: iTeam) =>
      withoutDefaultTeam ? !isDefaultTeam(team) : true,
    )
  }, [teams])

  return (
    <Select
      selectedKeys={selectedTeamValues}
      {...formRegister}
      isInvalid={!!isInvalid}
      errorMessage={errorMessage}
      items={teamItems}
      isLoading={isTeamLoading || isLoading}
      isDisabled={isTeamLoading || isDisabled}
      placeholder={selectLabel}
      aria-label={selectLabel}
      labelPlacement="outside"
      className={classes}
      classNames={{
        ...classNames,
        trigger: twMerge('h-12', classNames?.trigger),
        base: 'overflow-hidden',
      }}
      onChange={(e) => handleSelectionChange(e.target.value)}
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.data!.id} className="flex items-center gap-2">
            {!withoutTeamIcon && (
              <Avatar
                alt={item.data!.name}
                className="flex-shrink-0"
                size="sm"
                icon={
                  isDefaultTeam(item.data!) ? (
                    <GaiaLogo className={'w-5 h-5'} />
                  ) : (
                    <UserGroupIcon className={'h-5 w-5'} />
                  )
                }
              />
            )}

            <div className="flex flex-col truncate">
              <span className="truncate">{item.data!.name}</span>
              {!withoutCostcenter && (
                <span className="text-default-500 text-tiny truncate">
                  (Cost-Center: {item.data!.costCenter})
                </span>
              )}
            </div>
          </div>
        ))
      }}
    >
      {(team) => (
        <SelectItem key={team.id!} textValue={team.id} value={team.id}>
          <div className="flex gap-2 items-center">
            <Avatar
              alt={team.name}
              className="flex-shrink-0"
              size="sm"
              icon={
                isDefaultTeam(team) ? (
                  <GaiaLogo className={'w-5 h-5'} />
                ) : (
                  <UserGroupIcon className={'h-5 w-5'} />
                )
              }
            />
            <div className="flex flex-col truncate">
              <span className="text-small truncate">{team.name}</span>
              <span className="text-tiny text-default-400 truncate">
                {team.costCenter}
              </span>
            </div>
          </div>
        </SelectItem>
      )}
    </Select>
  )
}

export default TeamSelect
