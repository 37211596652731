import React from 'react'

interface iImportMembers {
  handleFile: (arg0: string[]) => void
}
function ImportMembers(props: iImportMembers) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault()
    hiddenFileInput.current!.click()
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    const fileUploaded = files![0]
    event.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text: string = String(e.target!.result)

      let formattedEntries: string[] = []
      // if the file has a comma as a delimiter
      if (text.includes(';')) {
        const entries = text
          .split(';')
          .map((entry) => entry.trim())
          .filter((entry) => entry)

        formattedEntries = entries.map((entry) => {
          // if the entry does not contain a comma, it is likely "Forename Surname", convert it to "Surname, Forename"
          if (!entry.includes(',') && !entry.includes('@')) {
            const names = entry.split(' ')
            return `${names[1]}, ${names[0]}`
          }
          return entry
        })
      } else {
        // if the file has a comma as a delimiter, split the entries by comma, take everything as it is and remove empty entries
        formattedEntries = text
          .split(',')
          .map((entry) => entry.trim())
          .filter((entry) => entry)
      }

      props.handleFile(formattedEntries)
    }
    event.target.value = ''
    reader.readAsText(fileUploaded)
  }
  return (
    <>
      <button className="btn btn-sm btn-primary" onClick={handleClick}>
        Import Members
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(event) => {
          handleChange(event)
        }}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default ImportMembers
