/**
 * @description This function is used to validate the draeger email address
 * @param {string} email The email address to validate
 * @return {boolean} Returns true if the email is a valid draeger address, otherwise false
 */
export function isDraegerEmail(email: string): boolean {
  const regex = /@draeger.com$/i
  return regex.test(email)
}

/**
 * @description This function is used to validate the email address
 * @param {string} email The email address to validate
 * @return {boolean} Returns true if the email is a valid email address, otherwise false
 */
export function isEmail(email: string): boolean {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return regex.test(email)
}
