import React from 'react'

import {
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CpuChipIcon,
  MicrophoneIcon,
  PaperClipIcon,
  PuzzlePieceIcon,
  SwatchIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Popup } from '../sessionControls/contextPopup'

import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import useTeamStore from '../../stateManagement/teamState.ts'
import { Role } from '../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'

type ChatMessageInstructionsProps = {
  toggleSettingsSection: () => void
}

const ChatMessageInstructions = ({
  toggleSettingsSection,
}: ChatMessageInstructionsProps) => {
  const { setPopup } = useMain()

  const { hasRightTo } = useTeamStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )
  return (
    <>
      <div className={'grid place-content-center h-full place-items-stretch'}>
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Welcome to GAIA!</h2>
            <p>GAIA is here to assist you with any queries you may have.</p>
            <p>Each chat window can have its own unique settings.</p>
            {hasRightTo(Role.Member) ? (
              <>
                <p className={'flex'}>
                  <CpuChipIcon
                    className="h-5 w-5 mr-2 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    onClick={toggleSettingsSection}
                  />
                  Customize GAIA responses to suit your needs
                </p>
                <p className={'flex'}>
                  <CloudArrowUpIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 cursor-pointer"
                    onClick={() => setPopup(Popup.Container)}
                  />
                  Upload files to your personal storage for easy access.
                </p>

                <p className={'flex'}>
                  <PaperClipIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 cursor-pointer"
                    onClick={() => setPopup(Popup.Files)}
                  />
                  Include files and directories directly into the chat for quick
                  sharing.
                </p>
                <p className={'flex'}>
                  <SwatchIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 cursor-pointer"
                  />
                  Show the initial assistant startup screen.
                </p>
                <p className={'flex'}>
                  <CloudArrowDownIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 cursor-pointer"
                    onClick={() => setPopup(Popup.ContainerSelector)}
                  />
                  Include your knowledge container in your query.
                </p>
                <p className={'flex'}>
                  <PuzzlePieceIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 cursor-pointer"
                    onClick={() => setPopup(Popup.PluginSelector)}
                  />
                  Activate Extensions like the web search.
                </p>
                <p className={'flex'}>
                  <MicrophoneIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2 cursor-pointer"
                  />
                  Speak to GAIA instead of writing your query.
                </p>
                <p className={'flex'}>
                  <TrashIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  />
                  Clear your session and start over.
                </p>
              </>
            ) : (
              <>
                <p className={'flex'}>
                  Your session uses predefined settings. Therefore you have limited
                  access to configure your session.
                </p>
                <p className={'flex'}>
                  <MicrophoneIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-1 cursor-pointer"
                  />
                  Speak to GAIA instead of writing your query.
                </p>
                <p className={'flex'}>
                  <TrashIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mr-1"
                  />
                  Clear your session and start over.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatMessageInstructions
