import { iSessionGroup } from '../components/sessionsGroups/interfaces/iSessionGroup'
import axiosClient from '../utils/axiosClient'

class SessionGroupService {
  private static instance: SessionGroupService

  private constructor() {}

  public static getInstance(): SessionGroupService {
    if (!SessionGroupService.instance) {
      SessionGroupService.instance = new SessionGroupService()
    }

    return SessionGroupService.instance
  }

  public async createGroup(sessionGroup: iSessionGroup): Promise<iSessionGroup> {
    return (await axiosClient.post(`/group`, sessionGroup)).data as iSessionGroup
  }

  public async deleteGroup(groupId: string): Promise<void> {
    await axiosClient.delete(`/group/${groupId}`)
  }

  public async getGroup(groupId: string): Promise<iSessionGroup> {
    return (await axiosClient.get(`/group/${groupId}`)).data as iSessionGroup
  }

  public async getGroups(teamId?: string): Promise<iSessionGroup[]> {
    return (await axiosClient.get(`/groups/${teamId ?? ''}`)).data as iSessionGroup[]
  }

  public async updateGroup(group: iSessionGroup): Promise<void> {
    await axiosClient.put(`/group`, group)
  }
}

export default SessionGroupService.getInstance()
