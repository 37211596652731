import React, { ReactNode, useEffect } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useBreakpoint } from '../../../hooks/useBreakpoint.ts'

export type DrawerProps = React.HTMLAttributes<HTMLDivElement> & {
  side: ReactNode
  open?: boolean
  end?: boolean
  toggleClassName?: string
  contentClassName?: string
  sideClassName?: string
  overlayClassName?: string
  onClickOverlay?: () => void
  onToggleOpen?: (val: boolean) => void
}

export default function Drawer({
  children,
  side,
  open,
  end,
  className,
  toggleClassName,
  contentClassName,
  sideClassName,
  overlayClassName,
  onClickOverlay,
  onToggleOpen,
  ...props
}: DrawerProps) {
  const classes = twMerge(
    'drawer',
    className,
    clsx({
      'drawer-end': end,
    }),
  )

  const { isBelowLg } = useBreakpoint('lg')

  useEffect(() => {
    onToggleOpen && onToggleOpen(!isBelowLg)
  }, [isBelowLg])

  return (
    <div aria-expanded={open} {...props} className={classes}>
      <input
        type="checkbox"
        className={clsx('drawer-toggle', toggleClassName)}
        checked={open}
        readOnly
      />
      {children && (
        <div className={clsx('drawer-content relative', contentClassName)}>
          {children}
        </div>
      )}
      <div className={clsx('drawer-side z-20', sideClassName)}>
        <label
          className={clsx('drawer-overlay', overlayClassName)}
          onClick={onClickOverlay}
        ></label>
        {side}
      </div>
    </div>
  )
}
