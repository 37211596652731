import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { CheckIcon, MicrophoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ContentItemType, iMessage } from './iMessage'
import './mermaid-custom.css'
import MonacoEditor from '@uiw/react-monacoeditor'
import MarkdownPreview from '@uiw/react-markdown-preview'
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon'
import FileViewer from '../sessionControls/files/fileViewer.tsx'
import { iChatMessageProps } from './iChatMessage.tsx'
import FeedbackWidget from './feedback/feedbackWidget.tsx'
import knowledgeContainerService from '../../services/knowledgeContainerService.ts'
import PdfViewer from './sourceFiles/pdfViewer.tsx'
import sessionService from '../../services/sessionService.ts'
import { useMessages } from '../../stateManagement/contexts/messageContext.tsx'
import { iSession } from './iSession.ts'
import Citation from './sourceFiles/citation.tsx'
import {
  iCitation,
  iFile,
  iKnowledgeReference,
  iSourceFileCitation,
  ViewerType,
} from './sourceFiles/iSourceFileCitations'
import useSessionStore from '../../stateManagement/sessionState.ts'
import { Button, Spinner, Tab, Tabs } from '@nextui-org/react'
import './chatMessage.css'
import { ToolCall } from './toolCalls/toolCall.tsx'
import ChatMessageHeader from './chatMessageHeader.tsx'
import { getCodeString } from 'rehype-rewrite'
import katex from 'katex'
import 'katex/dist/katex.css'
import rehypeSanitize from 'rehype-sanitize'
import { shallow } from 'zustand/shallow'

function ChatMessage({
  message,
  sessionId,
  userId,
  transfered,
  isLastMessage,
}: iChatMessageProps) {
  const { sessions } = useSessionStore(
    (state) => ({ sessions: state.sessions }),
    shallow,
  )

  const { updateMessage, deleteMessage } = useMessages()

  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editedMessage, setEditedMessage] = useState(message.content)
  const [messageContent, setMessageContent] = useState([] as string[])
  const [contentLoaded, setContentLoaded] = useState(false)
  const [references, setReferences] = useState<iKnowledgeReference[]>()
  const [activeReferenceId, setActiveReferenceId] = useState<number>()
  const session = sessions.find((session) => session.id === sessionId)
  const [displayToolCallsTab, setDisplayToolCallsTab] = useState(false)
  const [displaySourceFileTab, setDisplaySourceFileTab] = useState(false)
  const [selected, setSelected] = useState<string>('conversation')

  useEffect(() => {
    setDisplayToolCallsTab(!!message.toolCalls && message.toolCalls.length > 0)
  }, [message.toolCalls])

  const displayFeedback = useMemo(() => {
    return !session?.isGenerating
  }, [session?.isGenerating])

  useEffect(() => {
    setDisplaySourceFileTab(
      !!(
        !session?.isGenerating &&
        message.toolCalls &&
        message.citations &&
        message.citations.files?.length > 0
      ),
    )
  }, [message.toolCalls, message.citations])

  useEffect(() => {
    setContentLoaded(false)
    convertMessage(message).then((content) => {
      setMessageContent(content!)
      setContentLoaded(true)
    })
  }, [message.content, message.contentItems])

  const messageError = useMemo(() => {
    if (session?.isGenerating && isLastMessage) return ''
    if (message.error) return message.error.message
    if (!messageContent.length && message.id && contentLoaded)
      return 'Empty answer from Backend'
    return ''
  }, [message, messageContent])

  const getReferenceContent = (refId: number): string => {
    let content = ''
    message.citations?.files.forEach((file: iFile) => {
      file.citations.forEach((citation) => {
        if (citation.citationId === refId) {
          content = citation.citation
        }
      })
    })
    return content
  }

  useEffect(() => {
    if (selected === 'sourceFiles') {
      addDocumentAccess().then((allToolCitations) =>
        updateReferences(allToolCitations),
      )
    }
  }, [displaySourceFileTab, selected])

  const addDocumentAccess = () => {
    return sessionService.getSourceFiles(sessionId, message.id!)
  }

  const updateReferences = (sourceFileCitation: iSourceFileCitation) => {
    const refs: iKnowledgeReference[] = []
    sourceFileCitation.files.forEach((file) => {
      file.citations.forEach((citation) => {
        const content = getReferenceContent(citation.citationId)
        if (!content) {
          return
        }
        refs.push({
          id: citation.citationId,
          documentAccess: {
            accessUrl: file.accessUrl,
            documentName: file.documentName,
            mimeType: file.mimeType,
            viewerType: file.viewerType,
          },
          page: citation.page,
          content: content,
        })
      })
    })
    setReferences(refs)
  }

  const showCitation = (citation: iCitation | null) => {
    if (!citation) {
      return
    }
    addDocumentAccess().then((allToolCitations) => {
      const file = allToolCitations.files.find((file: iFile) => {
        return file.citations.find((c) => c.citationId === citation.citationId)
      })
      if (!file) {
        return
      }
      if (file.viewerType === ViewerType.HttpNewTab) {
        window.open(file.accessUrl, '_blank')
        return
      }
      if (file.viewerType === ViewerType.GaiaViewer) {
        updateReferences(allToolCitations)
        setSelected('sourceFiles')
        setActiveReferenceId(citation.citationId)
      }
    })
  }

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleSaveEdit = () => {
    message.content = editedMessage
    setIsEditing(false)
    updateMessage(session as iSession, message)
  }

  const handleCancelEdit = () => {
    setIsEditing(false)
  }

  const handleDelete = () => {
    deleteMessage(session as iSession, message)
  }

  function isDefaultExtensionType(key: string): key is DefaultExtensionType {
    return key in defaultStyles
  }

  const convertMessage = async (message: iMessage): Promise<string[]> => {
    if (message.content) {
      return [message.content]
    }

    if (message.contentItems) {
      return await Promise.all(
        message.contentItems.map(async (item) => {
          if (item.type === ContentItemType.Text) {
            return item.content
          }
          if (item.type === ContentItemType.Image) {
            try {
              const imageBlob = await knowledgeContainerService.downloadImageBlob(
                item.content,
              )
              return await readAsDataURL(imageBlob)
            } catch (error) {
              console.error('Error downloading image blob:', error)
              return ''
            }
          }
          return ''
        }),
      )
    }
    return []
  }

  const readAsDataURL = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(`<img class="object-scale-down" src="${reader.result}"/>`)
      }
      reader.onerror = () => reject('Failed to read blob as data URL.')
      reader.readAsDataURL(blob)
    })
  }

  const customStyle: CSSProperties = {
    '--color-canvas-default': 'transparent',
    // "--color-fg-default": "var(--bc)",
    // '--color-canvas-subtle': '#1f2937',
    margin: '10px',
    textAlign: 'left',
    fontFamily: 'PangeaText',
    fontSize: '14px',
    overflowX: 'auto',
  } as CSSProperties

  const chatClass = useMemo(() => {
    return message.ownerId === userId && message.role === 'user'
      ? 'chat-end'
      : 'chat-start'
  }, [])

  if (message.role === 'system') {
    return <></>
  }

  if (message.contentType === 'contentMessage') {
    return (
      <div>
        <div
          className="flex justify-center items-center m-2"
          style={{ userSelect: 'none' }}
        >
          {/* <button className="btn glass btn-xs"> */}
          <div
            className="shadow-xl w-fit rounded-lg bg-base-100 hover:bg-base-200 hover:shadow-md transition duration-200 ease-in-out transform border-secondary border-t-4"
            onClick={handleExpand}
          >
            {message.filesData && message.filesData.length > 0 ? (
              <div className="flex justify-center items-center space-x-4">
                <div style={{ width: '48px', margin: '16px' }} className="m-4 stack">
                  {message.filesData?.map((file, index) =>
                    isDefaultExtensionType(file.extension) ? (
                      <FileIcon
                        extension={file.extension}
                        // {...defaultStyles[file.extension]}
                        key={index}
                      />
                    ) : (
                      <FileIcon extension={file.extension} key={index} /> // Fallback without styles
                    ),
                  )}
                </div>
                <h2 className="text-xl font-bold text-center text-primary">
                  {message.filesData?.length > 1
                    ? message.filesData?.length + ' Files'
                    : message.filesData?.[0].name}
                </h2>

                <div className="justify-end">
                  <button className="mx-4 hover:text-error" onClick={handleDelete}>
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {isExpanded && (
          <dialog id={'message_model_' + message.id} className={'modal modal-open'}>
            <div className={'bg-white modal-box w-fit max-w-[60vw]'}>
              <FileViewer iMessage={message} closeFunction={handleExpand} />
            </div>
          </dialog>
        )}
      </div>
    )
  }

  const getFullChatBubble = () => {
    let content = <></>
    // Hide the chat bubble if the message is hidden
    if (isEditing) {
      content = (
        <div style={{ minWidth: '100%', minHeight: '100%' }}>
          <MonacoEditor
            height="200px"
            language="markdown"
            theme="vs-light"
            value={messageContent[0]}
            onChange={setEditedMessage}
            options={{
              renderValidationDecorations: 'off',
              minimap: { enabled: false, autohide: true },
              selectOnLineNumbers: true,
              roundedSelection: false,
              readOnly: false,
              cursorStyle: 'line',
              automaticLayout: true,
            }}
          />
          <div className="flex justify-end items-center space-x-4 mt-2">
            <Button
              variant={'light'}
              onClick={handleCancelEdit}
              startContent={<XMarkIcon className="h-6 w-6 text-red-500 " />}
            >
              Cancel
            </Button>
            <Button
              variant={'light'}
              onClick={handleSaveEdit}
              startContent={<CheckIcon className="h-6 w-6 text-green-500" />}
            >
              Save
            </Button>
          </div>
        </div>
      )
    } else if (message.isAudio) {
      content = (
        <MicrophoneIcon className="w-6 h-6  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" />
      )
    } else {
      // standard message
      content = (
        <div className="space-x-1 w-full">
          {messageContent.map((item, index) => {
            item = item.replace(/\\\([^()]*\)/g, (match, offset, string) => {
              match = match.replace(/\\\(/g, '') // Remove opening parentheses
              match = match.replace(/\\\)/g, '') // Remove closing parentheses
              return `\`$$${match}$$\``
            })
            item = item.replace(/\\\[.*\\]/g, (match, offset, string) => {
              match = match.replace(/\\\[/g, '') // Remove opening parentheses
              match = match.replace(/\\]/g, '') // Remove closing parentheses
              return `\`\`\`katex \n${match} \n\`\`\``
            })

            return item.startsWith('<img') ? (
              <div dangerouslySetInnerHTML={{ __html: item }} key={index} />
            ) : (
              <MarkdownPreview
                source={item}
                key={index}
                skipHtml={false}
                style={customStyle}
                rehypePlugins={[rehypeSanitize]}
                components={
                  // replace all li[id^="user-content-fn-cid-"] with Citation component
                  {
                    h2: (props) => {
                      return (
                        <h4 className="text-lg font-semibold">{props.children}</h4>
                      )
                    },
                    a: (props) => {
                      if (props.id?.startsWith('user-content-user-content-fnref')) {
                        let fnrefNumber = props.id.replace(
                          'user-content-user-content-fnref-',
                          '',
                        )
                        fnrefNumber = fnrefNumber.replace('cid-', '')
                        const cidNumber = fnrefNumber.split('-').shift()
                        return (
                          <a
                            {...props}
                            id={`user-content-fnref-${fnrefNumber}`}
                            href={`#user-content-fn-$
                            {cidNumber}
                          `}
                          >
                            {cidNumber}
                          </a>
                        )
                      }
                      return <a {...props}>{props.children}</a>
                    },
                    li: (props) => {
                      if (props.id?.startsWith('user-content-user-content-fn')) {
                        let cidNumber = props.id.replace(
                          'user-content-user-content-fn-',
                          '',
                        )
                        cidNumber = cidNumber.replace('cid-', '')
                        const pElement = props.node!.children.find(
                          (child: any) => child.tagName === 'p',
                        ) as any
                        if (!pElement) {
                          return <li id={props.id}>{props.children}</li>
                        }
                        if (pElement.children.length === 0) {
                          return <li id={props.id}>{props.children}</li>
                        }
                        const text = pElement.children.find(
                          (child: any) => child.type === 'text',
                        )!.value as any
                        const backHRefs = pElement.children
                          .filter((child: any) => child.tagName === 'a')
                          .map((a: any) => {
                            if (!a.properties.href) return
                            let fnrefNumber = a.properties.href.replace(
                              '#user-content-fnref-',
                              '',
                            )
                            fnrefNumber = fnrefNumber.replace('cid-', '')
                            return `#user-content-fnref-${fnrefNumber}`
                          })
                        let citation: iCitation | null = null
                        message.citations?.files.find((file: iFile) => {
                          file.citations.find((c) => {
                            if (c.citationId === parseInt(cidNumber)) {
                              c.citation = text
                              citation = c
                            }
                          })
                        })

                        if (!citation) {
                          return <></>
                        }

                        return (
                          <li id={`user-content-fn-${cidNumber}`}>
                            <Citation
                              cid={cidNumber}
                              text={text as string}
                              backHRefs={backHRefs}
                              onClick={() => showCitation(citation)}
                            />
                          </li>
                        )
                      } else {
                        return <li {...props}>{props.children}</li>
                      }
                    },
                    section: (props) => {
                      if (
                        props.className === 'footnotes' &&
                        message.citations?.files.length === 0
                      ) {
                        // Hide footnotes section if there are no citations in current message
                        return <></>
                      }
                      return <section {...props}>{props.children}</section>
                    },
                    code: ({ children = [], className, ...props }) => {
                      if (
                        typeof children === 'string' &&
                        /^\$\$(.*)\$\$/.test(children)
                      ) {
                        const html = katex.renderToString(
                          children.replace(/^\$\$(.*)\$\$/, '$1'),
                          {
                            throwOnError: false,
                          },
                        )
                        return (
                          <code
                            dangerouslySetInnerHTML={{ __html: html }}
                            style={{ background: 'transparent' }}
                          />
                        )
                      }
                      const code =
                        props.node && props.node.children
                          ? getCodeString(props.node.children)
                          : children
                      if (
                        typeof code === 'string' &&
                        typeof className === 'string' &&
                        /^language-katex/.test(className.toLocaleLowerCase())
                      ) {
                        const html = katex.renderToString(code, {
                          throwOnError: false,
                        })
                        return (
                          <code
                            style={{ fontSize: '150%' }}
                            dangerouslySetInnerHTML={{ __html: html }}
                          />
                        )
                      }
                      return <code className={String(className)}>{children}</code>
                    },
                  }
                }
                wrapperElement={{
                  'data-color-mode': 'light',
                }}
              />
            )
          })}
        </div>
      )
    }

    // no tabs for user messages
    if (message.role === 'user') {
      return (
        <>
          <ChatMessageHeader
            session={session!}
            message={message}
            onEditClick={() => setIsEditing(true)}
          ></ChatMessageHeader>
          <div
            className={`chat-bubble relative transition-background max-w-5/6 min-w-48 ${transfered ? '!bg-white' : 'bg-gray-100'}`}
          >
            {content}
            {message.isLoading && (
              <Spinner
                color={'primary'}
                size={'sm'}
                className={'rounded-full bg-white absolute right-[5px] -top-[15%]'}
              />
            )}
          </div>
        </>
      )
    }

    // message from assistant
    return (
      <>
        <ChatMessageHeader
          session={session!}
          message={message}
          disableButtons={{
            delete: session?.isGenerating,
            copy: session?.isGenerating,
            edit: session?.isGenerating,
            regenerate: session?.isGenerating,
            speech: session?.isGenerating,
          }}
          hideButtons={{
            regenerate: !isLastMessage,
          }}
          onEditClick={() => setIsEditing(true)}
        />
        <div
          className={`min-w-[90%] chat-bubble transition-background ${message.error ? 'bg-warning-200' : transfered ? 'bg-white' : 'bg-gray-100'}`}
        >
          <div className="">
            <Tabs
              variant={'light'}
              aria-label="Options"
              selectedKey={selected}
              onSelectionChange={(key) => setSelected(key as string)}
            >
              <Tab key={'conversation'} title="Conversation">
                <>
                  {messageError ? (
                    <span className="text-primary rounded p-2 bg-yellow-100 italic font-bold">
                      {messageError}
                    </span>
                  ) : message.id ? (
                    content
                  ) : (
                    <span className="loading loading-dots loading-sm text-primary"></span>
                  )}
                </>
              </Tab>
              {displayToolCallsTab && (
                <Tab key={'toolCalls'} title="Tool Calls">
                  <div className="mockup-code text-xs mb-2">
                    {!!message.toolCalls &&
                      message.toolCalls.map((func_call, index) => (
                        <div key={index} className="m-2">
                          <ToolCall initialToolCall={func_call}></ToolCall>
                        </div>
                      ))}
                  </div>
                </Tab>
              )}
              {displaySourceFileTab && (
                <Tab key={'sourceFiles'} title="Source Files">
                  {references && (
                    <PdfViewer
                      references={references}
                      acticeReferenceId={activeReferenceId}
                    />
                  )}
                </Tab>
              )}
            </Tabs>
          </div>
          {displayFeedback && (
            <div className="absolute right-3 top-1">
              <FeedbackWidget
                initialFeedback={message.feedback}
                ratingType="stars"
                entityType="message"
                entityId={message.id || ''}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div
      className={`chat ${chatClass} group ${message.id ? '' : 'animate-create'} w-full px-2 md:px-4`}
    >
      {getFullChatBubble()}
    </div>
  )
}

export default ChatMessage
