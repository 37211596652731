import UserService from '../services/userService'

class ProfileImageLoader {
  static instance: ProfileImageLoader
  profileImages: Record<string, { base64Image: string; loading: boolean }> = {}
  imageLoading: Record<string, boolean> = {}
  imageLoadPromises: Record<string, Promise<string>> = {}

  constructor() {}

  public static getInstance(): ProfileImageLoader {
    if (!ProfileImageLoader.instance) {
      ProfileImageLoader.instance = new ProfileImageLoader()
    }
    return ProfileImageLoader.instance
  }

  loadProfileImage(contact: string) {
    const existingImage = this.profileImages[contact]
    if (existingImage && existingImage.base64Image) {
      return Promise.resolve(existingImage.base64Image)
    }

    if (this.imageLoading[contact]) {
      return this.imageLoadPromises[contact]
    }

    this.imageLoading[contact] = true

    this.profileImages[contact] = {
      ...this.profileImages[contact],
      base64Image: '',
      loading: true,
    }

    const imageLoadPromise = UserService.userPictureByMail(contact).then(
      (base64Image) => {
        this.profileImages[contact] = {
          ...this.profileImages[contact],
          base64Image,
          loading: false,
        }
        this.imageLoading[contact] = false
        return base64Image
      },
    )

    this.imageLoadPromises[contact] = imageLoadPromise
    return imageLoadPromise
  }
}

export default ProfileImageLoader.getInstance()
