import axiosClient from '../utils/axiosClient.ts'
import { iKnowledgeContainer } from '../components/sessionControls/knowledgeContainer/iKnowledgeContainer.ts'
import { saveAs } from 'file-saver'
import { iBlobList } from '../components/sessionControls/knowledgeContainer/iBlobUpload.tsx'
import { iDocument } from '../components/sessionControls/files/fileSetting.tsx'
import { iUploadResponse } from '../interfaces/iUploadResponse.ts'

class KnowledgeContainerService {
  private static instance: KnowledgeContainerService

  private constructor() {}

  public static getInstance(): KnowledgeContainerService {
    if (!KnowledgeContainerService.instance) {
      KnowledgeContainerService.instance = new KnowledgeContainerService()
    }

    return KnowledgeContainerService.instance
  }

  public async createKnowledgeContainer(
    blob: iKnowledgeContainer,
  ): Promise<iKnowledgeContainer> {
    const response = await axiosClient.post('/container', blob)
    if (response.status !== 200) {
      throw new Error('Could not create blob')
    }
    return response.data as iKnowledgeContainer
  }

  public async getBlobTree(blobContainer: string): Promise<iBlobList> {
    const response = await axiosClient.get(`/container/${blobContainer}`)
    return response.data as iBlobList
  }

  /**
   * Get all knowledge containers
   * @param baseSettingsId base id of the assistant, optional
   */
  public async getKnowledgeContainerContainerList(
    baseSettingsId: string | null = '',
  ): Promise<iKnowledgeContainer[]> {
    if (!baseSettingsId || baseSettingsId === '') baseSettingsId = 'null'
    const response = await axiosClient.get('/containers/' + baseSettingsId)
    const blobs = response.data as iKnowledgeContainer[]
    return blobs
  }

  public async updateKnowledgeContainerContainer(
    blob: iKnowledgeContainer,
  ): Promise<void> {
    const response = await axiosClient.put(`/container/${blob.id}`, blob)
    if (response.status !== 200) {
      console.log('Could not update blob')
      throw new Error('Could not update blob')
    }
  }

  public async deleteKnowledgeContainerContainer(
    blobContainer: string,
  ): Promise<void> {
    const response = await axiosClient.delete(`/container/${blobContainer}`)
    if (response.status !== 200) {
      throw new Error('Could not delete blob')
    }
  }

  public async downloadImageBlob(blobName: string): Promise<Blob> {
    const response = await axiosClient.get(`/attachment/${blobName}`, {
      responseType: 'blob',
    })
    if (response.status !== 200) {
      throw new Error('Could not download blob')
    }
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    return blob
  }

  public async downloadBlob(blobContainer: string, blobName: string): Promise<void> {
    const response = await axiosClient.get(
      `/container/${blobContainer}/blob/${blobName}`,
      { responseType: 'blob' },
    )
    if (response.status !== 200) {
      throw new Error('Could not download blob')
    }
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    saveAs(blob, blobName)
  }

  public uploadBlob(file: File): Promise<iUploadResponse> {
    const formData = new FormData()
    formData.append('file', file)
    return axiosClient
      .post('/attachments', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data)
  }

  public uploadBlobs(containerId: string, files: File[]): (() => Promise<void>)[] {
    const MAX_BATCH_SIZE = 10 * 1024 * 1024
    let currentBatchSize = 0
    let currentBatch: File[] = []
    const batches: File[][] = []

    // Split files into batches based on MAX_BATCH_SIZE
    for (const file of files) {
      console.log('File:', file)
      if (currentBatchSize + file.size > MAX_BATCH_SIZE) {
        batches.push([...currentBatch]) // copy currentBatch
        currentBatch = [file]
        currentBatchSize = file.size
      } else {
        currentBatch.push(file)
        currentBatchSize += file.size
      }
    }
    if (currentBatch.length > 0) {
      batches.push([...currentBatch]) // copy currentBatch
    }

    console.log('Batches:', batches)

    // Upload each batch
    return batches.map((batch) => () => {
      if (batch.length === 0) {
        return Promise.resolve()
      }

      return new Promise((resolve, reject) => {
        const formData = new FormData()
        console.log('Uploading batch:', batch)
        batch.forEach((file) => {
          // Append each file to the FormData object
          formData.append('files', file, file.webkitRelativePath || file.name)
        })

        // Send a POST request to the server with the FormData object
        return axiosClient
          .post(`/container/${containerId}/batch-upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .catch(reject) // Make sure to reject the Promise if the request fails
          .then(() => resolve())
      })
    })
  }

  public async deleteBlobFile(
    blobContainer: string,
    blobName: string,
  ): Promise<void> {
    console.log(
      `Attempting to delete blob: ${blobName} from container: ${blobContainer}`,
    )

    const response = await axiosClient.delete(
      `/container/${blobContainer}/blob/${blobName}`,
    )
    if (response.status !== 200) {
      throw new Error('Could not delete blob')
    }
  }

  public async getDocumentText(
    file: File,
    filename: string,
    teamId: string,
    pagerange: string | null = null,
    originalAssistantId: string,
    modelId?: string,
  ): Promise<{ document: iDocument; tokenCount: number }> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('filename', filename)
    if (pagerange) {
      formData.append('pageRange', pagerange)
    }
    // TODO Sometimes file is not from type CustomFile but a simple object with name and webrkitRelativePath,
    // TODO this results in an 500 error
    const response = await axiosClient.post(`/document`, formData, {
      params: {
        teamId,
        modelId,
        originalAssistantId,
      },
    })
    if (response.status !== 200) {
      throw new Error('Could not get document text')
    }
    return response.data
  }
}

export default KnowledgeContainerService.getInstance()
