import React, { useCallback, useMemo, useRef, useState } from 'react'
import { ContentItem, ContentItemType } from './iMessage'

import {
  ArrowDownLeftIcon,
  ArrowUpRightIcon,
  CloudArrowDownIcon,
  CpuChipIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PhotoIcon,
  PuzzlePieceIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  SwatchIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Popup } from '../sessionControls/contextPopup'
import { useMessages } from '../../stateManagement/contexts/messageContext'
import { useMain } from '../../stateManagement/contexts/mainContext'
import { AssistantStatus, iSession } from './iSession.ts'
import { SignalRStatus } from '../../enums/signalRStatus.ts'
import Snackbar from '../basic/snackbar/snackbar.tsx'
import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Spinner,
  Tooltip,
} from '@nextui-org/react'
import { CameraIcon } from '@heroicons/react/16/solid'
import useSignalRStore, {
  iAssistantPayload,
} from '../../stateManagement/signalRState.ts'
import useSessionStore from '../../stateManagement/sessionState.ts'
import './chatWindow.css'
import useAssistantModelStore from '../../stateManagement/assistantModelState.ts'
import { AssistantModelAttribute } from '../../interfaces/iAssistantModel.ts'
import BlobService from '../../services/knowledgeContainerService.ts'
import { iUploadResponse } from '../../interfaces/iUploadResponse.ts'
import useTeamStore from '../../stateManagement/teamState.ts'
import { Role } from '../../interfaces/iShared.ts'
import { shallow } from 'zustand/shallow'
import { isDefaultTeam } from '../sessionControls/userManagement/iTeam.tsx'

type ChatMessageWindowMessageBarProps = {
  session: iSession
  assistantPayload: iAssistantPayload | null
  displayType: 'intro' | 'assistant' | 'chat' | null
  changeDisplayType: (type: 'intro' | 'assistant' | 'chat' | null) => void
  afterSendUserMessage: () => void
}
interface Delta {
  type: 'text' | 'image'
  value: string
}

const ChatWindowMessageBar = ({
  session,
  displayType,
  changeDisplayType,
  afterSendUserMessage,
}: ChatMessageWindowMessageBarProps) => {
  const {
    clearSession,
    updateSession,
    allPlugins,
    setInstructions,
    updateSessionInternal,
  } = useSessionStore(
    (state) => ({
      clearSession: state.clearSession,
      updateSession: state.updateSession,
      updateSessionInternal: state.updateSessionInternal,
      allPlugins: state.allPlugins,
      setInstructions: state.setInstructions,
    }),
    shallow,
  )
  const { hasRightTo, selectedTeam } = useTeamStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
      selectedTeam: state.selectedTeam,
    }),
    shallow,
  )

  const { handleSendMessage } = useMessages()
  const { signalRstatus } = useSignalRStore(
    (state) => ({
      signalRstatus: state.signalRstatus,
    }),
    shallow,
  )
  const { setPopup, showSettings, setShowSettings } = useMain()
  const chatWindowBottomAnchor = React.useRef<HTMLDivElement>(null)
  const assistantModels = useAssistantModelStore(
    (state) => state.assistantModels,
    shallow,
  )

  // const [isRecording, setIsRecording] = useState(false)
  const [stopGeneratingInProgress, setStopGeneratingInProgress] = useState(false)
  // const [recorder, setRecorder] = useState<AudioRecorder>(new AudioRecorder())
  const [synthesizeAudio, setSynthesizeAudio] = useState(false)

  // const [content, setContent] = React.useState('');
  const [deltaContent, setDeltaContent] = useState<Delta[]>([])
  const editorRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [resizedToBig, setResizedToBig] = useState(false)

  const defaultDisplayType = useMemo(() => {
    return !session?.messages?.length ? 'intro' : 'chat'
  }, [session])

  const toggleSettingsSection = () => {
    setShowSettings(!showSettings)
  }

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault() // Prevent default Enter behavior
      handleSubmit()
    } else if (e.key === 'Enter' && e.shiftKey) {
      // Insert a new line
      document.execCommand('insertHTML', false, '<br>')
    }
  }

  const dataUrlToFile = (dataUrl: string): File => {
    // convert a data url to a File
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)![1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    const blob = new Blob([u8arr], { type: mime })
    return new File([blob], 'filename', { type: mime })
  }

  const insertImage = (src: string) => {
    const image = new Image()
    image.style.maxWidth = '100px'
    image.style.maxHeight = '100px'
    image.src = src

    if (editorRef.current) {
      const selection = window.getSelection()
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        range.collapse(false) // Collapse the range to the end point
        if (editorRef.current.contains(range.startContainer)) {
          range.insertNode(image) // Insert the image at the collapsed range
        } else {
          editorRef.current.appendChild(image) // Append to the end if no selection
        }
      } else {
        editorRef.current.appendChild(image) // Append to the end if no selection
      }

      // Trigger a reflow and repaint to ensure the image is rendered
      editorRef.current.offsetHeight

      // Adjust cursor position after image insertion
      const newRange = document.createRange()
      newRange.setStartAfter(image)
      newRange.collapse(true)
      selection!.removeAllRanges()
      selection!.addRange(newRange)

      // Scroll to the newly inserted image if needed
      image.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    // Update the deltaContent state with the new image
    setDeltaContent([...deltaContent, { type: 'image', value: src }])
  }

  const hasModelAttribute = useCallback((attribute: AssistantModelAttribute) => {
    const model = assistantModels.find(
      (model) => model.id === session.sessionSettings?.settings?.modelId,
    )
    return model?.attributes.includes(attribute)
  }, [])

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const items = e.clipboardData.items
    for (const item of items) {
      if (item.kind === 'string' && item.type === 'text/plain') {
        item.getAsString((text) => {
          // const sanitizedText = DOMPurify.sanitize(text);
          const selection = window.getSelection()
          if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0)
            range.deleteContents() // Delete the current selection

            const textNode = document.createTextNode(text)
            range.insertNode(textNode)

            // Move the cursor after the inserted text
            range.setStartAfter(textNode)
            range.collapse(true)
            selection.removeAllRanges()
            selection.addRange(range)
          } else if (editorRef.current) {
            // Fallback: append at the end if there's no selection
            editorRef.current.appendChild(document.createTextNode(text))
          }

          editorRef.current!.scrollTop = editorRef.current!.scrollHeight
        })
      } else if (item.kind === 'file' && item.type.startsWith('image/')) {
        const file = item.getAsFile()
        if (file) {
          const reader = new FileReader()
          reader.onload = (event) => {
            insertImage(event.target!.result as string)
          }
          reader.readAsDataURL(file)
        }
      }
    }
    e.preventDefault() // Prevent the default paste behavior
  }

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files[0]) {
      const reader = new FileReader()
      reader.onload = (event) => {
        insertImage(event.target!.result as string)
      }
      reader.readAsDataURL(files[0])

      // Clear the file input to allow the same file to be selected again
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }
  /*  const handleRecording = async () => {
    if (isRecording) {
      setIsRecording(false)
      const audioRecording = await recorder.stopRecording()
      console.log(audioRecording)
      const newDeltaContent: Delta[] = []
      if (editorRef.current) {
        const htmlContent = editorRef.current.innerHTML
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = htmlContent

        // Construct the Delta objects based on the content
        tempDiv.childNodes.forEach((node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            // Text node
            const textValue = node.nodeValue || ''
            if (textValue.trim() !== '') {
              newDeltaContent.push({ type: 'text', value: textValue })
            }
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            // Element node (e.g., images)
            const element = node as HTMLElement
            if (element.tagName === 'IMG' && element.getAttribute('src')) {
              const src = element.getAttribute('src') || ''
              newDeltaContent.push({ type: 'image', value: src })
            }
          }
        })

        setDeltaContent(newDeltaContent)
      }

      const contentItems: ContentItem[] = []
      let index = 0

      newDeltaContent.forEach((op: any) => {
        if (op.type === 'image') {
          const image = op.value
          const base64 = image.split(',')[1]
          const contentItem: ContentItem = {
            type: ContentItemType.Image,
            content: base64,
            index: index,
            mimeType: image.substring(5, image.indexOf(';')),
          }
          contentItems.push(contentItem)
        } else {
          const contentItem: ContentItem = {
            type: ContentItemType.Text,
            content: op.value,
            index: index,
          }
          contentItems.push(contentItem)
        }
        index++
      })

      setDeltaContent([])
      editorRef.current!.innerHTML = ''

      if (contentItems.length > 0) {
        await handleSendMessage(undefined, contentItems, session!, audioRecording!)
        scrollToBottomOfChatWindow()
      } else
        await handleSendMessage(undefined, contentItems, session!, audioRecording!)
    } else {
      setIsRecording(true)
      await recorder.startRecording()
    }
  }*/

  const scrollToBottomOfChatWindow = () => {
    if (chatWindowBottomAnchor.current) {
      chatWindowBottomAnchor.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // Function to handle input events and update content state
  const handleSubmit = async () => {
    // do not show assistant after message
    // since the messages are set AFTER the assistant replies we set this, so the assistant is hidden right away
    changeDisplayType('chat')

    if (editorRef.current) {
      const htmlContent = editorRef.current.innerHTML
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlContent

      // Construct the Delta objects based on the content
      const newDeltaContent: Delta[] = []
      tempDiv.childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          // Text node
          const textValue = node.nodeValue || ''
          if (textValue.trim() !== '') {
            newDeltaContent.push({ type: 'text', value: textValue })
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          // Element node (e.g., images)
          const element = node as HTMLElement
          if (element.tagName === 'IMG' && element.getAttribute('src')) {
            const src = element.getAttribute('src') || ''
            newDeltaContent.push({ type: 'image', value: src })
          }
        }
      })

      setDeltaContent(newDeltaContent)
      editorRef.current.innerHTML = ''

      const contentPromises = newDeltaContent.map(
        (delta, index) =>
          new Promise<ContentItem>((resolve) => {
            if (delta.type === 'image') {
              const file = dataUrlToFile(delta.value!)
              BlobService.uploadBlob(file).then(
                (uploadResponse: iUploadResponse) => {
                  resolve({
                    type: ContentItemType.Image,
                    content: uploadResponse.id,
                    index: index,
                    mimeType: uploadResponse.mimeType,
                  })
                },
              )
            } else {
              resolve({
                type: ContentItemType.Text,
                content: delta.value ?? '',
                index: index,
              })
            }
          }),
      )

      Promise.all(contentPromises).then((contentItems) => {
        if (contentItems.length > 0) {
          handleSendMessage(undefined, contentItems, session!, null).then(() => {
            afterSendUserMessage()
            scrollToBottomOfChatWindow()
          })
        }
      })
    }
  }

  const handleStopGenerating = async () => {
    if (session) {
      session.assistantStatus = AssistantStatus.Cancelled
      setStopGeneratingInProgress(true)
      updateSession(session).then(() => {
        session.isGenerating = false
        updateSessionInternal(session)
        setStopGeneratingInProgress(false)
      })
    }
  }

  const handleClearSession = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setPopup(Popup.SessionFeedback, {
      onClose: async () => {
        await clearSession(session!)
        // we need to add instructions again, because they are deleted when clearing the session
        if (session!.sessionSettings!.instruction)
          setInstructions(session!, session!.sessionSettings!.instruction)
      },
    })
  }

  const handleResize = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    if (resizedToBig) {
      editorRef?.current?.classList.remove('max-sm:h-28', 'h-48')
      setResizedToBig(false)
    } else {
      setResizedToBig(true)
      editorRef?.current?.classList.add('max-sm:h-28', 'h-48')
    }
  }

  const handleSynthesizeAudio = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setSynthesizeAudio(!synthesizeAudio)
    session!.sessionSettings!.settings!.synthesizeAudio = !synthesizeAudio
    updateSession(session!)
  }

  const resizeButton = () => {
    const Icon = resizedToBig ? ArrowDownLeftIcon : ArrowUpRightIcon
    const tooltip = resizedToBig ? 'Shrink chat window' : 'Enlarge chat window'
    return (
      <Tooltip content={tooltip} color={'primary'} className={'max-w-96'}>
        <button
          className={`btn-glass px-2 py-2 max-h-12 rounded-lg ${resizedToBig ? 'hover:-translate-x-1  hover:translate-y-1' : 'hover:translate-x-1 hover:-translate-y-1'} hover:scale-110 duration-300 top-0 right-0 absolute`}
          key={'arrowUpRightButton_' + session?.id}
          onClick={handleResize}
        >
          <Icon
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          />
        </button>
      </Tooltip>
    )
  }

  return (
    <div className={'sticky bottom-0 z-10 '}>
      <div className={'z-10 relative'}>
        {/* Stop button */}
        {signalRstatus !== SignalRStatus.Connected ? (
          <div className={'flex mt-3 justify-center'}>
            <Snackbar relative isOpen={true} className={'alert-warning'}>
              <Spinner></Spinner>
              <div>{signalRstatus} ...</div>
            </Snackbar>
          </div>
        ) : (
          <div className={'grid grid-cols-3 mt-3 content-end'}>
            <div className={'self-end'}>
              <AvatarGroup isBordered color="primary">
                {session.sessionSettings?.pluginIds?.map((plugin, index) => {
                  const pluginObject = allPlugins.find((p) => p.id === plugin)
                  return (
                    <Tooltip
                      key={index}
                      content={pluginObject?.name}
                      color={'primary'}
                      className={'max-w-96'}
                    >
                      <Avatar
                        src={pluginObject?.image}
                        showFallback
                        fallback={
                          <CameraIcon className="w-6 h-6 text-default-500" />
                        }
                      />
                    </Tooltip>
                  )
                })}
              </AvatarGroup>
            </div>
            <div className={'flex justify-center m-1'}>
              {session?.isGenerating ? (
                <Button
                  className={
                    'bg-white px-2 py-2 max-h-12 border-2 border-rose-400 rounded-lg hover:-translate-y-1 hover:scale-110 duration-300'
                  }
                  isLoading={stopGeneratingInProgress}
                  isDisabled={stopGeneratingInProgress}
                  key={'stopButton_' + session.id}
                  onClick={handleStopGenerating}
                >
                  <div className={'flex'}>
                    Stop Generating
                    <NoSymbolIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-red-500 ml-2"
                      key={'stopIcon_' + session.id}
                    />
                  </div>
                </Button>
              ) : (
                <></>
                /*              <>
                  {isRecording && (
                    <span className="flex loading loading-bars loading-xs bg-primary mr-1 rotate-180"></span>
                  )}
                  <button
                    className={`btn btn-neutral btn-circle max-h-12 border-2 ${isRecording ? 'text-red-700 bg-transparent -translate-y-1 scale-110' : 'border-primary'} hover:-translate-y-1 hover:scale-110 duration-300 hover:text-red-700 hover:bg-white`}
                    key={'recordButton_' + session.id}
                    onClick={() => handleRecording()}
                  >
                    <div className={'flex'}>
                      <MicrophoneIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        key={'stopIcon_' + session.id}
                      />
                    </div>
                  </button>
                  {isRecording && (
                    <span className="flex loading loading-bars loading-xs bg-primary ml-1"></span>
                  )}
                </>*/
              )}
            </div>
            <div></div>
          </div>
        )}
      </div>
      <div className={'bg-base-100 chat-message-window'}>
        {/* Message window */}
        <form
          className={'w-full mt-2 relative'}
          onSubmit={() => handleSubmit()}
          key={'submit_' + session.id}
        >
          <div
            className={'flex items-center space-x-2 w-full relative'}
            key={'div_' + session.id}
          >
            <div className="flex-grow">
              <div
                ref={editorRef}
                contentEditable={
                  !session.isGenerating && signalRstatus === SignalRStatus.Connected
                }
                onPaste={handlePaste}
                onKeyDown={async (e) => await handleKeyDown(e)}
                className={`transition-all textarea max-sm:h-10 h-24 rounded-lg shadow-lg bg-white overflow-auto w-full resize-none ${session.isGenerating || signalRstatus !== SignalRStatus.Connected ? 'cursor-not-allowed bg-gray-200' : 'cursor-text'}`}
                data-placeholder="Type your message here..."
              ></div>
              <input
                type="file"
                accept="image/*"
                onChange={handleAddImage}
                ref={fileInputRef}
                style={{ display: 'none' }} // Hide the input, but keep it in the DOM
              />
            </div>
            {resizeButton()}
          </div>
        </form>
        {/* Buttons under Message Window */}
        <div className="flex items-start py-2">
          <div>
            <Tooltip content={'Clear chat'} color={'primary'} className={'max-w-96'}>
              <Button
                type="submit"
                data-guide-id="clearChat"
                isIconOnly
                variant={'light'}
                onClick={handleClearSession}
                key={'trashButton_' + session.id}
              >
                <TrashIcon
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 "
                  key={'trashIcon_' + session.id}
                />
              </Button>
            </Tooltip>
            {(hasRightTo(Role.GaiaUser) || isDefaultTeam(selectedTeam!)) && (
              <>
                <Tooltip
                  content={'Include Files'}
                  color={'primary'}
                  className={'max-w-96'}
                >
                  <Button
                    type="submit"
                    data-guide-id="includeFiles"
                    isIconOnly
                    variant={'light'}
                    onClick={() => setPopup(Popup.Files)}
                  >
                    <PaperClipIcon
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 "
                    />
                  </Button>
                </Tooltip>
                {hasModelAttribute(AssistantModelAttribute.imageProcessing) && (
                  <Tooltip
                    content={'Add Image'}
                    color={'primary'}
                    className={'max-w-96'}
                  >
                    <Button
                      onClick={() => fileInputRef.current?.click()}
                      data-guide-id="includeImage"
                      type="submit"
                      isIconOnly
                      variant={'light'}
                    >
                      <PhotoIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 "
                      />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip
                  content={'Show the initial assistant selector'}
                  color={'primary'}
                  className={'max-w-96'}
                >
                  <Button
                    data-guide-id="loadPredefinedSettings"
                    onClick={() =>
                      changeDisplayType(
                        displayType === 'assistant'
                          ? defaultDisplayType
                          : 'assistant',
                      )
                    }
                    className={`${displayType === 'assistant' ? 'btn-active' : 'btn-glass'}`}
                    isIconOnly
                    variant={`${displayType === 'assistant' ? 'shadow' : 'light'}`}
                  >
                    <SwatchIcon
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    />
                  </Button>
                </Tooltip>
                <Badge
                  shape="circle"
                  isInvisible={
                    !session?.sessionSettings?.knowledgeContainerIds?.length
                  }
                  content={
                    session?.sessionSettings?.knowledgeContainerIds?.length || 0
                  }
                  color="primary"
                >
                  <Tooltip
                    content={'Include Knowledge Container'}
                    color={'primary'}
                    className={'max-w-96'}
                  >
                    <Button
                      type="submit"
                      data-guide-id="includeKnowledge"
                      isIconOnly
                      variant={'light'}
                      onClick={() => setPopup(Popup.ContainerSelector)}
                    >
                      <CloudArrowDownIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 "
                      />
                    </Button>
                  </Tooltip>
                </Badge>
                <Badge
                  shape="circle"
                  isInvisible={!session?.sessionSettings?.pluginIds?.length}
                  content={session?.sessionSettings?.pluginIds?.length || 0}
                  color="primary"
                >
                  <Tooltip
                    content={'Activate Plugins'}
                    color={'primary'}
                    className={'max-w-96'}
                  >
                    <Button
                      isIconOnly
                      variant={'light'}
                      type="submit"
                      data-guide-id="includePlugins"
                      onClick={() => setPopup(Popup.PluginSelector)}
                    >
                      <PuzzlePieceIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 "
                      />
                    </Button>
                  </Tooltip>
                </Badge>
              </>
            )}
          </div>

          <div className="flex items-start ml-auto ">
            <Tooltip
              content={'Synthesize Audio'}
              color={'primary'}
              className={'max-w-96'}
            >
              <Button
                isIconOnly
                variant={'light'}
                type="submit"
                data-guide-id="synthesizeAudio"
                onClick={handleSynthesizeAudio}
              >
                {synthesizeAudio ? (
                  <SpeakerWaveIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 "
                  />
                ) : (
                  <SpeakerXMarkIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 "
                  />
                )}
              </Button>
            </Tooltip>

            {(hasRightTo(Role.GaiaUser) || isDefaultTeam(selectedTeam!)) && (
              <>
                {/*<div className={"tooltip"}*/}
                {/*    data-tip={"Load predefined GAIA settings for the current session"}>*/}
                {/*    <button*/}
                {/*        data-guide-id="loadPredefinedSettings"*/}
                {/*        onClick={() => setPopup(Popup.AssistantSelector)}*/}
                {/*        className={"btn-glass px-2 max-h-12 rounded-lg hover:-translate-y-1 hover:scale-110 duration-300"}*/}
                {/*    >*/}
                {/*        <SwatchIcon className="h-5 w-5" fill="none"*/}
                {/*            viewBox="0 0 24 24" stroke="currentColor" />*/}
                {/*    </button>*/}
                {/*</div>*/}
                <Tooltip
                  content={'Customize GAIA behaviour'}
                  color={'primary'}
                  className={'max-w-96'}
                >
                  <Button
                    isIconOnly
                    variant={'light'}
                    data-guide-id="customize"
                    onClick={toggleSettingsSection}
                  >
                    <CpuChipIcon
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    />
                  </Button>
                </Tooltip>
              </>
            )}
            <Tooltip
              content={'Send prompt'}
              color={'primary'}
              className={'max-w-96'}
            >
              <Button
                data-guide-id="sendPrompt"
                type="submit"
                isIconOnly
                variant={'light'}
                key={'paperAirplaneButton_' + session.id}
                onClick={async () => handleSubmit()}
              >
                <PaperAirplaneIcon
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                  key={'paperAirplaneIcon_' + session.id}
                />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatWindowMessageBar
