import React, { useState } from 'react'

import './sessionGroups.css'
import { iSessionGroup } from './interfaces/iSessionGroup.ts'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react'
type DialogSendMessageToGroupProps = React.HTMLAttributes<HTMLDialogElement> & {
  open: boolean
  group: iSessionGroup
  onClose?: () => void
}
const DialogFolderOptions = ({
  group,
  open,
  onClose,
}: DialogSendMessageToGroupProps) => {
  const [, setMessage] = useState<string>('')

  const handleSendMessageToGroup = () => {
    // const groupSessions = Array.from(sessions).filter((session) => session.groupId === groupId);
    // groupSessions.forEach(async (session) => {
    //   await handleSendMessage(message, session, null);
    //   // session. = true;
    // });
    // setMessage("");
    // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // // @ts-ignore
    // document.getElementById(SendMessageToGroupId)!.close();
  }

  return (
    <>
      <Modal
        scrollBehavior={'inside'}
        backdrop={'blur'}
        isOpen={open}
        onClose={onClose}
        classNames={{
          base: '!max-w-[100vw] w-fit',
        }}
      >
        <ModalContent>
          <ModalHeader>
            <h3 className="font-bold text-lg">Options</h3>
          </ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant={'flat'}>
              Close
            </Button>
            <Button color={'primary'} onClick={() => handleSendMessageToGroup()}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DialogFolderOptions
