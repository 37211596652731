import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import ProfileImage from '../../utils/profileImage/profileImage'
import Description from '../../utils/description'
import ClipboardCopyButton from '../../utils/copyToClipboard'
import { useMain } from '../../../stateManagement/contexts/mainContext'
import { Popup } from '../contextPopup'
import { iAssistant } from './iAssistantTypes'
import { Button, Tooltip } from '@nextui-org/react'
import useSessionStore from '../../../stateManagement/sessionState'
import useAssistantModelStore from '../../../stateManagement/assistantModelState'
import { IAssistantModel } from '../../../interfaces/iAssistantModel'
import { getUserKey } from '../../login/authentication'
import useAssistantStore from '../../../stateManagement/assistantState'
import { Role } from '../../../interfaces/iShared'
import { shallow } from 'zustand/shallow'

interface iAssistantCardProps {
  preSetting: iAssistant
  handleApply: (preSetting: iAssistant) => void
  disabledReason?: 'restricted-model' | 'restricted-assistant'
}

const AssistantCard: React.FC<iAssistantCardProps> = ({
  preSetting,
  handleApply,
  disabledReason,
}) => {
  const { setPopup } = useMain()
  const { activeSession, sessions } = useSessionStore(
    (state) => ({
      sessions: state.sessions,
      activeSession: state.activeSession,
    }),
    shallow,
  )

  const { hasRightTo } = useAssistantStore(
    (state) => ({
      hasRightTo: state.hasRightTo,
    }),
    shallow,
  )

  const [applied, setApplied] = useState(false)

  const apply = useCallback(() => {
    if (applied && activeSession?.id === preSetting.id) return
    setApplied(true)
    handleApply(preSetting)
  }, [applied, activeSession, preSetting, handleApply])

  const isOwner = useMemo(
    () =>
      preSetting.ownerId === getUserKey() ||
      hasRightTo(Role.Manager, preSetting.id!),
    [preSetting, hasRightTo],
  )

  useEffect(() => {
    const session = sessions.find((s) => s.id === activeSession?.id)
    if (session) {
      setApplied(session.sessionSettings?.baseSessionSettingsId === preSetting.id)
    }
  }, [activeSession, sessions, preSetting])

  const disableReasonText = useMemo(() => {
    if (disabledReason === 'restricted-assistant')
      return 'The Assistant is not allowed in your team.'
    if (disabledReason === 'restricted-model')
      return 'The Assistant uses a model that has not been approved by your team.'
  }, [disabledReason])
  return (
    <div key={`${preSetting.id}_ac`} className="p-1 tracking-wide mt-2">
      <div className="card m-1 w-64 min-h-96 glass hover:shadow-lg hover:scale-105 transition-transform duration-500 ease-in-out max-w-64">
        <div className="card-body">
          <div className="flex justify-between">
            <ProfileImage contact={preSetting.ownerEmail!} width={8} />
            <div className="flex">
              <Tooltip content="Inspect" color="primary" className="max-w-96">
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => setPopup(Popup.AssistantOnlyInspect, preSetting)}
                >
                  <MagnifyingGlassIcon className="w-5 h-5" />
                </Button>
              </Tooltip>
              {isOwner && (
                <Tooltip content="Edit" color="primary" className="max-w-96">
                  <Button
                    isIconOnly
                    variant="light"
                    onClick={() => setPopup(Popup.AssistantEdit, preSetting)}
                  >
                    <PencilSquareIcon className="w-5 h-5" />
                  </Button>
                </Tooltip>
              )}
              <ClipboardCopyButton
                textToCopy={preSetting.id!}
                tooltip="Assistant ID"
              />
            </div>
          </div>
          <h2 className="card-title">{preSetting.title}</h2>
          <Description description={preSetting.description!} />
          <div className="card-actions justify-end items-center">
            <Button
              variant={applied ? undefined : 'bordered'}
              color={applied ? 'primary' : undefined}
              isDisabled={!!disabledReason}
              onClick={apply}
            >
              {applied ? 'Applied' : 'Apply'}
            </Button>
            {disabledReason && (
              <Tooltip
                content={disableReasonText}
                color="primary"
                className="max-w-96"
              >
                <InformationCircleIcon
                  fill="none"
                  stroke="currentColor"
                  color="primary"
                  className="w-6 h-6"
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssistantCard
