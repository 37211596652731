import React from 'react'
import './sessions.css'
import ProfileImage from './profileImage/profileImage.tsx'
import { useMain } from '../../stateManagement/contexts/mainContext.tsx'
import { Popup } from '../sessionControls/contextPopup.tsx'
import { deleteTokens, getUser, logoutURL } from '../login/authentication.tsx'
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react'
import {
  ArrowRightEndOnRectangleIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/24/outline'

const AccountMenu = () => {
  const { setPopup } = useMain()

  const logout = () => {
    deleteTokens()
    document.location.href = logoutURL
  }

  const { email, displayName, gaiaRole } = getUser()
  return (
    <Dropdown>
      <DropdownTrigger className={'h-18'}>
        <Button variant={'light'} className={'w-full justify-start py-4 truncate'}>
          <ProfileImage width={8} contact={email} />
          <div className="flex flex-col items-start truncate">
            <span className="text-small bold truncate  max-w-full">
              {displayName}
            </span>
            <span className="text-tiny align-start text-default-400 max-w-full">
              {gaiaRole}
            </span>
            <span className="text-tiny text-default-400 truncate max-w-full">
              {email}
            </span>
          </div>
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions">
        <DropdownItem
          textValue={'logout'}
          startContent={<ArrowRightEndOnRectangleIcon className={'w-5 h-5'} />}
          onClick={logout}
        >
          <a>Logout</a>
        </DropdownItem>
        <DropdownItem
          textValue={'Personal access tokens'}
          startContent={<ShieldExclamationIcon className={'w-5 h-5'} />}
          onClick={() => setPopup(Popup.PatKeys)}
        >
          <a>Personal access tokens</a>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default AccountMenu
