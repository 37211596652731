import { create } from 'zustand'
import { iUser } from '../components/login/iUser.ts'
import userService from '../services/userService.ts'

interface IUserProfileState {
  isLoading: boolean
  userProfile: iUser | null
  setIsLoading: (isLoading: boolean) => void
  setUserProfile: (userProfile: iUser) => void
  acceptedToUVersion: (resourceId: string) => Promise<void>
  loadUserProfile: () => Promise<iUser>
  updateUserProfileChangelog: (changelogDate: string) => Promise<void>
}

const useUserProfileStore = create<IUserProfileState>((set, getState) => ({
  isLoading: false,
  userProfile: null,
  setUserProfile: (userProfile: iUser) => {
    set(() => ({ userProfile }))
  },

  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  loadUserProfile: async () => {
    const user = await userService.getUser()
    getState().setUserProfile(user)
    return user
  },
  acceptedToUVersion: async (resourceId: string) => {
    const date = new Date().toISOString()
    await userService.acceptedToUVersion(date, resourceId)
    const tmpUserProfile = getState().userProfile!
    tmpUserProfile.acceptedToUVersion = {
      ...tmpUserProfile.acceptedToUVersion,
      [resourceId]: date,
    }
    getState().setUserProfile(tmpUserProfile)
  },
  updateUserProfileChangelog: async (changelogDate: string) => {
    const tmpUserProfile = getState().userProfile!
    tmpUserProfile.changelogdate = changelogDate
    getState().setUserProfile(tmpUserProfile)
    await userService.updateUserProfileChangelog(changelogDate)
  },
}))

export default useUserProfileStore
