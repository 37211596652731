import React, { useEffect, useState, useCallback } from 'react'
import sessionSettingsService from '../../services/sessionSettingsService'
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { iAssistant } from '../sessionControls/assistants/iAssistantTypes'
import { Button, Tooltip } from '@nextui-org/react'

interface iItemSelectionProps {
  selectedItemId: string[]
  setSelectedItemId: (id: string[]) => void
}

const AssistantSelection: React.FC<iItemSelectionProps> = ({
  selectedItemId,
  setSelectedItemId,
}) => {
  const [search, setSearch] = useState('')
  const [selectedItems, setSelectedItems] = useState<iAssistant[]>([])
  const [items, setItems] = useState<iAssistant[]>([])
  const [allItems, setAllItems] = useState<iAssistant[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    sessionSettingsService
      .getBlueprintAssistants()
      .then((response) => {
        setAllItems(response)
        if (selectedItemId) {
          const items = response.filter((item) => selectedItemId.includes(item.id!))
          setSelectedItems(items)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [selectedItemId])

  const setItem = useCallback(
    (item: iAssistant) => {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item])
      setSelectedItemId([...selectedItemId, item.id!])
    },
    [selectedItemId, setSelectedItemId],
  )

  const deleteItemFromList = useCallback(
    (item: iAssistant) => {
      setSelectedItems(selectedItems.filter((i) => i.id !== item.id))
      setSelectedItemId(selectedItemId.filter((i) => i !== item.id))
    },
    [setSelectedItemId],
  )

  const searchItems = useCallback(
    (query: string) => {
      const filterItems = (i: iAssistant[]) => {
        return i.filter(
          (user) =>
            user.title?.toLowerCase().includes(query.toLowerCase()) ||
            user.description?.toLowerCase().includes(query.toLowerCase()),
        )
      }
      setItems(filterItems(allItems))
    },
    [allItems],
  )

  return (
    <div>
      {loading ? (
        <div className="grid grid-cols-1 h-20">
          <span className="loading loading-spinner loading-lg place-self-center"></span>
        </div>
      ) : (
        <div className="flex flex-col border-2 border-gray-300 rounded-md p-4 w-full min-w-fit">
          <div className="max-h-40 mb-2">
            {selectedItems.map((item) => (
              <div key={item.id} className="flex items-center mb-3">
                <div className="join flex w-full justify-between">
                  <div className="w-full flex items-center">
                    <div className="w-fit pr-0">{item.title}</div>
                    <Tooltip
                      content={item.description}
                      color="primary"
                      className="max-w-96"
                    >
                      <InformationCircleIcon
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.0}
                        stroke="currentColor"
                        className="w-4 h-4 ml-1"
                      />
                    </Tooltip>
                  </div>
                  <div className="shrink">
                    <Button
                      variant="light"
                      isIconOnly
                      className="hover:text-error"
                      onClick={() => deleteItemFromList(item)}
                    >
                      <TrashIcon className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <input
            type="text"
            placeholder="Add Assistants"
            className="input input-bordered input-sm w-full mb-4"
            value={search}
            onClick={() => searchItems(search)}
            onChange={(e) => {
              setSearch(e.target.value)
              searchItems(e.target.value)
            }}
          />
          {items.length > 0 && (
            <div className="max-h-40 overflow-y-auto rounded-md p-4 bg-gray-100 min-w-fit">
              {items
                .filter((item) => !selectedItems.some((i) => i.id === item.id))
                .map((item) => (
                  <div
                    key={item.id}
                    className="flex justify-between items-center bg-gray-100 rounded-md"
                  >
                    <button
                      className="btn glass btn-sm"
                      onMouseDown={() => setItem(item)}
                    >
                      {item.title}
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AssistantSelection
