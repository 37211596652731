import React, { useEffect, useState } from 'react'
import {
  HttpLocation,
  iHttpConstant,
  httpLocations,
  iHttpConstantItem,
} from './model'
import { TrashIcon } from '@heroicons/react/24/outline'
import { FieldsEditor } from '../../../utils/inputfields/fieldEditors'
import {
  InputField,
  TextField,
  SelectField,
  FlagField,
} from '../../../utils/inputfields/model'

const tooltipSecret =
  'Use this for sensitive value such as passwords or API keys. Secret values will be hidden in the UI.'
const tooltipTemplate =
  'If checked, the value will be treated as a template. Patterns like {paramter_name} or {constant_name} will be replaced with the actual value of the parameter or constant with the respective name.'

interface iHttpConstantEditorProps {
  item: iHttpConstantItem
  index: number
  onNameChange: (index: number, newName: string) => void
  onValueChange: (index: number, constant: iHttpConstant) => void
  onRemove(index: number): void
}

function HttpConstantEditor({
  item,
  index,
  onNameChange,
  onValueChange,
  onRemove,
}: iHttpConstantEditorProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [fields, setFields] = useState<InputField[]>([
    new TextField({ key: 'value', name: 'Value', value: item.constant.value }),
    new SelectField({
      key: 'in',
      name: 'In',
      value: { key: item.constant.in },
      options: (httpLocations as unknown as string[]).map((location) => ({
        key: location,
      })),
    }),
    new FlagField({
      key: 'isSecret',
      name: 'Is secret',
      value: item.constant.isSecret,
      info: tooltipSecret,
    }),
    new FlagField({
      key: 'isTemplate',
      name: 'Is template',
      value: item.constant.isTemplate,
      info: tooltipTemplate,
    }),
  ])

  useEffect(() => {
    if (!item.name) {
      setIsExpanded(true)
    }
  }, [])

  useEffect(() => {
    onValueChange(index, {
      value: (fields[0] as TextField).value,
      in: (fields[1] as SelectField).value.key as HttpLocation,
      isSecret: (fields[2] as FlagField).value,
      isTemplate: (fields[3] as FlagField).value,
    })
  }, [fields])

  return (
    <>
      <div className="p-2 bg-gray-200 rounded-lg mb-2">
        <details open={isExpanded}>
          <summary>
            <div className={'inline ml-1'}>
              <input
                type="text"
                className="input input-bordered w-10/12"
                value={item.name}
                placeholder="Name"
                onChange={(e) => onNameChange(index, e.target.value)}
                required
              />
              <TrashIcon
                className="w-6 h-6 inline-block ml-4 cursor-pointer"
                onClick={() => onRemove(index)}
              />
            </div>
          </summary>
          <div className="w-10/12">
            <FieldsEditor fields={fields} onChange={setFields}></FieldsEditor>
          </div>
        </details>
      </div>
    </>
  )
}
export default HttpConstantEditor
