import React, { useEffect, useState } from 'react'
import { ExecutionEnvironment, iPlugin } from './iPlugin'
import { iHttpTemplate } from './httpTemplates/model'
import { ShareType } from '../../../interfaces/iItem'
import MembersSelection from '../../utils/memberSelection'
import { iShared } from '../../../interfaces/iShared'
import pluginService from '../../../services/pluginService.ts'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { deepCopy } from '../../utils/deepCopy.ts'
import HttpTemplateEditor from './httpTemplates/httpTemplateEditor'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ValidationError } from '../../utils/iValidation.tsx'
import { ModalBody, ModalFooter, ModalHeader, Tooltip } from '@nextui-org/react'
import { getUserKey } from '../../login/authentication.tsx'

interface iPluginFormProps {
  currentPlugin?: iPlugin // If a plugin is passed, we're in edit mode. Otherwise, it's create mode.
  closeFunction: () => void
}

const tooltipShareWith =
  'Limited: Only selected members can access this presetting. Public: Everyone can access this presetting. People enlisted can also modify this presetting. '

function PluginCreateEdit({ currentPlugin, closeFunction }: iPluginFormProps) {
  const [newPlugin, setNewPlugin] = useState<iPlugin>({
    id: currentPlugin?.id || undefined,
    selected: currentPlugin?.selected || false,
    name: currentPlugin?.name || '',
    description: currentPlugin?.description || '',
    functionName: currentPlugin?.functionName || '',
    citationRequired: currentPlugin?.citationRequired || false,
    image: currentPlugin?.image || '',
    sharedType: currentPlugin?.sharedType || ShareType.None,
    shared: currentPlugin?.shared || [],
    executionEnvironment: ExecutionEnvironment.Local,
    settings: currentPlugin?.settings || {},
    ownerId: getUserKey(),
    fromBaseAssistantInherited: false,
    ...currentPlugin,
  })
  const [sharedLimited, setSharedLimited] = useState<iShared[]>([])
  const [sharedPublic, setSharedPublic] = useState<iShared[]>([])
  const [isHttpPlugin, setIsHttpPlugin] = useState<boolean>(false)
  const [httpTemplate, setHttpTemplate] = useState<iHttpTemplate>(
    currentPlugin?.httpTemplate || {
      url: '',
      method: 'auto',
      constants: {},
      parameters: {},
    },
  )
  const [shared, setShared] = useState<iShared[]>([])
  const [definitionStyle, setDefinitionStyle] = useState<
    null | 'default' | 'templateString'
  >(null)
  const [isLoading, setIsLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])

  const editingPlugin = currentPlugin !== undefined

  useEffect(() => {
    if (isHttpPlugin) {
      setNewPlugin({
        ...newPlugin,
        httpTemplate,
        executionEnvironment: ExecutionEnvironment.ExternalHttp,
      })
    } else {
      setNewPlugin({
        ...newPlugin,
        httpTemplate: undefined,
        executionEnvironment: ExecutionEnvironment.Local,
      })
    }
  }, [isHttpPlugin])

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = +event.target.value as unknown as ShareType
    setNewPlugin({ ...newPlugin, sharedType: newValue })
    switch (newValue) {
      case ShareType.Limited:
        setShared(deepCopy(sharedLimited))
        break
      case ShareType.Public:
        setShared(deepCopy(sharedPublic))
        break
    }
  }

  const getTitle = () => {
    if (editingPlugin) {
      return 'Edit plugin'
    } else {
      return 'Create plugin'
    }
  }

  const handleErrorResponse = (error: any) => {
    if (error.response && error.response.status === 422) {
      if (error.response.data) {
        const errors = error.response.data.errors as ValidationError[]
        setValidationErrors(errors)
      } else {
        setValidationErrors([{ message: 'Unknown validation error', field: '' }])
      }
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (
      newPlugin.name &&
      newPlugin.description &&
      newPlugin.functionName &&
      newPlugin.image
    ) {
      setIsLoading(true)
      if (editingPlugin) {
        pluginService
          .updatePlugin(newPlugin)
          .then(closeFunction)
          .catch(handleErrorResponse)
          .finally(() => setIsLoading(false))
      } else {
        pluginService
          .createPlugin(newPlugin)
          .then(closeFunction)
          .catch(handleErrorResponse)
          .finally(() => setIsLoading(false))
      }
    } else {
      closeFunction()
    }
  }

  useEffect(() => {
    if (currentPlugin) {
      setNewPlugin(currentPlugin)
      setIsHttpPlugin(!!currentPlugin.httpTemplate)
      if (!currentPlugin.shared) {
        setShared([])
        setNewPlugin({ ...newPlugin, shared: [] })
      } else {
        setShared(currentPlugin.shared!)
        setNewPlugin({ ...newPlugin, shared: currentPlugin.shared! })
      }
      console.log(currentPlugin)
    }
  }, [])

  useEffect(() => {
    setNewPlugin({ ...newPlugin, shared: shared })
  }, [shared])

  useEffect(() => {
    console.log({ newPlugin })
  }, [newPlugin])

  useEffect(() => {
    if (definitionStyle === 'default') {
      setNewPlugin({ ...newPlugin, httpTemplate: httpTemplate })
    }
    if (definitionStyle === 'templateString') {
      setNewPlugin({ ...newPlugin, httpTemplateString: '<HTTP request template>' })
    }
  }, [definitionStyle])

  return (
    <>
      <ModalHeader>
        <h1 className={'text-xl font-semibold mb-2'}>{getTitle()}</h1>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit} className={'min-w-[350px]'}>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Name</span>
            </label>
            <input
              id="pluginName"
              type="text"
              className="input input-bordered w-full"
              value={newPlugin.name}
              onChange={(e) => setNewPlugin({ ...newPlugin, name: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Function name</span>
            </label>
            <input
              id="pluginFunctionName"
              type="text"
              className="input input-bordered w-full"
              value={newPlugin.functionName}
              onChange={(e) =>
                setNewPlugin({ ...newPlugin, functionName: e.target.value })
              }
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Image url</span>
            </label>
            <input
              id="pluginImage"
              type="text"
              className="input input-bordered w-full"
              value={newPlugin.image}
              onChange={(e) => setNewPlugin({ ...newPlugin, image: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Description</span>
            </label>
            <textarea
              id="pluginDescription"
              className="textarea textarea-bordered w-full"
              value={newPlugin.description}
              onChange={(e) =>
                setNewPlugin({ ...newPlugin, description: e.target.value })
              }
              rows={4}
              required
            />
          </div>
          <div className="mb-4">
            <label className="label ">
              <span className="label-text">Citation required</span>
            </label>
            <input
              type="checkbox"
              className="checkbox checkbox-sm"
              checked={newPlugin.citationRequired}
              onChange={(e) =>
                setNewPlugin({ ...newPlugin, citationRequired: e.target.checked })
              }
            />
          </div>
          <div className={'w-full '}>
            <label className="label">
              <span className="label-text flex">
                Share with
                <Tooltip
                  content={tooltipShareWith}
                  color={'primary'}
                  className={'max-w-96'}
                >
                  <InformationCircleIcon
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.0}
                    stroke="currentColor"
                    className="w-4 h-4 ml-1"
                  />
                </Tooltip>
              </span>
              <select
                className="select select-bordered w-1/2 max-w-xs"
                value={newPlugin.sharedType}
                onChange={onSelectChange}
              >
                <option value={ShareType.None}>None</option>
                <option value={ShareType.Public}>Public</option>
                <option value={ShareType.Limited}>Limited</option>
              </select>
            </label>
          </div>

          {newPlugin.sharedType !== ShareType.None && (
            <>
              <label className="label ">
                <span className="label-text">Members</span>
              </label>
              <MembersSelection
                shared={shared}
                ownerId={newPlugin.ownerId}
                setShared={setShared}
                writeOnly={newPlugin.sharedType === ShareType.Public}
                roles={newPlugin!.roles}
              />
            </>
          )}

          {
            <div>
              <div className="text-base mb-2 mt-2 flex">
                <input
                  type="checkbox"
                  className="checkbox checkbox-sm mr-2 mt-2"
                  checked={isHttpPlugin}
                  onChange={() => setIsHttpPlugin(!isHttpPlugin)}
                />
                <label className={'label'}>
                  <span className={'label-text text-sm'}>HTTP Plugin</span>
                </label>
              </div>
              {isHttpPlugin && (
                <HttpTemplateEditor
                  currentHttpTemplate={httpTemplate}
                  onChange={(template) =>
                    setNewPlugin({ ...newPlugin, httpTemplate: template })
                  }
                />
              )}
            </div>
          }
        </form>
        {validationErrors.length > 0 && (
          <div className="alert alert-warning mt-3">
            <ExclamationTriangleIcon className="w-8 h-8" />
            <ul>
              {validationErrors.map((error) => (
                <li key={error.field}>{error.message}</li>
              ))}
            </ul>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn mr-2 w-20" onClick={closeFunction}>
          Cancel
        </button>
        {(!isLoading && (
          <button
            type="submit"
            className="btn btn-primary w-20"
            disabled={
              !newPlugin.name ||
              !newPlugin.description ||
              !newPlugin.functionName ||
              !newPlugin.image
            }
            onClick={handleSubmit}
          >
            Save
          </button>
        )) || (
          <button className="btn btn-primary w-20" disabled>
            <span className="loading loading-spinner w-8 place-self-center"></span>
          </button>
        )}
      </ModalFooter>
    </>
  )
}

export default PluginCreateEdit
