export interface IAssistantModel {
  id: string
  apiVersion: string
  attributes: AssistantModelAttribute[]
  completionUnitPrice: number
  deploymentName: string
  deploymentType: string
  maxRequestSize: number
  name: string
  promptUnitPrice: number
  teamReferences: string[] | null | undefined
  region: string
  scope: string
  serviceProvider: string
  trainingData: string
  unitSize: number
  unitType: string
}

export enum AssistantModelAttribute {
  toolCalling = 'toolCalling',
  parallelToolCalling = 'parallelToolCalling',
  textProcessing = 'textProcessing',
  imageProcessing = 'imageProcessing',
}
