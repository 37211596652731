import React, { useCallback, useEffect, useState } from 'react'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button, Input, Tooltip } from '@nextui-org/react'
import {
  EntityType,
  iRole,
  iShareLink,
  SharableEntityType,
} from '../../interfaces/iShared.ts'
import ShareLinkService from '../../services/shareLinkService.ts'
import CopyToClipboard from '../utils/copyToClipboard.tsx'
import MemberRoleSelection from '../utils/memberRoleSelection.tsx'
import { generateInvitationUrl } from './constants.ts'

type InvitationLinkSettingsProps = {
  links: iShareLink[]
  resourceType: SharableEntityType
  allowedEntityTypes?: EntityType[]
  resourceId: string
  writeOnly?: boolean
  readOnly?: boolean
  roles?: iRole[]
  onChange?: (links: iShareLink[]) => void
}

function InvitationLinkSettings({
  resourceId,
  resourceType,
  allowedEntityTypes = [EntityType.User, EntityType.Team],
  links = [],
  writeOnly,
  readOnly,
  roles,
  onChange = () => {},
}: InvitationLinkSettingsProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stateLinks, setStateLinks] = useState<iShareLink[]>(links ?? [])
  const generateNewLink = () => {
    setIsLoading(true)
    ShareLinkService.generateShareLink(resourceId, resourceType, allowedEntityTypes)
      .then((data) => {
        setStateLinks([data, ...stateLinks])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    onChange(stateLinks)
  }, [stateLinks])

  const generateLink = useCallback((linkId: string) => {
    return generateInvitationUrl(resourceId, linkId, resourceType)
  }, [])
  return (
    <div className="flex flex-col mb-4 border-2 border-gray-300 rounded-md p-4 w-full min-w-fit">
      {stateLinks.map((link) => {
        return (
          <div className={'flex flex-row mb-4'}>
            <Tooltip
              content={generateLink(link.linkId)}
              color="primary"
              className="max-w-96"
            >
              <Input
                disabled={true}
                type="text"
                defaultValue={generateLink(link.linkId)}
                className="max-w-xs mr-2"
                endContent={
                  <CopyToClipboard
                    textToCopy={generateLink(link.linkId)}
                  ></CopyToClipboard>
                }
              />
            </Tooltip>
            <MemberRoleSelection
              value={link.role}
              isDisabled={readOnly}
              roles={roles}
              writeOnly={writeOnly}
              onChange={(value) => {
                link.role = value
                setStateLinks([...stateLinks])
              }}
            ></MemberRoleSelection>
            {!readOnly && (
              <Button
                isIconOnly
                variant={'light'}
                className="hover:text-error ml-4"
                onClick={() =>
                  setStateLinks(stateLinks.filter((m) => m.linkId !== link.linkId))
                }
              >
                <TrashIcon className={'w-4 h-4'}></TrashIcon>
              </Button>
            )}
          </div>
        )
      })}
      {!readOnly && (
        <div className={`divider transition mt-6`}>
          <div className={'flex flex-col items-center justify-center text-gray-400'}>
            <Button
              isLoading={isLoading}
              variant={'light'}
              id="createNewAssistant"
              className="self-center"
              onClick={generateNewLink}
            >
              {' '}
              {!isLoading && <PlusCircleIcon className="w-8 h-8" />}
              Generate new Link
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default InvitationLinkSettings
